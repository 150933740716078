import React from 'react';
import { useNavigate } from 'react-router-dom';
import Icon from 'components/Icon';
import AppButton from 'components/AppButton';
import './styles.scss';
import { useTranslation } from 'react-i18next';

type DependantAddButtonProps = {
  full?: boolean;
  outline?: boolean;
  disabled?: boolean;
};

function DependantAddButton({
  full = false,
  outline = false,
  disabled = false,
}: DependantAddButtonProps) {
  const { t } = useTranslation('dependant');
  const navigate = useNavigate();
  return (
    <div className="add-container">
      <AppButton
        full={full}
        outline={outline}
        onClick={() => navigate('/dependant/add')}
        disabled={disabled}
      >
        {t('dependant.register')}
        <div
          data-testid="addCircle"
          className={`icon${disabled ? '-disabled' : ''}`}
        >
          <Icon name="addCircle" />
        </div>
      </AppButton>
    </div>
  );
}

export default DependantAddButton;
