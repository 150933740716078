export const nameSpliter = (fullName: string) => {
  if (fullName && fullName.includes(' ')) {
    const nameParts = fullName.split(' ');

    const firstName = nameParts[0];
    const lastName = nameParts[nameParts.length - 1];

    return `${firstName} ${lastName}`;
  }
  return fullName;
};
