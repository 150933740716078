import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Controller, UseFormReturn } from 'react-hook-form';
import { addMinutes, formatISO } from 'date-fns';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import ValidationUtils, {
  getFormValidationProps,
} from 'components/Shared/ValidationUtils';
import masks from 'utils/MasksUtils';
import { customStylesDefault } from 'styles/select/customStylesDefault';
import ModalIR from 'components/Shared/ModalIR';
import DatePicker from 'components/AppDatePicker';
import * as FormModel from '../../models';
import 'styles/base/form-field.scss';
import './styles.scss';

const maxLength = 50;

const degreeKinship = [
  { value: 'Filho(a)', label: 'Filho(a)' },
  { value: 'Cônjuge', label: 'Cônjuge' },
];

export type DependantFormProps = {
  errorMessage: string;
  loading: boolean;
  validCPF: (cpf: string) => void;
  formMethods: UseFormReturn<FormModel.DependantProfile>;
};

function DependantForm({
  loading,
  validCPF,
  formMethods,
  errorMessage,
}: DependantFormProps) {
  const { errors } = formMethods.formState;
  const { t } = useTranslation('dependant');

  const [open, setOpen] = useState(false);

  const nameFormValidationProps = getFormValidationProps(
    'name',
    {},
    formMethods
  );

  const dateOfBirthFormValidationProps = getFormValidationProps(
    'birthdate',
    {},
    formMethods
  );

  const cpfFormValidationProps = getFormValidationProps('CPF', {}, formMethods);

  const kinshipFormValidationProps = getFormValidationProps(
    'kinship',
    {},
    formMethods
  );

  const includeIRFormValidationProps = getFormValidationProps(
    'includeIR',
    {},
    formMethods
  );

  return (
    <section className="dependant-form-container">
      <Row>
        <Col>
          <label id="name" htmlFor="name" className="form-field">
            <span
              className={
                errors.name?.message
                  ? 'label label-required-error'
                  : 'label label-required'
              }
            >
              {t('form.field.name')}
            </span>
            <Controller
              name="name"
              rules={{ maxLength, required: true }}
              control={formMethods.control}
              render={({ field: { onChange, onBlur } }) => (
                <input
                  type="text"
                  onBlur={onBlur}
                  placeholder={t('placeholder.full.name')}
                  className={
                    errors.name?.message ? 'field -input-error' : 'field -input'
                  }
                  value={formMethods.getValues('name') || ''}
                  onChange={(e) => onChange(masks.onlyLetters(e.target.value))}
                  disabled={nameFormValidationProps.shouldDisable}
                />
              )}
            />
            <p className="error-text">{errors.name?.message}</p>
          </label>
        </Col>
      </Row>

      <Row>
        <Col>
          <label
            id="dependantsRelationship"
            htmlFor="dependantsRelationship"
            className="form-field"
          >
            <span
              className={
                errors.kinship?.message
                  ? 'label label-required-error'
                  : 'label label-required'
              }
            >
              {t('form.field.kinship')}
            </span>
            <Controller
              name="kinship"
              control={formMethods.control}
              rules={{ required: true }}
              render={({ field: { onChange, value, name, ref, onBlur } }) => (
                <Select
                  placeholder="Selecione o parentesco"
                  styles={customStylesDefault(errors.kinship?.message)}
                  options={degreeKinship}
                  name={name}
                  value={degreeKinship.find((r) => r.value === value)}
                  onChange={(selectedOption: any) => {
                    onChange(selectedOption.value);
                  }}
                  onBlur={onBlur}
                  isSearchable={false}
                  menuIsOpen={
                    kinshipFormValidationProps.shouldDisable ? false : undefined
                  }
                />
              )}
            />
            <p className="error-text">{errors.kinship?.message}</p>
          </label>
        </Col>
        <Col>
          <label
            id="dependantsDateOfBirth"
            htmlFor="dependantsDateOfBirth"
            className="form-field"
          >
            <span
              className={
                errors.birthdate?.message
                  ? 'label label-required-error'
                  : 'label label-required'
              }
            >
              {t('form.field.birthDate')}
            </span>

            <Controller
              name="birthdate"
              control={formMethods.control}
              rules={{ required: true, minLength: 10 }}
              render={({ field: { value, onChange, ...rest } }) => {
                const castDate = (value && new Date(value)) || null;

                const dateValue =
                  castDate &&
                  addMinutes(castDate, castDate.getTimezoneOffset());

                return (
                  <Form.Control
                    className={
                      errors.birthdate?.message
                        ? 'field -input-error calendar'
                        : 'field -input calendar'
                    }
                    disabled={dateOfBirthFormValidationProps.shouldDisable}
                    as={DatePicker}
                    isInvalid={!!errors.birthdate}
                    name={rest.name}
                    selected={dateValue}
                    onChange={(date: any) => {
                      const formatted =
                        date &&
                        formatISO(date, {
                          representation: 'date',
                        });
                      formMethods.clearErrors('birthdate');
                      onChange(formatted || '');
                    }}
                    onBlur={rest.onBlur}
                  />
                );
              }}
            />
            <p className="error-text">{errors.birthdate?.message}</p>
          </label>
        </Col>
      </Row>
      <Row>
        <Col>
          <label
            id="dependantsCPF"
            htmlFor="dependantsCPF"
            className="form-field"
          >
            <span
              className={
                errors.cpf?.message || errorMessage
                  ? 'label label-required-error'
                  : 'label label-required'
              }
            >
              {t('form.field.cpf')}
            </span>
            <Controller
              control={formMethods.control}
              name="cpf"
              rules={{
                required: true,
                minLength: 14,
              }}
              render={({ field: { onChange, onBlur } }) => (
                <input
                  placeholder="000.000.000-00"
                  type="text"
                  className={
                    errors.cpf?.message || errorMessage
                      ? 'field -input-error'
                      : `${loading ? 'field -input -loading' : 'field -input'}`
                  }
                  onChange={(e) => {
                    validCPF(masks.cpf(e.target.value));
                    onChange(masks.cpf(e.target.value));
                  }}
                  value={formMethods.getValues('cpf')}
                  onBlur={onBlur}
                  disabled={cpfFormValidationProps.shouldDisable}
                />
              )}
            />

            {loading ? (
              <p className="loading">{t('dependant.loading.validation')}</p>
            ) : (
              <p className="error-text">
                {errors.cpf?.message ? errors.cpf?.message : errorMessage}
              </p>
            )}
          </label>
        </Col>
      </Row>
      <Row className="line">
        <Col>
          <label
            id="dependantsIncludedIR"
            htmlFor="dependantsIncludedIR"
            className="form-field"
          >
            <span className="label label-required">
              {t('form.field.includeIR')}
            </span>
            <Controller
              control={formMethods.control}
              name="includeIR"
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur } }) => (
                <>
                  <label htmlFor="includeIRTrue" className="radio-label">
                    <input
                      onClick={() => setOpen(true)}
                      id="includeIRTrue"
                      name="includeIR"
                      type="radio"
                      checked={formMethods.getValues('includeIR') === true}
                      onChange={(_) => {
                        onChange(true);
                      }}
                      className={ValidationUtils.errorRadioClass(
                        errors,
                        errors.includeIR
                      )}
                      value="true"
                      onBlur={onBlur}
                      disabled={includeIRFormValidationProps.shouldDisable}
                    />
                    <span>Sim</span>
                  </label>

                  <label htmlFor="includeIRFalse" className="radio-label">
                    <input
                      id="includeIRFalse"
                      name="includeIR"
                      type="radio"
                      checked={formMethods.getValues('includeIR') === false}
                      onChange={(_) => {
                        onChange(false);
                      }}
                      className={ValidationUtils.errorRadioClass(
                        errors,
                        errors.includeIR
                      )}
                      value="false"
                      onBlur={onBlur}
                      disabled={includeIRFormValidationProps.shouldDisable}
                    />
                    <span>Não</span>
                  </label>
                </>
              )}
            />
            <br />
            <p className="error-text">{errors.includeIR?.message}</p>
          </label>
        </Col>
      </Row>
      <ModalIR close={() => setOpen(false)} open={open} />
    </section>
  );
}

export default DependantForm;
