import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import configLocalization from 'configs/Localization';
import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from 'configs/msal-instance';
import { AuthProvider } from 'configs/context';
import ScrollToTop from 'components/Shared/ScrollToTop';
import Base from './components/Base';
import store from './store/store';
import './styles/base/base.scss';

function App() {
  configLocalization();
  return (
    <MsalProvider instance={msalInstance}>
      <AuthProvider store={store}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <ScrollToTop />
          <Base />
        </BrowserRouter>
      </AuthProvider>
    </MsalProvider>
  );
}
export default App;
