import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import DependantDetail from 'components/Dependants/DependantDetail';
import {
  DependantOperation,
  DependantProfile,
  DependantsList,
} from 'types/models/Dependant';
import dependantService from 'services/DependantService';
import { OperationStatus } from 'types/common/OperationStatus';
import BenefitWarning from 'components/Dependants/BenefitWarning';
import DependantAddButton from 'components/Dependants/AddDependant/AddDependantButton';
import DependantsEmpty from 'components/Dependants/DependantsEmpty';
import DependantDetailSkeleton from 'components/Dependants/DependantDetailSkeleton';
import OperationService from 'services/OperationService';
import Icon from 'components/Icon';
import ConfirmationModal from 'components/Shared/ConfirmationModal';
import AuthContext from 'configs/context';
import { pendingOperations } from 'store/user/actions';
import './styles.scss';
import FirstDependantAnalysis from 'components/Dependants/FirstDependantAnalysis';
import { nameSpliter } from 'utils/NameSpliter';
import PendingOperationBanner from 'components/Shared/PendingOperationBanner';

type ConfirmationModalDataType = {
  icon: string;
  title: React.ReactNode | string;
  description: React.ReactNode | string;
  rightButtonText: string;
  leftButtonText?: string;
};

type DependantOperationModalData = {
  [key in OperationStatus]?: ConfirmationModalDataType;
};

const DEFAULT_MODAL_DATA: ConfirmationModalDataType = {
  icon: '',
  title: '',
  description: '',
  rightButtonText: '',
};

type DependantsProps = {
  isActive?: boolean;
};

function Dependants({ isActive }: DependantsProps) {
  const { t } = useTranslation('dependant');
  const {
    state: { pendingOperation },
    dispatch,
  } = useContext(AuthContext);

  const [dependantsList, setDependantsList] = useState<DependantsList>({
    dependants: [],
  });

  const { dependantDeleteOperation, dependantCreateOperation } = dependantsList;

  const [dependantWithUpdateOperation] = dependantsList.dependants.filter(
    (dependant) => dependant.dependantUpdateOperation !== undefined
  );

  const [isLoading, setIsLoading] = useState(true);
  const [disableActions, setDisableActions] = useState(false);
  const [
    openDependantDeleteOperationModal,
    setOpenDependantDeleteOperationModal,
  ] = useState(false);
  const [
    openDependantUpdateOperationModal,
    setOpenDependantUpdateOperationModal,
  ] = useState(false);
  const [
    openDependantCreateOperationModal,
    setOpenDependantCreateOperationModal,
  ] = useState(false);

  const [
    dependantCreationNotStartedOrAnalysis,
    setDependantCreationNotStartedOrAnalysis,
  ] = useState(false);

  const ANALYSIS_OPERATION = 'ANALYSIS';
  const NOT_STARTED_OPERATION = 'NOT_STARTED';
  const CLOSE_BUTTON_TEXT = t('modal.close.button');
  const EMAIL_DESCRIPTION = (
    <span style={{ fontWeight: 'bold' }}>
      {t('dependant.operation.description.email')}
    </span>
  );

  useEffect(() => {
    dependantService.list().then((response) => {
      const responseDependant = response.data.dependants || [];

      setDependantsList({ ...response.data, dependants: responseDependant });
      setIsLoading(false);
      handleDisableActions(response.data);

      const deleteOperation = response.data?.dependantDeleteOperation;
      setOpenDependantDeleteOperationModal(
        deleteOperation?.flagViewed === false &&
          deleteOperation?.status !== NOT_STARTED_OPERATION &&
          deleteOperation?.status !== ANALYSIS_OPERATION
      );

      const createOperation = response.data?.dependantCreateOperation;
      setOpenDependantCreateOperationModal(
        createOperation?.flagViewed === false &&
          createOperation?.status !== NOT_STARTED_OPERATION &&
          createOperation?.status !== ANALYSIS_OPERATION
      );

      const updateOperation = responseDependant.filter(
        (d) => d.dependantUpdateOperation !== undefined
      )[0];

      setOpenDependantUpdateOperationModal(
        updateOperation?.dependantUpdateOperation?.flagViewed === false &&
          updateOperation?.dependantUpdateOperation?.status !==
            NOT_STARTED_OPERATION &&
          updateOperation?.dependantUpdateOperation?.status !==
            ANALYSIS_OPERATION
      );
    });
  }, []);

  function handleDisableActions(data: DependantsList) {
    const hasNotStartedCreate =
      data.dependantCreateOperation?.status === NOT_STARTED_OPERATION;
    const hasAnalysisCreate =
      data.dependantCreateOperation?.status === ANALYSIS_OPERATION;

    const hasNotStartedUpdate = data.dependants?.some(
      (dependant) =>
        dependant.dependantUpdateOperation?.status === NOT_STARTED_OPERATION
    );
    const hasAnalysisUpdate = data.dependants?.some(
      (dependant) =>
        dependant.dependantUpdateOperation?.status === ANALYSIS_OPERATION
    );

    const hasNotStartedDelete = data.dependants?.some(
      (dependant) =>
        dependant.dependantDeleteOperation?.status === NOT_STARTED_OPERATION
    );
    const hasAnalysisDelete = data.dependants?.some(
      (dependant) =>
        dependant.dependantDeleteOperation?.status === ANALYSIS_OPERATION
    );

    setDisableActions(
      hasNotStartedCreate ||
        hasAnalysisCreate ||
        hasNotStartedUpdate ||
        hasAnalysisUpdate ||
        hasNotStartedDelete ||
        hasAnalysisDelete
    );
    setDependantCreationNotStartedOrAnalysis(
      hasNotStartedCreate || hasAnalysisCreate
    );

    if (
      hasNotStartedCreate ||
      hasAnalysisCreate ||
      hasNotStartedUpdate ||
      hasAnalysisUpdate ||
      hasNotStartedDelete ||
      hasAnalysisDelete
    ) {
      dispatch(pendingOperations(true));
    }
  }

  function getDependantOperationDeleteModalData(): ConfirmationModalDataType {
    const name = nameSpliter(
      dependantsList?.dependantDeleteOperation?.operationData?.name as string
    );
    const statusData: DependantOperationModalData = {
      APPROVED: {
        icon: 'remove',
        title: (
          <Trans
            t={t}
            i18nKey={t('dependant.operation.delete.approved.title', {
              name,
            })}
            components={{ bold: <span className="bold" /> }}
          />
        ),
        description: (
          <>
            {t('dependant.operation.delete.approved.description')}
            {EMAIL_DESCRIPTION}
          </>
        ),
        rightButtonText: CLOSE_BUTTON_TEXT,
      },
      DENIED: {
        icon: 'removeDenied',
        title: (
          <Trans
            t={t}
            i18nKey={t('dependant.operation.delete.denied.title', {
              name,
            })}
            components={{ bold: <span className="bold" /> }}
          />
        ),
        description: (
          <>
            {t('dependant.operation.delete.denied.description')}
            {EMAIL_DESCRIPTION}
          </>
        ),
        rightButtonText: CLOSE_BUTTON_TEXT,
      },
    };

    return (
      statusData[dependantDeleteOperation?.status ?? ''] || DEFAULT_MODAL_DATA
    );
  }

  function getDependantOperationCreateModalData(): ConfirmationModalDataType {
    const name = nameSpliter(
      dependantsList?.dependantCreateOperation?.operationData?.name as string
    );
    const statusData: DependantOperationModalData = {
      APPROVED: {
        icon: 'createApproved',
        title: (
          <>
            {t('dependant.operation.create.approved.success.title.part1', {
              name,
            })}
            <span style={{ fontWeight: 'bold' }}>
              {t('dependant.operation.create.approved.success.title.part2')}
            </span>
          </>
        ),
        description: (
          <>
            {t('dependant.operation.create.approved.success.description')}
            {EMAIL_DESCRIPTION}
          </>
        ),
        rightButtonText: CLOSE_BUTTON_TEXT,
      },
      DENIED: {
        icon: 'createDenied',
        title: (
          <>
            {t('dependant.operation.create.denied.success.title.part1')}
            <span style={{ fontWeight: 'bold' }}>
              {t('dependant.operation.create.denied.success.title.part2')}
            </span>
          </>
        ),
        description: (
          <>
            {t('dependant.operation.create.denied.success.description')}
            {EMAIL_DESCRIPTION}
          </>
        ),
        rightButtonText: CLOSE_BUTTON_TEXT,
      },
    };

    return (
      statusData[dependantCreateOperation?.status ?? ''] || DEFAULT_MODAL_DATA
    );
  }

  function getDependantOperationUpdateModalData(): ConfirmationModalDataType {
    let name = dependantWithUpdateOperation?.name;

    if (name) {
      const nameSplit = name.split(' ');
      if (nameSplit.length > 1) {
        name = `'${nameSplit[0]} ${nameSplit[nameSplit.length - 1]}'`;
      } else {
        name = `'${nameSplit[0]}'`;
      }
    }

    switch (dependantWithUpdateOperation?.dependantUpdateOperation?.status) {
      case 'APPROVED':
        return {
          icon: 'editSuccess',
          title: (
            <Trans
              t={t}
              i18nKey={t('dependant.operation.update.approved.title', {
                name,
              })}
              components={{ bold: <span className="bold" /> }}
            />
          ),
          description: t('dependant.operation.update.approved.description'),
          rightButtonText: 'Fechar',
        };
      case 'DENIED':
        return {
          icon: 'editError',
          title: (
            <Trans
              t={t}
              i18nKey={t('dependant.operation.update.denied.title', {
                name,
              })}
              components={{ bold: <span className="bold" /> }}
            />
          ),
          description: t('dependant.operation.update.denied.description'),
          rightButtonText: 'Fechar',
        };
      default:
        return DEFAULT_MODAL_DATA;
    }
  }

  function toggleOperationVisibility(operationId?: number): void {
    if (operationId !== undefined) {
      OperationService.updateOperationVisibility(operationId);
      setOpenDependantDeleteOperationModal(false);
      setOpenDependantCreateOperationModal(false);
      setOpenDependantUpdateOperationModal(false);
      dispatch(pendingOperations(false));
    }
  }

  function renderConfirmationModal(
    close: () => void,
    handleConfirm: () => void,
    openState: boolean,
    data: ConfirmationModalDataType
  ) {
    return (
      <ConfirmationModal
        close={close}
        handleConfirm={handleConfirm}
        open={openState}
        data={data}
      />
    );
  }

  const showNewDependantFlag = (
    dependantCreateOperations: DependantOperation | undefined,
    dependants: DependantProfile
  ) =>
    dependantCreateOperations?.operationData?.id === dependants.id &&
    !dependantCreateOperations?.flagViewed;

  function renderDependants() {
    if (dependantsList.dependants?.length > 0) {
      return (
        <>
          <BenefitWarning />
          <Row className="dependants">
            <Col>
              {dependantsList.dependants.map((dependant, idx) => (
                <DependantDetail
                  dependant={dependant}
                  idx={idx}
                  key={dependant.id}
                  disableActions={pendingOperation}
                  newDependant={showNewDependantFlag(
                    dependantCreateOperation,
                    dependant
                  )}
                />
              ))}
            </Col>
          </Row>
          {dependantCreationNotStartedOrAnalysis && (
            <Row className="pendency-row">
              <Col className="pendency-info">
                <Icon name="historyToggle" />
                <h2>{t('dependant.pendency.info')}</h2>
              </Col>
            </Row>
          )}
          <Row>
            <Col className="button-add-col" data-testid="dependant-add-btn">
              <DependantAddButton outline disabled={pendingOperation} />
            </Col>
          </Row>
        </>
      );
    }
    if (
      dependantsList.dependants?.length === 0 &&
      (dependantCreateOperation?.status === 'NOT_STARTED' ||
        dependantCreateOperation?.status === 'ANALYSIS')
    ) {
      return <FirstDependantAnalysis />;
    }
    return <DependantsEmpty disabled={pendingOperation} />;
  }

  return (
    <Container fluid className="dependants-container">
      <Row className="header-row">
        <Col data-testid="dependants" className="title-col">
          <h2>{t('dependant.title')}</h2>
          <h4>{t('dependant.view.info')}</h4>
        </Col>
        {dependantsList.dependants?.length > 0 && (
          <Col className="button-add-col ">
            <DependantAddButton outline disabled={pendingOperation} />
          </Col>
        )}
      </Row>
      {pendingOperation && <PendingOperationBanner />}
      {isLoading
        ? Array.from({ length: 3 }).map((_, index) => (
            <Row key={`skeleton-${index + 1}`}>
              <DependantDetailSkeleton />
            </Row>
          ))
        : renderDependants()}

      {renderConfirmationModal(
        toggleOperationVisibility.bind(dependantCreateOperation?.id),
        toggleOperationVisibility.bind(null, dependantCreateOperation?.id),
        openDependantCreateOperationModal && !!isActive,
        getDependantOperationCreateModalData()
      )}

      {renderConfirmationModal(
        toggleOperationVisibility.bind(dependantDeleteOperation?.id),
        toggleOperationVisibility.bind(null, dependantDeleteOperation?.id),
        openDependantDeleteOperationModal && !!isActive,
        getDependantOperationDeleteModalData()
      )}

      {renderConfirmationModal(
        toggleOperationVisibility.bind(
          dependantWithUpdateOperation?.dependantUpdateOperation?.id
        ),
        toggleOperationVisibility.bind(
          null,
          dependantWithUpdateOperation?.dependantUpdateOperation?.id
        ),
        openDependantUpdateOperationModal && !!isActive,
        getDependantOperationUpdateModalData()
      )}
    </Container>
  );
}

export default Dependants;
