/* eslint-disable import/prefer-default-export */
export const ProfileTexts = {
  'pt-BR': {
    'profile.redistribute.modal.success.title':
      'Sua nova redistribuição foi salva!',
    'profile.redistribute.modal.success.confirm.button':
      'Acessar a cesta de benefícios',
    'profile.redistribute.modal.error.title': 'Falha no salvamento',
    'profile.redistribute.modal.error.description':
      'Houve algum erro na hora de salvar os novos valores da sua cesta. Retorne e tente novamente!',
    'profile.redistribute.modal.loading.title': 'Salvando redistribuição...',
    'profile.modal.loading.description': 'Aguarde...',
    'profile.modal.info.back.button': 'Voltar',
    'profile.modal.warning.close.btn': 'Fechar',
    'profile.modal.intern.warning.title':
      'Você não pode manter sua cesta de benefícios dessa maneira',
    'profile.modal.intern.warning.description':
      'Com os reajustes, sua cesta de benefícios ultrapassou o valor permitido. Colaboradores estagiários não podem ultrapassar sua cesta',
    'profile.modal.pending.operation.title': 'Há uma solicitação em andamento!',
    'profile.modal.pending.operation.description':
      'Assim que finalizar, novas operações poderão ser realizadas.',
    'profile.update.modal.title':
      'Seus dados cadastrais foram <bold>atualizados!<bold>',
    'profile.update.modal.description':
      'Você solicitou por e-mail a alteração dos seus dados cadastrais que agora foram atualizados. Verifique se estão todos corretos, caso não, contate <bold>equipe.dp@iteris.com.br<bold>',
    name: 'Nome Completo',
    'preferred.name': 'Como quer ser chamado',
    'country.of.birth': 'País de nascimento',
    'date.of.birth': 'Data de nascimento',
    sex: 'Sexo',
    race: 'Raça/Cor',
    'civil.status': 'Estado civil',
    schooling: 'Escolaridade',
    deficiency: 'Deficiência',
    parentage: 'Filiação 1',
    'second.parentage': 'Filiação 2',
    'edited.data': 'Dados Alterados',
  },
};
