export const AvailableBenefitsEnum = {
  dentalAssistance: 'ASSISTENCIA_ODONTOLOGICA',
  healthAssistance: 'ASSISTENCIA_MEDICA',
};

export const BenefitStatus = {
  denied: 'DENIED',
  notStarted: 'NOT_STARTED',
  analysis: 'ANALYSIS',
  approved: 'APPROVED',
};
