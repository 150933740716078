/* eslint-disable react/button-has-type */
import React from 'react';
import './style.scss';

export type AppButtonProps = JSX.IntrinsicElements['button'] & {
  className?: string;
  full?: boolean;
  disabled?: boolean;
  outline?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
};

function AppButton({
  className = '',
  full = false,
  disabled = false,
  outline = false,
  onClick = () => {},
  children,
  ...nativeProps
}: AppButtonProps) {
  return (
    <button
      className={`app-button ${full ? 'full' : ''} ${
        outline ? 'outline' : ''
      } ${className}`}
      type={nativeProps.type || 'button'}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export default AppButton;
