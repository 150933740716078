import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Icon from 'components/Icon';
import AppModal from 'components/AppModal';
import './styles.scss';

const infos = [
  {
    number: '1',
    description: 'Prosseguiremos o processo com você por e-mail;',
  },
  {
    number: '2',
    description: 'Em casos de adição de dependentes, temos:',
    text: 'O novo dependente poderá ter uma carência de 180 dias;',
    secondText:
      'A carência só não implicará caso a data do casamento ou nascimento não ultrapasse 30 dias antes da solicitação;',
  },
  {
    number: '3',
    description:
      'Será necessário fazer a redistribuição da sua cesta, que será automaticamente aplicada após a aprovação;',
  },
  {
    number: '4',
    description: 'Ao confirmar, você concorda com os termos citados acima.',
  },
];

type ModalTermsRProps = {
  open: boolean;
  close: () => void;
  confirm: () => void;
};

function ModalTerms({ close, open, confirm }: ModalTermsRProps) {
  return (
    <AppModal
      open={open}
      showLeftButton
      leftButtonText="Voltar"
      rightButtonText="Confirmar"
      confirm={() => {
        confirm();
      }}
      close={() => {
        close();
      }}
    >
      <div className="modal-with-confirmation" data-testid="warn-term">
        <Row className="icon">
          <Col>
            <Icon name="warning" />
          </Col>
        </Row>
        <Row className="text">
          <Col>
            <h2 className="title">Antes de continuar, leia os termos!</h2>
            <div className="modal-terms modal-ir">
              {infos.map((info) => (
                <div key={info.number} className="line">
                  <div className="dot">{info.number}</div>
                  <div className="description">
                    {info.number !== '2' ? (
                      <h4 className="text">{info.description} </h4>
                    ) : (
                      <h4 className="text">
                        Em casos de
                        <span className="strong"> adição </span>
                        de dependentes, temos:
                      </h4>
                    )}

                    {info.text && (
                      <div className="infos">
                        <ol className="list">
                          <li className="info">{info.text}</li>
                        </ol>
                        <ol className="list">
                          <li className="info last">{info.secondText}</li>
                        </ol>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </div>
    </AppModal>
  );
}

export default ModalTerms;
