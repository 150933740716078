import axios from 'axios';
import { loginRequest } from '../authConfig';
import { msalInstance } from './msal-instance';

export async function fetchToken() {
  const accounts = msalInstance.getAllAccounts();
  if (!accounts || accounts.length === 0) {
    return null;
  }

  return new Promise((resolve) => {
    msalInstance
      .acquireTokenSilent({
        scopes: loginRequest.scopes,
        account: msalInstance.getAllAccounts()[0],
      })
      .then((response) => {
        resolve(response.idToken);
      })
      .catch((err) => {
        msalInstance.loginPopup();
      });
  });
}

const api = axios.create({
  timeout: 30000,
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },

  baseURL: process.env.REACT_APP_URL_API,
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      sessionStorage.clear();
      localStorage.clear();
    }
    return Promise.reject(error);
  }
);

api.interceptors.request.use(async (config) => {
  if (!config?.headers) {
    throw new Error(`Não foi encontrado header nessa requisição.`);
  }

  const accessToken = await fetchToken();
  if (accessToken != null) {
    /* eslint-disable no-param-reassign */
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

export default api;
