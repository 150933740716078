import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import Icon from 'components/Icon';
import api from 'configs/api';
import ConfirmationModal from 'components/Shared/ConfirmationModal';
import UploadFileUtils from './UploadFileUtils';
import UploadedFile from './UploadedFile';
import './style.scss';

const UPLOAD_MAX_SIZE = 5242880;

type AttachmentResponse = {
  document: string;
  field: string;
};

export type AppUploadInputProps = {
  uploadedFile: UploadedFile;
  fieldName: string;
  onComplete: (name: string, fieldName: string) => void;
  path: string;
  required?: boolean;
  translation: any;
  inputSubtitleAppend: string;
  extensions?: string[];
  loading: boolean;
  setLoading: (loading: boolean) => void;
  handleResponse: (response: AttachmentResponse) => void;
  dependantFirstName: string;
  setNameToRemove: (name: string) => void;
};

function AppUploadInput({
  uploadedFile,
  fieldName,
  required = false,
  path,
  onComplete,
  inputSubtitleAppend,
  translation,
  extensions,
  loading,
  setLoading,
  handleResponse,
  dependantFirstName,
  setNameToRemove,
}: AppUploadInputProps) {
  const [openModalError, setOpenModalError] = useState(false);
  const [modalErrorData, setModalErrorData] = useState({
    rightButtonText: translation('modal.back.button'),
    icon: 'errorIcon',
    title: translation('modal.error.title.upload'),
    description: translation('modal.custom.error.description'),
  });

  const getValidExtensions = (): string => {
    if (!extensions || extensions.length === 0) return '';
    return extensions.toString();
  };

  const validateFile = (nameFile: string, sizeFile: number): boolean => {
    if (sizeFile > UPLOAD_MAX_SIZE) {
      setModalErrorData({
        ...modalErrorData,
        description: translation('dependant.file.max.size'),
      });
      setOpenModalError(true);
      return false;
    }

    if (extensions && !UploadFileUtils.isValidFormat(nameFile, extensions)) {
      setModalErrorData({
        ...modalErrorData,
        description: translation('dependant.file.max.size'),
      });
      setOpenModalError(true);
      return false;
    }

    return true;
  };

  const handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];
    if (!validateFile(file.name, file.size)) return;
    handleConfirm(file);
  };

  const handleConfirm = async (file: File) => {
    setLoading(true);
    const data = new FormData();
    data.append('field', fieldName);
    data.append('file', file, file.name);
    data.append('dependantFirstName', dependantFirstName);

    api
      .post(`${process.env.REACT_APP_URL_API}${path}`, data)
      .then((res) => {
        setNameToRemove(res.data.fileName);
        onComplete(file.name, fieldName);
        handleResponse({
          document: res.data.fileName,
          field: fieldName.toUpperCase(),
        });
      })
      .catch((err) => {
        if (err) {
          setModalErrorData({
            ...modalErrorData,
          });
          setOpenModalError(true);
        }
      })
      .finally(() => setLoading(false));
  };

  function handleFileValidation(fileNameInput: string) {
    if (required) return !!fileNameInput;
    return false;
  }

  return (
    <label
      htmlFor={`input-${fieldName}`}
      className={`input-container${loading ? ' disabled' : ''}`}
    >
      <div className="input">
        <span className="label">{translation('click.to.add')}</span>
        <Icon name="attachFileAdd" />
        <Controller
          control={uploadedFile.control}
          name={fieldName}
          rules={{
            validate: (fileValue: { name: string }) =>
              handleFileValidation(fileValue.name),
          }}
          render={() => (
            <input
              name={fieldName}
              onChange={(event) => {
                handleFile(event);
              }}
              type="file"
              id={`input-${fieldName}`}
              accept={getValidExtensions()}
              disabled={loading}
              onClick={(event) => {
                const element = event.target as HTMLInputElement;
                element.value = '';
              }}
            />
          )}
        />
      </div>
      <div className="description">
        <span>
          {translation(`upload.description.${fieldName}${inputSubtitleAppend}`)}
        </span>
        <span className="example" hidden>
          {translation(`upload.field.example`)}
        </span>
      </div>
      <ConfirmationModal
        open={openModalError}
        data={modalErrorData}
        handleConfirm={() => {
          setOpenModalError(false);
        }}
        close={() => {
          setOpenModalError(false);
        }}
      />
    </label>
  );
}

export default AppUploadInput;
