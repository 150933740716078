import React from 'react';
import { Row, Col } from 'react-bootstrap';
import AppSkeleton from 'components/AppSkeleton';

function BenefitInformationLoader() {
  return (
    <Row>
      <Col
        sm={12}
        style={{ margin: '8px' }}
        className="additional-skeleton"
        data-testid="additional-skeleton"
      >
        <div
          className="benefit-card"
          style={{
            height: '164px',
            backgroundColor: 'var(--color-primary-02-neutral)',
          }}
        >
          <AppSkeleton
            style={{ width: '40px', height: '40px', marginBottom: '12px' }}
            data-testid="app-skeleton"
          />
          <div className="text-container">
            <AppSkeleton
              style={{ width: '100%', height: '29px', marginBottom: '8px' }}
              data-testid="app-skeleton"
            />
            <AppSkeleton
              style={{ width: '70%', height: '19px' }}
              data-testid="app-skeleton"
            />
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default BenefitInformationLoader;
