import React from 'react';
import AppSkeleton from 'components/AppSkeleton';
import './styles.scss';

function ProgressBarLoading() {
  return (
    <div data-testid="progress-bar-loading" className="progress-bar loading">
      <div className="info">
        <div className="titles">
          <AppSkeleton className="left" style={{ width: '160px' }} xl={3} />
          <AppSkeleton className="right" style={{ width: '160px' }} xl={3} />
        </div>
        <div className="progress-bar-base" />
        <div className="values">
          <AppSkeleton className="left" style={{ width: '160px' }} xl={3} />
          <AppSkeleton className="right" style={{ width: '160px' }} xl={3} />
        </div>
      </div>
      <div className="total">
        <AppSkeleton style={{ width: '112px', height: '18px' }} xl={12} />
        <AppSkeleton
          style={{ width: '112px', marginTop: '4px', height: '24px' }}
          xl={12}
        />
      </div>
    </div>
  );
}

export default ProgressBarLoading;
