import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon';
import { AddDependantStep } from 'types/common/AddDependantStep';
import AddDependantSteps from '../AddDependantSteps';
import './styles.scss';

export type AddDependantHeaderProps = {
  isRemoving: boolean;
  step: AddDependantStep;
  setOpenBackModal: (open: boolean) => void;
};

function AddDependantHeader({
  step,
  isRemoving,
  setOpenBackModal,
}: AddDependantHeaderProps) {
  const { t } = useTranslation('dependant');

  return (
    <div className="add-dependant-header-container">
      <Row className="navigation">
        <Col
          className="navigation-link"
          data-testid="navigation-link"
          onClick={() => {
            if (!isRemoving) setOpenBackModal(true);
          }}
        >
          <div className={`icon ${isRemoving ? 'disabled' : ''}`}>
            <Icon name="chevronLeftGray" />
          </div>
          <span>{t('add.back.dependants')}</span>
        </Col>
      </Row>
      <hr />
      <Row className="title">
        <h1>{t('add.dependant.header')}</h1>
        <div className="desc">
          <p>{t('add.header.description')}</p>
        </div>
      </Row>
      <Row>
        <Col>
          <AddDependantSteps active={step} />
        </Col>
      </Row>
    </div>
  );
}

export default AddDependantHeader;
