import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import Icon from 'components/Icon';
import AppModal from 'components/AppModal';
import './styles.scss';

type ModalManageDependantsTermsProps = {
  open: boolean;
  close: () => void;
  confirm: () => void;
};

function ModalManageDependantsTerms({
  close,
  open,
  confirm,
}: ModalManageDependantsTermsProps) {
  const { t } = useTranslation('benefit');
  const infos = [
    {
      number: '1',
      description: t('benefit.manage.dependant.modal.terms.1'),
    },
    {
      number: '2',
      description: t('benefit.manage.dependant.modal.terms.2'),
      text: t('benefit.manage.dependant.modal.terms.2.1'),
      secondText: t('benefit.manage.dependant.modal.terms.2.2'),
    },
    {
      number: '3',
      description: t('benefit.manage.dependant.modal.terms.3'),
      text: t('benefit.manage.dependant.modal.terms.3.1'),
    },
    {
      number: '4',
      description: t('benefit.manage.dependant.modal.terms.4'),
    },
    {
      number: '5',
      description: t('benefit.manage.dependant.modal.terms.5'),
    },
  ];

  return (
    <AppModal
      open={open}
      showLeftButton
      leftButtonText={t('back.button')}
      rightButtonText={t('benefit.manage.dependant.modal.confirm')}
      confirm={() => {
        confirm();
      }}
      close={() => {
        close();
      }}
    >
      <div className="modal-with-confirmation">
        <Row className="icon">
          <Col>
            <Icon name="warning" />
          </Col>
        </Row>
        <Row className="text">
          <Col>
            <h2 className="title">
              {t('benefit.manage.dependant.modal.terms.title')}
            </h2>
            <div className="modal-terms modal-ir">
              {infos.map((info) => (
                <div key={info.number} className="line">
                  <div className={info.number === '3' ? 'dot red' : 'dot'}>
                    {info.number}
                  </div>
                  <div className="description">
                    {info.number !== '2' && info.number !== '3' && (
                      <h4 className="text">{info.description} </h4>
                    )}
                    {info.number === '2' && (
                      <h4 className="text">
                        <Trans
                          t={t}
                          i18nKey="benefit.manage.dependant.modal.terms.2"
                          components={{ bold: <span className="bold" /> }}
                        />
                      </h4>
                    )}
                    {info.number === '3' && (
                      <h4 className="text">
                        <Trans
                          t={t}
                          i18nKey="benefit.manage.dependant.modal.terms.3"
                          components={{ bold: <span className="bold" /> }}
                        />
                      </h4>
                    )}
                    {info.text && (
                      <div className="infos">
                        <ol
                          className={info.number === '3' ? 'list red' : 'list'}
                        >
                          <li
                            className={info.secondText ? 'info' : 'info last'}
                          >
                            {info.text}
                          </li>
                        </ol>
                        {info.secondText && (
                          <ol className="list">
                            <li className="info last">{info.secondText}</li>
                          </ol>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </div>
    </AppModal>
  );
}

export default ModalManageDependantsTerms;
