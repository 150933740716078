import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Controller, UseFormReturn } from 'react-hook-form';
import Icon from 'components/Icon';
import ValidationUtils, {
  getFormValidationProps,
} from 'components/Shared/ValidationUtils';
import masks from 'utils/MasksUtils';
import AuthContext from 'configs/context';
import ModalIR from 'components/Shared/ModalIR';
import * as FormModel from '../models';
import 'styles/base/form-field.scss';
import './styles.scss';

export type DependantEditFormProps = {
  formMethods: UseFormReturn<FormModel.DependantEditProfile>;
};

function DependantEditForm({ formMethods }: DependantEditFormProps) {
  const maxLength = 50;
  const navigate = useNavigate();
  const { errors } = formMethods.formState;
  const { t } = useTranslation('dependant');
  const [open, setOpen] = useState(false);

  const {
    state: { editDependant },
  } = useContext(AuthContext);

  const nameFormValidationProps = getFormValidationProps(
    'name',
    {},
    formMethods
  );

  const includeIRFormValidationProps = getFormValidationProps(
    'includeIR',
    {},
    formMethods
  );

  function loadAnswers() {
    formMethods.reset({
      dependantId: editDependant.dependantId,
      name: editDependant?.name || '',
      includeIR: editDependant?.includeIR || false,
    });
  }

  useEffect(() => {
    loadAnswers();
    if (editDependant.name === '') {
      navigate('/');
    }
  }, []);

  return (
    <div className="container">
      <Row>
        <Col className="header-box">
          <div className="icon">
            <Icon name="errorIcon" />
          </div>
          <span className="text">{t('editing.dependent.information')}</span>
          <span className="name">{editDependant.name}</span>
        </Col>
      </Row>
      <hr />
      <Row className="subtitle">
        <Col>{t('fill.dependant.information')}</Col>
      </Row>
      <Row>
        <Col>
          <label id="nameLabel" htmlFor="name" className="form-field">
            <span
              className={ValidationUtils.errorLabelClass(errors, errors.name)}
            >
              {t('form.field.name')}
            </span>
            <Controller
              name="name"
              rules={{ maxLength, required: true }}
              control={formMethods.control}
              render={({ field: { onChange, onBlur } }) => (
                <input
                  type="text"
                  data-testid="nameInput"
                  id="nameInput"
                  onBlur={onBlur}
                  className={ValidationUtils.errorClass(errors, errors.name)}
                  value={formMethods.getValues('name') || ''}
                  onChange={(e) => onChange(masks.onlyLetters(e.target.value))}
                  disabled={nameFormValidationProps.shouldDisable}
                />
              )}
            />
            {ValidationUtils.errorViewValidator(errors, errors.name)}
            {ValidationUtils.errorMaxLengthValidator(
              errors,
              errors.name,
              maxLength
            )}
          </label>
        </Col>
      </Row>
      <Row className="line">
        <Col>
          <label
            id="dependantsIncludedIR"
            htmlFor="dependantsIncludedIR"
            className="form-field"
          >
            <span className="label label-required">
              {t('form.field.includeIR')}
            </span>
            <Controller
              control={formMethods.control}
              name="includeIR"
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur } }) => (
                <>
                  <label htmlFor="includeIRTrue" className="radio-label">
                    <input
                      id="includeIRTrue"
                      name="includeIR"
                      type="radio"
                      data-testid="includeIRTrue"
                      checked={formMethods.getValues('includeIR') === true}
                      onChange={(_) => {
                        onChange(true);
                      }}
                      className={ValidationUtils.errorRadioClass(
                        errors,
                        errors.includeIR
                      )}
                      value="true"
                      onBlur={onBlur}
                      disabled={includeIRFormValidationProps.shouldDisable}
                      onClick={() => setOpen(true)}
                    />
                    <span>{t('yes.input')}</span>
                  </label>

                  <label htmlFor="includeIRFalse" className="radio-label">
                    <input
                      id="includeIRFalse"
                      name="includeIR"
                      type="radio"
                      data-testid="includeIRFalse"
                      checked={formMethods.getValues('includeIR') === false}
                      onChange={(_) => {
                        onChange(false);
                      }}
                      className={ValidationUtils.errorRadioClass(
                        errors,
                        errors.includeIR
                      )}
                      value="false"
                      onBlur={onBlur}
                      disabled={includeIRFormValidationProps.shouldDisable}
                    />
                    <span>{t('no.input')}</span>
                  </label>
                </>
              )}
            />
            {errors.includeIR?.message}
          </label>
        </Col>
      </Row>
      <ModalIR close={() => setOpen(false)} open={open} />
    </div>
  );
}

export default DependantEditForm;
