import React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import TabsContainer from 'components/TabsContainer';
import { allowedPaths } from 'constants/paths';
import { tabs } from 'constants/tabs';
import { useContractType } from 'hooks/useContractType';
import { filterTabsByContractType } from './FilterTabs';

export default function TabsController() {
  const location = useLocation();
  const navigate = useNavigate();
  const contractType = useContractType();

  const selectTab = (tab: string) => {
    navigate(`/${tab}`);
  };

  const filteredTabs = filterTabsByContractType(tabs, contractType);

  const currentPath = location.pathname.replace(/\//gi, '');

  if (
    allowedPaths(contractType).filter((path) => path === currentPath).length ===
    0
  ) {
    return <Navigate to="/profile" />;
  }

  return (
    <TabsContainer
      tabs={filteredTabs}
      activeTab={currentPath}
      selectTab={selectTab}
    />
  );
}
