import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getFormValidationProps } from 'components/Shared/ValidationUtils';
import AppUpload from 'components/AppUpload';
import UploadedFile from 'components/AppUpload/UploadedFile';
import * as FormModel from '../models';
import '../../../styles/base/form-field.scss';
import './styles.scss';

type AttachmentResponse = {
  document: string;
  field: string;
};

export type DependantAttachmentsProps = {
  profileInfos: FormModel.DependantProfile;
  formMethods: UseFormReturn<FormModel.DependantAttachment>;
  isRemoving: boolean;
  setFilesToRemove: (isRemoving: boolean) => void;
  setAttachmentsResponse: (
    attachmentResponse: Array<AttachmentResponse>
  ) => void;
  attachmentsResponse: Array<AttachmentResponse>;
};

function DependantAttachments({
  formMethods,
  isRemoving,
  setFilesToRemove,
  profileInfos,
  attachmentsResponse,
  setAttachmentsResponse,
}: DependantAttachmentsProps) {
  const { t } = useTranslation('dependant');
  const attachments = ['ID', 'CERTIFICATE'];
  const dependantFirstName = profileInfos.name?.split(' ')[0];
  const [loading, setLoading] = useState(false);

  const getUploadedFile = (name: string): UploadedFile => {
    const { shouldDisable, hasModified } = getFormValidationProps(
      name,
      {},
      formMethods
    );

    return {
      name,
      url: '',
      shouldDisable,
      hasModified,
      control: formMethods.control,
      status: '',
    };
  };

  const kinshipValidation = () => {
    const { kinship } = profileInfos;

    if (kinship === 'Filho(a)') {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const index = attachmentsResponse.findIndex(
      (attachment) => attachment.document === ''
    );
    index !== -1 && attachmentsResponse.splice(index, 1);
  }, [attachmentsResponse]);

  const handleResponse = (response: { document: string; field: string }) => {
    const existingIndex = attachmentsResponse.findIndex(
      (attachment) => attachment.field === response.field
    );

    if (existingIndex !== -1) {
      const updatedAttachmentsResponse = [...attachmentsResponse];
      updatedAttachmentsResponse[existingIndex] = {
        ...updatedAttachmentsResponse[existingIndex],
        document: response.document,
      };
      setAttachmentsResponse(updatedAttachmentsResponse);
    } else {
      setAttachmentsResponse([...attachmentsResponse, response]);
    }
  };

  return (
    <section className="dependant-attachment-container">
      <Row>
        <Col>
          {attachments.map((name: string) => (
            <AppUpload
              dependantFirstName={dependantFirstName}
              handleResponse={handleResponse}
              key={name}
              translation={t}
              fieldName={name}
              path="/attachment"
              formMethods={formMethods}
              loading={loading}
              setLoading={setLoading}
              isRemoving={isRemoving}
              setFilesToRemove={setFilesToRemove}
              uploadedFile={getUploadedFile(name)}
              required={!(!kinshipValidation() && name === 'ID')}
              inputSubtitleAppend={kinshipValidation() ? '' : 'Child'}
              extensions={['.jpg', '.jpeg', '.png', '.pdf']}
            />
          ))}
        </Col>
      </Row>
    </section>
  );
}

export default DependantAttachments;
