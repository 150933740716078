import React from 'react';
import { Outlet } from 'react-router-dom';
import ProfileHeader from 'components/ProfileHeader';
import './styles.scss';

function Home() {
  return (
    <>
      <ProfileHeader />
      <Outlet />
    </>
  );
}

export default Home;
