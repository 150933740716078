import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Container, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { DependantProfile } from 'types/models/Dependant';
import Icon from 'components/Icon';
import AuthContext from 'configs/context';
import { useTranslation } from 'react-i18next';
import {
  editCurrentDependant,
  cancelCurrentDependant,
} from 'store/user/actions';
import { nameSpliter } from 'utils/NameSpliter';
import CancelDependantModal from './CancelDependantModal';
import WaitingApproval from '../WaitingApproval';
import DependantDetailFields from '../DependantDetailFields';
import NewDependant from '../NewDependant';
import './styles.scss';

type DependantDetailsProps = {
  dependant: DependantProfile;
  idx: number;
  disableActions: boolean;
  newDependant: boolean;
};

type ModalData = {
  icon: string;
  title: string;
  description?: string;
  rightButtonText: string;
  leftButtonText?: string;
};

function DependantDetails({
  dependant,
  idx,
  disableActions,
  newDependant,
}: DependantDetailsProps) {
  const navigate = useNavigate();
  const { t } = useTranslation('dependant');
  const { dispatch } = useContext(AuthContext);
  const target = useRef<HTMLDivElement>(null);
  const targetSpan = useRef<HTMLSpanElement>(null);
  const { dependantUpdateOperation, dependantDeleteOperation } = dependant;

  const fields = [
    {
      name: t('dependant.actions.edit'),
    },
    {
      name: t('dependant.actions.cancel'),
    },
  ];

  const [showOverlay, setShowOverlay] = useState(false);
  const [openCancelDependantModal, setOpenCancelDependantModal] =
    useState(false);

  const waitingApprovalEditionData = {
    text: t('dependant.edited.wainting.approval'),
    icon: 'waitingApproval',
    color: '#48484C',
    backgroundColor: '#F1F1F6',
  };

  const waitingApprovalDeletionData = {
    text: t('dependant.deleted.wainting.approval'),
    icon: 'waitingApprovalRed',
    color: '#AA2E29',
    backgroundColor: '#FCECEE',
  };

  const newDependantData = {
    text: t('dependant.new'),
    color: '#006D2E',
    backgroundColor: '#E4F8ED',
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        target.current &&
        !target.current.contains(event.target as Node) &&
        targetSpan.current &&
        !targetSpan.current.contains(event.target as Node)
      ) {
        setShowOverlay(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const popover = (
    <Popover id="popover-actions" className="popover-actions">
      <Popover.Body ref={target}>
        <table>
          <tbody>
            {fields.map((field) => (
              <tr
                key={field.name}
                onClick={() => {
                  actionType(field.name);
                  setShowOverlay(false);
                }}
              >
                <td className={`action ${field.name}`}>{field.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Popover.Body>
    </Popover>
  );

  function actionType(field: string) {
    if (field === fields[0].name) {
      dispatch(
        editCurrentDependant({
          dependantId: dependant.id,
          name: dependant.name,
          includeIR: dependant.includeIR,
        })
      );
      return navigate('/dependant/edit');
    }
    getCancelDependantModalData();
    setShowOverlay(false);
    setOpenCancelDependantModal(true);
    return '';
  }

  function getCancelDependantModalData(): ModalData {
    const name = nameSpliter(dependant?.name) ?? 'dependente';
    const modalTitle = t('modal.dependant.cancel.title', { name });

    return {
      rightButtonText: t('back.app.button.text'),
      leftButtonText: t('modal.confirm.cancel.dependant'),
      icon: 'warning',
      title: modalTitle,
    };
  }

  const waitingApprovalText = (operation?: string) => {
    if (operation) {
      return operation === 'NOT_STARTED' || operation === 'ANALYSIS';
    }
    return false;
  };

  return (
    <Container fluid className="dependant-detail-container">
      <Row className="header">
        <Col className="title">
          Dependente {idx + 1}
          {newDependant ? <NewDependant data={newDependantData} /> : ''}
        </Col>
        {!disableActions ? (
          <OverlayTrigger
            placement="bottom-end"
            trigger="click"
            overlay={popover}
            show={showOverlay}
            onToggle={() => setShowOverlay(!showOverlay)}
            onExit={() => {
              setShowOverlay(false);
            }}
          >
            <Col>
              <div className={`actions ${showOverlay ? 'active' : ''}`}>
                <button
                  type="button"
                  aria-hidden="true"
                  className="display-actions"
                >
                  {showOverlay
                    ? t('dependant.actions.close.button')
                    : t('dependant.actions.open.button')}
                  <Icon name={showOverlay ? 'arrowUp' : 'arrowDown'} />
                </button>
              </div>
            </Col>
          </OverlayTrigger>
        ) : (
          <Col>
            <div className="actions">
              <button type="button" disabled className="disabled-actions">
                {t('dependant.actions.open.button')}
                <Icon name="arrowDownDisabled" />
              </button>
            </div>
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <DependantDetailFields dependant={dependant} />
        </Col>
      </Row>
      {waitingApprovalText(dependantUpdateOperation?.status) && (
        <WaitingApproval data={waitingApprovalEditionData} />
      )}
      {waitingApprovalText(dependantDeleteOperation?.status) && (
        <WaitingApproval data={waitingApprovalDeletionData} />
      )}

      <CancelDependantModal
        close={() => {
          dispatch(
            cancelCurrentDependant({
              ...dependant,
            })
          );
          navigate('/dependant/delete');
        }}
        handleConfirm={() => {
          setOpenCancelDependantModal(false);
        }}
        open={openCancelDependantModal}
        showLeftButton={openCancelDependantModal}
        data={getCancelDependantModalData()}
      />
    </Container>
  );
}

export default DependantDetails;
