import React from 'react';
import { Card, Placeholder, Col } from 'react-bootstrap';

type GlowPlaceholderProps = React.ComponentProps<typeof Placeholder>;

function GlowPlaceholder(props: GlowPlaceholderProps) {
  return <Placeholder as={Card.Text} animation="glow" {...props} />;
}

type PlaceholderSize = 'xs' | 'sm' | 'lg';

type SkeletonColProps = {
  xl?: number;
  xs?: number;
  className?: string;
  style?: React.CSSProperties;
  size?: PlaceholderSize;
};

function AppSkeleton({ xs, xl, size, style, className }: SkeletonColProps) {
  const mergedStyles = {
    borderRadius: 'var(--border-radius)',
    backgroundColor: 'var(--opacity-black-08)',
    ...style,
  };
  return (
    <Col data-testid="app-skeleton" className={className} xl={xl}>
      <GlowPlaceholder>
        <Placeholder xs={xs} size={size} style={mergedStyles} />
      </GlowPlaceholder>
    </Col>
  );
}

export default AppSkeleton;
