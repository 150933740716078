import React from 'react';
import AppSkeleton from 'components/AppSkeleton';
import './styles.scss';

function ProfileHeaderLoader() {
  return (
    <div className="profile-header-loader-container">
      <div className="image-loader">
        <AppSkeleton
          data-testid="profile-header-loader"
          style={{ width: '200px', height: '200px', borderRadius: '50%' }}
        />
      </div>
      <div className="titles-loader">
        <div className="title-element">
          <AppSkeleton
            data-testid="profile-header-loader"
            style={{ width: '428px', height: '48px' }}
          />
        </div>
        <div className="title-element">
          <AppSkeleton
            data-testid="profile-header-loader"
            style={{ width: '264px', height: '24px' }}
          />
        </div>
      </div>
    </div>
  );
}

export default ProfileHeaderLoader;
