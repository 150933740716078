import React from 'react';
import Icon from 'components/Icon';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { DependantBenefitOption } from 'types/models/Benefit';
import './styles.scss';

export type ManageDependantsProps = {
  valueDependant: number;
  handleRemoveAll: () => void;
  handleIncludeAll: () => void;
  included: Array<DependantBenefitOption>;
  available: Array<DependantBenefitOption>;
  setIncluded: (included: Array<DependantBenefitOption>) => void;
  setAvailable: (available: Array<DependantBenefitOption>) => void;
};

function ManageDependants({
  included,
  available,
  setIncluded,
  setAvailable,
  valueDependant,
  handleRemoveAll,
  handleIncludeAll,
}: ManageDependantsProps) {
  const { t } = useTranslation('dependant');

  function handleChooseDependantsClick(dependant: DependantBenefitOption) {
    if (included.includes(dependant)) {
      setAvailable([...available, dependant]);
      setIncluded(included.filter((item) => item !== dependant));
    } else {
      setIncluded([...included, dependant]);
      setAvailable(available.filter((item) => item !== dependant));
    }
  }

  return (
    <div
      data-testid="manage-dependants-component"
      className="choose-dependants"
    >
      <Card className="left">
        <Card.Header>
          <Container>
            <Row>
              <Col
                className={`included ${included.length === 0 ? 'empty' : ''}`}
              >
                <span className="title">{t('manage.included.dependats')}</span>
                <button
                  data-testid="removeAllButton"
                  type="button"
                  disabled={included.length === 0}
                  onClick={() => {
                    handleRemoveAll();
                  }}
                  className="actions removing"
                >
                  <span>{t('manage.remove.dependats')}</span>
                  <Icon
                    name={`${
                      included.length === 0
                        ? 'minusCircle'
                        : 'minusCircleOrange'
                    }`}
                  />
                </button>
              </Col>
            </Row>
          </Container>
        </Card.Header>
        <Card.Body>
          <Container>
            <Row>
              <Col
                className={`included ${included.length === 0 ? 'empty' : ''}`}
              >
                {included.map((dependant: DependantBenefitOption) => (
                  <div className="item" key={dependant.idDependant}>
                    <span>{dependant.name}</span>
                    <div className="separator">
                      <button
                        data-testid="removeDependantButton"
                        type="button"
                        onClick={() => handleChooseDependantsClick(dependant)}
                        className="fab"
                      >
                        <Icon name="minusCircleBgwhite" />
                      </button>
                    </div>
                  </div>
                ))}
                {included.length === 0 && (
                  <div className="empty-warning">
                    {t('manage.no.dependants.into.benefit')}
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </Card.Body>
        <Card.Footer>
          <Container>
            <Row>
              <Col
                className={`included ${included.length === 0 ? 'empty' : ''}`}
              >
                <h6>{t('manage.total.value')}</h6>
                <span>
                  R${' '}
                  {(valueDependant * included.length)
                    .toFixed(2)
                    .toString()
                    .replace('.', ',')}
                </span>
              </Col>
            </Row>
          </Container>
        </Card.Footer>
      </Card>

      <Card className="right">
        <Card.Header>
          <Container>
            <Row>
              <Col
                className={`available ${available.length === 0 ? 'empty' : ''}`}
              >
                <span className="title">{t('manage.dependants.to.add')}</span>
                <button
                  data-testid="includeAllButton"
                  type="button"
                  disabled={available.length === 0}
                  onClick={() => {
                    handleIncludeAll();
                  }}
                  className="actions adding"
                >
                  <span>{t('manage.add.all.dependants')}</span>
                  <Icon
                    name={`${
                      available.length === 0 ? 'plusCircle' : 'plusCircleBlue'
                    }`}
                  />
                </button>
              </Col>
            </Row>
          </Container>
        </Card.Header>
        <Card.Body>
          <Container>
            <Row>
              <Col
                className={`available ${available.length === 0 ? 'empty' : ''}`}
              >
                {available.map((dependant: DependantBenefitOption) => (
                  <div className="item" key={dependant.idDependant}>
                    <span>{dependant.name}</span>
                    <div className="separator">
                      <button
                        data-testid="addDependantButton"
                        type="button"
                        name="addDependant"
                        onClick={() => handleChooseDependantsClick(dependant)}
                        className="fab blue"
                      >
                        <Icon name="plusCircleBgwhite" />
                      </button>
                    </div>
                  </div>
                ))}
                {available.length === 0 && (
                  <div className="empty-warning">
                    {t('manage.no.dependants.to.add')}
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </Card.Body>
        <Card.Footer>
          <Container>
            <Row>
              <Col
                className={`available ${available.length === 0 ? 'empty' : ''}`}
              >
                <h6>{t('manage.cost.per.dependant')}</h6>
                <span>R$ {valueDependant.toString().replace('.', ',')}</span>
              </Col>
            </Row>
          </Container>
        </Card.Footer>
      </Card>
    </div>
  );
}

export default ManageDependants;
