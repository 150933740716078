import { useContext, useEffect } from 'react';
import AuthContext from 'configs/context';
import { resume } from 'store/user/actions';
import ProfileService from 'services/ProfileService';

export function useContractType() {
  const { state, dispatch } = useContext(AuthContext);
  const { contractType } = state.resume;

  useEffect(() => {
    if (!contractType) {
      ProfileService.getProfileResume().then((res) => {
        dispatch(resume(res.data));
      });
    }
  }, [contractType, dispatch]);

  return contractType;
}
