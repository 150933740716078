import React, { useEffect } from 'react';
import { msalInstance } from 'configs/msal-instance';

function Logout() {
  useEffect(() => {
    const logout = async () => {
      await msalInstance.logoutRedirect({
        account: msalInstance.getAllAccounts()[0],
        postLogoutRedirectUri: '/',
      });
    };
    logout();
  });

  return null;
}

export default Logout;
