import { ContractType } from 'types/common/ContractType';

export function isValidContractType(
  contract: string
): contract is ContractType {
  return (
    contract === 'CLT' ||
    contract === 'PJ' ||
    contract === 'INTERNSHIP' ||
    contract === 'FOREIGN_CLT' ||
    contract === 'PARTNER'
  );
}
