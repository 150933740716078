import React, { useEffect } from 'react';
import './styles.scss';
import { Trans, useTranslation } from 'react-i18next';
import DateUtils from 'utils/DateUtils';

export type BenefitAdjustWarningInfoProps = {
  benefitFinalDate?: string;
};

function BenefitAdjustWarningInfo({
  benefitFinalDate = '',
}: BenefitAdjustWarningInfoProps) {
  const { t } = useTranslation('benefit');
  return (
    <div className="benefit-adjust-info-container">
      <div className="title">
        <span>{t('benefit.adjust.warning.modal.info.title')}</span>
      </div>
      <div className="info-content">
        <ol className="list">
          <li className="list-item">
            <div className="item-content">
              <div className="bullet">1</div>
              <span className="description">
                <Trans
                  t={t}
                  i18nKey={t('benefit.adjust.warning.modal.info.item.1')}
                  components={{ bold: <span className="bold" /> }}
                />
              </span>
            </div>
            <ul className="sublist">
              <li className="sublist-item">
                <div className="sublist-bullet">•</div>
                <div className="sublist-text">
                  {t('benefit.adjust.warning.modal.info.item.1.sub.1')}
                </div>
              </li>
              <li className="sublist-item">
                <div className="sublist-bullet">•</div>
                <div className="sublist-text">
                  <Trans
                    t={t}
                    i18nKey={t(
                      'benefit.adjust.warning.modal.info.item.1.sub.2'
                    )}
                    components={{ bold: <span className="bold" /> }}
                  />
                </div>
              </li>
            </ul>
          </li>
          <li className="list-item">
            <div className="item-content">
              <div className="bullet">2</div>
              <span className="description">
                <Trans
                  t={t}
                  i18nKey={t('benefit.adjust.warning.modal.info.item.2')}
                  components={{ bold: <span className="bold" /> }}
                />
              </span>
            </div>
            <ul className="sublist">
              <li className="sublist-item">
                <div className="sublist-bullet">•</div>
                <div className="sublist-text">
                  {t('benefit.adjust.warning.modal.info.item.2.sub.1')}
                </div>
              </li>
              <li className="sublist-item">
                <div className="sublist-bullet">•</div>
                <div className="sublist-text">
                  {t('benefit.adjust.warning.modal.info.item.2.sub.2')}
                </div>
              </li>
              <li className="sublist-item">
                <div className="sublist-bullet">•</div>
                <div className="sublist-text">
                  {t('benefit.adjust.warning.modal.info.item.2.sub.3')}
                </div>
              </li>
            </ul>
          </li>
          <li className="list-item">
            <div className="item-content last">
              <div className="bullet red">3</div>
              <span className="description">
                <Trans
                  t={t}
                  i18nKey={t('benefit.adjust.warning.modal.info.item.3.1')}
                  components={{ bold: <span className="bold" /> }}
                />
                {benefitFinalDate &&
                  DateUtils.format(benefitFinalDate, 'dd/MM/yyyy')}
                {t('benefit.adjust.warning.modal.info.item.3.2')}
              </span>
            </div>
          </li>
        </ol>
      </div>
    </div>
  );
}

export default BenefitAdjustWarningInfo;
