import React from 'react';
import { Card, Col } from 'react-bootstrap';
import AppSkeleton from 'components/AppSkeleton';
import './styles.scss';

const left = [
  {
    id: 1,
    name: 'header',
    width: ['100%', '180px'],
    height: ['32px', '32px'],
    className: ['first', 'second'],
  },
  {
    id: 2,
    name: 'first-row',
    width: ['100%', '24px'],
    height: ['22px', '24px'],
    className: ['first', 'second'],
  },
  {
    id: 3,
    name: 'second-row',
    width: ['100%', '24px'],
    height: ['22px', '24px'],
    className: ['first', 'second'],
  },
];

const right = [
  {
    id: 1,
    name: 'header',
    width: ['100%', '180px'],
    height: ['32px', '32px'],
    className: ['first', 'second'],
  },
  {
    id: 2,
    name: 'first-row',
    width: ['100%', '24px'],
    height: ['22px', '24px'],
    className: ['first', 'second'],
  },
  {
    id: 3,
    name: 'second-row',
    width: ['100%', '24px'],
    height: ['22px', '24px'],
    className: ['first', 'second'],
  },
  {
    id: 4,
    name: 'third-row',
    width: ['100%', '24px'],
    height: ['22px', '24px'],
    className: ['first', 'second'],
  },
];

function ManageDependantsLoading() {
  return (
    <Card
      data-testid="choose-dependants-loading"
      className="choose-dependants loading"
    >
      <Card.Body>
        <Col className="left">
          <div className="border-right">
            {left.map((item) => (
              <div key={`${item.id}-${item.name}`} className="inside">
                {item.width.map((width, i) => (
                  <div
                    key={`${item.name}-${item.id} ${item.width[i]}`}
                    className={`item ${item.name}`}
                  >
                    <AppSkeleton
                      className={item.className[i]}
                      style={{
                        width,
                        height: item.height[i],
                      }}
                      xl={12}
                      xs={12}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
          <Col className="footer">
            <AppSkeleton
              className="first-col"
              style={{ height: '14px', width: '96px' }}
              xl={2}
            />
            <AppSkeleton
              className="second-col"
              style={{ height: '14px', width: '96px' }}
              xl={3}
            />
          </Col>
        </Col>
        <Col className="right">
          {right.map((item) => (
            <div key={item.id} className="inside">
              {item.width.map((width, i) => (
                <div
                  key={`${item.name} ${item.className[i]}`}
                  className={`item ${item.name}`}
                >
                  <AppSkeleton
                    className={item.className[i]}
                    style={{
                      width,
                      height: item.height[i],
                    }}
                    xl={12}
                    xs={12}
                  />
                </div>
              ))}
            </div>
          ))}
          <Col className="footer -right">
            <AppSkeleton style={{ height: '14px', width: '96px' }} xl={2} />
            <AppSkeleton
              className="second-col"
              style={{ height: '14px', width: '96px' }}
              xl={3}
            />
          </Col>
        </Col>
      </Card.Body>
    </Card>
  );
}

export default ManageDependantsLoading;
