import React, { useContext, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, UseFormReturn } from 'react-hook-form';
import DependantEditForm from 'components/Dependants/DependantEditForm';
import * as FormModel from 'components/Dependants/models';
import { FORM_SCHEMA_DEPENDANT_EDIT } from 'components/Dependants/validation';
import AppButton from 'components/AppButton';
import Icon from 'components/Icon';
import DependantService from 'services/DependantService';
import ConfirmationBackModal from 'components/Shared/ConfirmationBackModal';
import ModalWithLoading from 'components/Shared/ModalWithLoading';
import AuthContext from 'configs/context';
import { DependantEditProfile } from 'components/Dependants/models';
import './styles.scss';

type DependantOperationModalData = {
  type: string;
  icon: string;
  title: string;
  description: React.ReactNode | string;
  rightButtonText: string;
  leftButtonText: string;
};

function DependantEdit() {
  const navigate = useNavigate();
  const { t } = useTranslation('dependant');
  const DEFAULT_MODAL_DATA: DependantOperationModalData = {
    type: 'send',
    rightButtonText: t('button.label.send.solicitation'),
    leftButtonText: t('modal.cancel.button'),
    icon: 'errorIcon',
    title: t('modal.something.went.wrong.title'),
    description: t('modal.something.went.wrong.description'),
  };

  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openBackModal, setOpenBackModal] = useState(false);
  const [showLeftButton, setShowLeftButton] = useState(true);
  const [modalData, setModalData] =
    useState<DependantOperationModalData>(DEFAULT_MODAL_DATA);

  const {
    state: { editDependant },
  } = useContext(AuthContext);

  const formMethods: UseFormReturn<FormModel.DependantEditProfile> =
    useForm<FormModel.DependantEditProfile>({
      resolver: yupResolver(FORM_SCHEMA_DEPENDANT_EDIT),
      mode: 'onBlur',
      defaultValues: {
        name: '',
      },
    });

  const handleConfirm = async (dataRequest: FormModel.DependantEditProfile) => {
    setLoading(true);
    setModalData({
      ...modalData,
      type: 'loading',
      icon: '',
      title: t('modal.loading.title'),
      description: t('modal.loading.description'),
    });

    const data: DependantEditProfile = {
      dependantId: dataRequest.dependantId,
    };

    if (dataRequest.includeIR !== editDependant.includeIR) {
      data.includeIR = dataRequest.includeIR;
    }
    if (dataRequest.name !== editDependant.name) {
      data.name = dataRequest.name;
    }

    await DependantService.updateDependantInfo(data)
      .then(() => {
        setLoading(false);
        setShowLeftButton(false);
        setModalData({
          ...modalData,
          type: 'success',
          icon: 'sentSuccess',
          title: `${t('modal.edit.request.sent.title')}`,
          description: (
            <>
              {t('dependant.operation.edit.approved.description')}
              <span style={{ fontWeight: 'bold' }}>
                {t('dependant.operation.description.email')}
              </span>
            </>
          ),
          rightButtonText: `${t('modal.success.confirm.button')}`,
        });
      })
      .catch(() => {
        setLoading(false);
        setShowLeftButton(false);
        setModalData({
          ...modalData,
          type: 'error',
          icon: 'errorIcon',
          title: t('modal.error.title'),
          description: t('modal.error.description'),
          rightButtonText: t('back.app.button.text'),
        });
      });
  };

  function handleDisable(form: UseFormReturn<any, any>): boolean {
    const { isDirty, isValid } = form.formState;
    const shouldDisable = !isDirty || !isValid;
    return shouldDisable;
  }

  function modalConfirm() {
    const { type } = modalData;
    switch (type) {
      case 'error':
        setOpenModal(false);
        setModalData(DEFAULT_MODAL_DATA);
        break;
      case 'send':
        handleConfirm(formMethods.getValues());
        break;
      case 'success':
        navigate('/dependant');
        break;
      default:
        break;
    }
  }

  return (
    <div className="edit-container main-padding">
      <div className="page-header">
        <Row className="navigation">
          <Col
            className="navigation-link"
            onClick={() => setOpenBackModal(true)}
          >
            <Icon name="chevronLeftGray" />
            <span className="text">{t('edit.back')}</span>
          </Col>
        </Row>
        <hr className="separator" />
        <div className="title-box">
          <h1 className="title">{t('edit.header.title')}</h1>
          <p className="description">{t('edit.header.description')}</p>
        </div>
      </div>
      <section className="edit-section">
        <DependantEditForm formMethods={formMethods} />
        <Row className="edit-button">
          <Col>
            <AppButton
              full
              disabled={handleDisable(formMethods)}
              onClick={() => {
                setOpenModal(true);
                setShowLeftButton(true);
                setModalData({
                  ...modalData,
                  rightButtonText: t('button.label.send.solicitation'),
                  icon: 'warning',
                  title: t('modal.edit.title'),
                  description: t('modal.edit.description'),
                });
              }}
            >
              {t('button.label.send.solicitation')}
            </AppButton>
          </Col>
        </Row>
      </section>
      <ConfirmationBackModal
        open={openBackModal}
        handleClose={() => navigate('/dependant')}
        handleConfirm={() => setOpenBackModal(false)}
      />

      <ModalWithLoading
        open={openModal}
        data={modalData}
        loading={loading}
        showFooter={!loading}
        showLeftButton={showLeftButton}
        close={() => setOpenModal(false)}
        handleConfirm={() => modalConfirm()}
      />
    </div>
  );
}

export default DependantEdit;
