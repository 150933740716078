import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { Col, Row } from 'react-bootstrap';
import './style.scss';

export type TabProps = {
  eventKey: string;
  title: string;
  isDisabled?: boolean;
  component: (props?: { isActive?: boolean }) => React.ReactElement;
};

type TabsProps = {
  tabs: TabProps[];
  activeTab: string;
  selectTab: (tab: string) => void;
};

function TabsContainer({ tabs, activeTab, selectTab }: TabsProps) {
  return (
    <Tab.Container id="tab-container" defaultActiveKey="my-profile">
      <Row className="tabs-container menu main-padding">
        <Col className="pe-0 ps-0 d-flex">
          <Nav variant="pills">
            {tabs.map((tab) => (
              <Nav.Item key={tab.eventKey}>
                <Nav.Link
                  active={activeTab === tab.eventKey}
                  onClick={() => selectTab(tab.eventKey)}
                  className={`${tab.eventKey} ${
                    tab.isDisabled ? 'd-none' : ''
                  }`}
                  disabled={tab.isDisabled}
                  eventKey={tab.eventKey}
                >
                  {tab.title}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Col>
      </Row>
      <Row className="tabs-container main-padding">
        <Col className="pe-0 ps-0">
          <Tab.Content>
            {tabs.map((tab) => (
              <Tab.Pane
                active={activeTab === tab.eventKey}
                key={tab.eventKey}
                eventKey={tab.eventKey}
              >
                {tab.component({ isActive: activeTab === tab.eventKey })}
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
}

export default TabsContainer;
