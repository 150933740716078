import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon';
import './styles.scss';

function FirstDependantAnalysis() {
  const { t } = useTranslation('dependant');

  return (
    <div
      data-testid="first-dependant-analysis"
      className="first-dependant-analysis-container"
    >
      <Icon name="historyToggle" />
      <p className="title">{t('dependant.first.analysis.title')}</p>
      <p className="desc">
        {t('dependant.first.analysis.description.first.line')}
      </p>
      <p className="desc last-line">
        {t('dependant.first.analysis.description.second.line')}
      </p>
    </div>
  );
}

export default FirstDependantAnalysis;
