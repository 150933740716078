import { BenefitToManage } from 'types/models/Benefit';
import { DependantEditProfile, DependantProfile } from 'types/models/Dependant';
import { ProfileResume } from 'types/models/Profile';

export type UserStore = {
  resume: ProfileResume;
  loggedUser: LoggedUser;
  editDependant: DependantEditProfile;
  cancelDependant: DependantProfile;
  benefitToManage: BenefitToManage;
  idBenefitPlan: number;
  pendingOperation: boolean;
  reloadBenefit: boolean;
};

export type LoggedUser = {
  nameUser: string;
  imgUser: null | any;
};

export type StoreAction = {
  type: string;
  payload?:
    | ProfileResume
    | LoggedUser
    | DependantEditProfile
    | DependantProfile
    | BenefitToManage
    | number
    | boolean;
};

export const types = {
  RESUME: 'RESUME',
  LOGGED: 'LOGGED',
  EDIT_DEPENDANT: 'EDIT_DEPENDANT',
  CANCEL_DEPENDANT: 'CANCEL_DEPENDANT',
  ID_BENEFIT_PLAN: 'ID_BENEFIT_PLAN',
  PENDING_OPERATION: 'PENDING_OPERATION',
  MANAGE_BENEFIT: 'MANAGE_BENEFIT',
  RELOAD_BENEFIT: 'RELOAD_BENEFIT',
};
