import React from 'react';
import { Row } from 'react-bootstrap';
import {
  BenefitAmounts,
  BenefitOptions,
  DependantBenefitOption,
} from 'types/models/Benefit';
import ProgressBarBenefits from 'components/MyBenefits/ProgressBar';
import ConfirmationModal from 'components/Shared/ConfirmationModal';
import ModalTerms from 'components/Shared/ModalTerms';
import useScrollTop from 'hooks/useScrollTop';
import ManageDependants from '../ManageDependants';
import ManageDependantsStepLoading from './ManageDependantsStepLoading';
import ModalManageDependantsTerms from '../ModalManageDependantsTerms';

type ConfirmationModalData = {
  icon: string;
  title: string;
  description: React.ReactNode | string;
  rightButtonText: string;
};

type HeaderData = {
  title: React.ReactNode | string;
  description: React.ReactNode | string;
};

export type ManageDependantStepProps = {
  handleRemoveAll: () => void;
  handleIncludeAll: () => void;
  included: Array<DependantBenefitOption>;
  available: Array<DependantBenefitOption>;
  setIncluded: (included: Array<DependantBenefitOption>) => void;
  setAvailable: (available: Array<DependantBenefitOption>) => void;
  benefitOption: BenefitOptions;
  openTermsModal: boolean;
  setOpenTermsModal: React.Dispatch<React.SetStateAction<boolean>>;
  openWarningModal: boolean;
  setOpenWarningModal: React.Dispatch<React.SetStateAction<boolean>>;
  progressAmounts: BenefitAmounts;
  confirmTermsAndContinue: () => void;
  warningDataModal: ConfirmationModalData;
  header: HeaderData;
  loading: boolean;
  obsLabel?: React.ReactNode;
  termsModalType: 'ADD' | 'MANAGE';
};

function ManageDependantStep({
  loading,
  included,
  available,
  setIncluded,
  setAvailable,
  handleRemoveAll,
  handleIncludeAll,
  benefitOption,
  setOpenTermsModal,
  openTermsModal,
  openWarningModal,
  setOpenWarningModal,
  progressAmounts,
  confirmTermsAndContinue,
  warningDataModal,
  header,
  obsLabel,
  termsModalType,
}: ManageDependantStepProps) {
  useScrollTop();

  return (
    <Row>
      <div>
        <div>{header.title}</div>
        <div>{header.description}</div>
      </div>

      {loading ? (
        <ManageDependantsStepLoading />
      ) : (
        <>
          <div>
            <ProgressBarBenefits amounts={progressAmounts} />
          </div>

          <ManageDependants
            included={included}
            available={available}
            setIncluded={setIncluded}
            setAvailable={setAvailable}
            handleRemoveAll={handleRemoveAll}
            handleIncludeAll={handleIncludeAll}
            valueDependant={benefitOption.valueDependant}
          />

          {available.length === 0 && included.length === 0 && (
            <div>{obsLabel}</div>
          )}
        </>
      )}
      {termsModalType === 'ADD' && (
        <ModalTerms
          open={openTermsModal}
          confirm={confirmTermsAndContinue}
          close={() => setOpenTermsModal(false)}
        />
      )}
      {termsModalType === 'MANAGE' && (
        <ModalManageDependantsTerms
          open={openTermsModal}
          confirm={confirmTermsAndContinue}
          close={() => setOpenTermsModal(false)}
        />
      )}
      <ConfirmationModal
        showLeftButton={false}
        data={warningDataModal}
        open={openWarningModal}
        close={() => setOpenWarningModal(false)}
        handleConfirm={() => setOpenWarningModal(false)}
      />
    </Row>
  );
}

export default ManageDependantStep;
