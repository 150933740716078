import React from 'react';
import { Col, Row } from 'react-bootstrap';
import AppSkeleton from 'components/AppSkeleton';
import ProgressBarLoading from 'components/MyBenefits/ProgressBar/ProgressBarLoading';
import ManageDependantsLoading from 'components/Shared/ManageDependants/ManageDependantsLoading';
import './styles.scss';

function ManageDependantsStepLoading() {
  return (
    <div data-testid="loading" className="manage-dependants-loading">
      <ProgressBarLoading />
      <ManageDependantsLoading />
      <AppSkeleton
        xl={12}
        xs={12}
        style={{
          height: '58px',
          marginTop: '40px',
        }}
      />
      <Row className="buttons">
        <Col>
          <AppSkeleton
            className="skeleton"
            style={{ height: '48px', width: '100px', marginRight: '29px' }}
          />
          <AppSkeleton
            className="skeleton"
            style={{ height: '48px', width: '220px' }}
          />
        </Col>
      </Row>
    </div>
  );
}

export default ManageDependantsStepLoading;
