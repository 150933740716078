import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Image, Row } from 'react-bootstrap';
import { BenefitOperation, BenefitResume } from 'types/models/Benefit';
import benefitService from 'services/BenefitService';
import AuthContext from 'configs/context';
import Icon from 'components/Icon';
import { idBenefit } from 'store/user/actions';
import AppButton from 'components/AppButton';
import GenericTooltipMessage from 'components/Shared/GenericTooltipMessage';
import { AvailableBenefitsEnum, BenefitStatus } from './AvailableBenefitsEnum';
import './style.scss';

export type AvailableBenefitsProps = {
  operation?: BenefitOperation;
};

function AvailableBenefits({ operation }: AvailableBenefitsProps) {
  const navigate = useNavigate();
  const { t } = useTranslation('benefit');
  const {
    dispatch,
    state: { pendingOperation },
  } = useContext(AuthContext);

  const [benefitsAvailable, setBenefitsAvailable] = useState<
    Array<BenefitResume>
  >([]);

  useEffect(() => {
    let isMounted = true;

    benefitService.getAvailable().then((response) => {
      if (isMounted) {
        setBenefitsAvailable(response.data);
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className="available-benefits-container">
      <Row>
        {benefitsAvailable.map((benefit: BenefitResume) => (
          <Col sm={4} key={benefit.name} className="benefit-col">
            <div className="benefit-card">
              {(benefit.denomination ===
                AvailableBenefitsEnum.dentalAssistance &&
                (operation?.status === BenefitStatus.notStarted ||
                  operation?.status === BenefitStatus.analysis)) ||
              pendingOperation ? (
                <Image
                  src={benefit.urlImage}
                  className="card-image"
                  data-testid="disabled-image"
                  style={{ filter: 'grayscale(1)' }}
                />
              ) : (
                <Image
                  src={benefit.urlImage}
                  className="card-image"
                  data-testid={`enabled-image${benefit.id}`}
                />
              )}
              <div className="text-container">
                <h2 className="card-title">{benefit.name}</h2>
                <span className="card-description">{benefit.subtitle}</span>
              </div>
              {benefit.denomination &&
              benefit.denomination ===
                AvailableBenefitsEnum.dentalAssistance ? (
                <div className="button-div">
                  <AppButton
                    disabled={
                      operation?.status === BenefitStatus.notStarted ||
                      operation?.status === BenefitStatus.analysis ||
                      pendingOperation ||
                      benefit.benefitBlocked
                    }
                    onClick={() => {
                      dispatch(idBenefit(benefit.id));
                      navigate('/benefit/choose');
                    }}
                    outline
                  >
                    {t('solicitate.plan.button')}
                  </AppButton>
                  {(operation?.status === BenefitStatus.notStarted ||
                    operation?.status === BenefitStatus.analysis ||
                    benefit.benefitBlocked) && (
                    <GenericTooltipMessage
                      placement="right-end"
                      icon="attention"
                      message={
                        !benefit.benefitBlocked
                          ? t('tooltip.pendency.message')
                          : t('dental.completed.operation.tooltip.description')
                      }
                      title={
                        !benefit.benefitBlocked
                          ? t('tooltip.pendency.title')
                          : t('dental.completed.operation.tooltip.title')
                      }
                      type={operation?.status || 'benefit-blocked'}
                    />
                  )}
                </div>
              ) : (
                <AppButton outline disabled>
                  {t('add.basket.button')}
                </AppButton>
              )}
            </div>
          </Col>
        ))}
        {benefitsAvailable.length === 0 && (
          <Col>
            <div className="no-additional-benefits">
              <Icon name="volunteerActivism" />
              <div className="message">
                <h6 className="title">{t('using.all.benefits.title')}</h6>
                <span className="description">
                  {t('using.all.benefits.description')}
                </span>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
}

export default AvailableBenefits;
