import React from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AppModal from 'components/AppModal';
import './styles.scss';

type modalProps = {
  open: boolean;
  showFooter?: boolean;
  buttonText?: string;
  data: {
    id: number;
    icon?: string;
    title?: string;
    name?: string;
    description?: string;
    subtitle?: string;
  };
  close: () => void;
  handleConfirm: () => void;
};

function AdditionalBenefitsModal({
  data,
  open,
  buttonText,
  close,
  showFooter,
  handleConfirm,
}: modalProps) {
  const { t } = useTranslation('benefit');

  function setContactEmail(benefitId: number) {
    let email = '';
    if (benefitId === 7) {
      email = 'assuntos.rd@iteris.com.br';
    } else if (
      benefitId === 8 ||
      benefitId === 10 ||
      benefitId === 11 ||
      benefitId === 14
    ) {
      email = 'equipe.itc@iteris.com.br';
    } else {
      email = 'equipe.dp@iteris.com.br';
    }
    return email;
  }

  return (
    <AppModal
      className="additional-benefits-modal"
      rightButtonText={buttonText}
      open={open}
      showFooter={showFooter}
      confirm={() => {
        handleConfirm();
      }}
      close={() => {
        close();
      }}
      data-testid="modal"
    >
      <Row
        className="modal-with-benefits"
        data-testid="additional-benefits-modal"
      >
        <div className="header">
          <Row className="icon p-0">
            <img src={data?.icon} alt="icon" />
          </Row>
          <div className="title-text">
            <p data-testid="additional-benefits-title" className="title">
              {data?.name}
            </p>
            <p className="description">{data?.subtitle}</p>
          </div>
        </div>
        <Row>
          <div className="subtitle-text">
            <h4 className="title">{t('what.is.this.benefit')}</h4>
            <h4 className="description">{data?.description}</h4>
          </div>
        </Row>
        <Row>
          <div className="information-text">
            <h4 className="title">{t('for.more.information')}</h4>
            <h4 className="description">
              {t('send.an.email')}{' '}
              <a href={`mailto:${setContactEmail(data.id)}`} className="link">
                {setContactEmail(data.id)}
              </a>{' '}
              {t('answer.your.questions')}
            </h4>
          </div>
        </Row>
      </Row>
    </AppModal>
  );
}

export default AdditionalBenefitsModal;
