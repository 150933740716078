/* eslint-disable import/prefer-default-export */
export const RedistributeTexts = {
  'pt-BR': {
    'add.header.title': 'Redistribua a sua cesta',
    'add.header.description':
      'Utilize todo o valor disponível da sua cesta nos seus benefícios Alelo. <bold>Esta ação é obrigatória!</bold>',
    'warning.text': 'Atenção! O valor ultrapassou a sua cesta',
    'warning.description':
      'Os seus benefícios Alelo são zerados automaticamente e o valor excedente é <bold>descontado na sua folha de pagamento</bold>.',
    'warning.button': 'estou ciente e desejo continuar',
    'remaining.text': 'Valor restante para distribuir',
    'remaining.text.invalid': 'Você ultrapassou o valor disponível de',
    'redistribute.modal.zero.value.available.title':
      'Todo valor da cesta foi utilizado pelo plano médico e/ou odontológico!',
    'redistribute.modal.zero.value.available.description':
      'O valor total dos planos somam igual ao total da sua cesta, por isso seus benefícios Alelo foram zerados automaticamente.',
  },
};
