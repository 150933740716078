import { types, UserStore } from './types';

function reducer(state: UserStore, action: any): UserStore {
  switch (action.type) {
    case types.RESUME:
      return {
        ...state,
        resume: action.payload,
      };
    case types.LOGGED:
      return {
        ...state,
        loggedUser: action.payload,
      };

    case types.EDIT_DEPENDANT:
      return {
        ...state,
        editDependant: action.payload,
      };

    case types.CANCEL_DEPENDANT:
      return {
        ...state,
        cancelDependant: action.payload,
      };

    case types.MANAGE_BENEFIT:
      return {
        ...state,
        benefitToManage: action.payload,
      };

    case types.ID_BENEFIT_PLAN:
      return {
        ...state,
        idBenefitPlan: action.payload,
      };

    case types.PENDING_OPERATION:
      return {
        ...state,
        pendingOperation: action.payload,
      };

    case types.RELOAD_BENEFIT:
      return {
        ...state,
        reloadBenefit: action.payload,
      };
    default:
      return state;
  }
}

export default reducer;
