import React, { useContext, useEffect, useState } from 'react';
import AuthContext from 'configs/context';
import StandardImage from 'assets/icons/image-standard.svg';
import './style.scss';

type Props = {
  className: string;
  alt: string;
};

function UserPhoto({ className, alt }: Props) {
  const { state } = useContext(AuthContext);
  const [userImage, setUserImage] = useState(StandardImage);

  useEffect(() => {
    const image = new Image();
    image.onload = () => {
      setUserImage(state.loggedUser.imgUser);
    };
    image.src = state.loggedUser.imgUser;
  }, [state.loggedUser.imgUser]);

  return (
    <div className="user-photo-container">
      <img src={userImage} className={className} alt={alt} />
    </div>
  );
}

export default UserPhoto;
