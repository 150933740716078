import React, { useEffect, useState } from 'react';
import lodash from 'lodash';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import BenefitService from 'services/BenefitService';
import Icon from 'components/Icon';
import Redistribute from 'components/Shared/Redistribute';
import { BasketBenefit, BenefitsRedistribution } from 'types/models/Benefit';
import ConfirmationModal from 'components/Shared/ConfirmationModal';
import BenefitBasketRedistributeLoader from 'components/Benefits/BenefitBasketRedistribute/BenefitBasketRedistributeLoader';
import ModalWithLoadingTrans from 'components/Shared/ModalWithLoadingTrans';
import './styles.scss';

function BenefitBasketRedistribute() {
  const { t } = useTranslation('benefit');
  const navigate = useNavigate();
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [benefitDynamicLoading, setBenefitDynamicLoading] = useState(false);
  const [benefitAmountLoading, setBenefitAmountLoading] = useState(false);
  const [basketRedistribution, setBasketRedistribution] = useState<
    Array<BasketBenefit>
  >([]);
  const [currentBasketRedistribution, setCurrentBasketRedistribution] =
    useState<Array<BasketBenefit>>([]);
  const [totalAmount, setTotalAmount] = useState({
    total: 0,
    used: 0,
  });
  const [dataConfirmationModal, setDataConfirmationModal] = useState({
    type: '',
    icon: '',
    title: '',
    description: '',
    rightButtonText: '',
  });
  const { total, used } = totalAmount;
  useEffect(() => {
    setBenefitDynamicLoading(true);
    BenefitService.getDynamicBenefits().then((response) => {
      const formattedBenefits = response.data.map((b) => ({
        ...b,
      }));
      setBasketRedistribution(formattedBenefits);
      setCurrentBasketRedistribution(formattedBenefits);
      setBenefitDynamicLoading(false);
    });
  }, []);

  useEffect(() => {
    const disconsiderAleloAssist = true;
    setBenefitAmountLoading(true);
    BenefitService.getAmounts(disconsiderAleloAssist).then((response) => {
      setTotalAmount({
        total: response.data.total,
        used: response.data.used,
      });
      setBenefitAmountLoading(false);
    });
  }, []);

  const showLeftButton = true;

  const modalData = {
    leftButtonText: t('redistribute.modal.back.button'),
    rightButtonText: t('redistribute.modal.confirm.button'),
    icon: 'warning',
    title: t('redistribute.modal.title'),
    description: t('redistribute.modal.description'),
  };

  function disableConfirmButton() {
    let disableConfirm = false;
    const availableAmount = total - used;
    const roundedAvailableAmount = Math.max(
      parseFloat(availableAmount.toFixed(2)),
      0
    );
    const isAmountZero = availableAmount === 0;
    const basketRedistributionObject: Record<number, number> = {};
    const currentBasketRedistributionObject: Record<number, number> = {};
    let totalBasketDistributed = 0;

    basketRedistribution.forEach((item) => {
      totalBasketDistributed += item.value;
      basketRedistributionObject[item.id] = item.value;
    });

    currentBasketRedistribution.forEach((item) => {
      currentBasketRedistributionObject[item.id] = item.value;
    });

    const roundedTotalBasket = parseFloat(totalBasketDistributed.toFixed(2));

    if (
      lodash.isEqual(
        basketRedistributionObject,
        currentBasketRedistributionObject
      )
    ) {
      disableConfirm = true;
    }
    if (isAmountZero) disableConfirm = false;
    if (roundedAvailableAmount !== roundedTotalBasket) {
      disableConfirm = true;
    }
    return disableConfirm;
  }

  const openConfirmModal = () => {
    setOpenModal(true);
  };

  const handleConfirm = () => {
    setOpenModal(false);
    const basketRedistributionRequest: BenefitsRedistribution[] = [];
    basketRedistribution.forEach((b) => {
      const benefit: BenefitsRedistribution = {
        idBenefit: b.id,
        value: b.value,
      };
      basketRedistributionRequest.push(benefit);
    });
    redistributeBasket(basketRedistributionRequest);
  };

  async function redistributeBasket(
    basketRedistributionRequest: BenefitsRedistribution[]
  ) {
    setLoadingModal(true);
    setOpenConfirmationModal(true);
    setDataConfirmationModal({
      ...dataConfirmationModal,
      title: t('redistribute.modal.loading.title'),
      description: t('modal.loading.description'),
    });
    await BenefitService.redistributeBenefitBasket(basketRedistributionRequest)
      .then(() => {
        setLoadingModal(false);
        setDataConfirmationModal({
          type: 'success',
          icon: 'sentSuccess',
          title: t('redistribute.modal.success.title'),
          description: t('redistribute.modal.success.description'),
          rightButtonText: t('redistribute.modal.success.confirm.button'),
        });
      })
      .catch(() => {
        setLoadingModal(false);
        setDataConfirmationModal({
          type: 'error',
          icon: 'errorIcon',
          title: t('redistribute.modal.error.title'),
          description: t('redistribute.modal.error.description'),
          rightButtonText: t('modal.info.back.button'),
        });
      });
  }

  return (
    <div className="benefit-basket-container main-padding">
      {!benefitDynamicLoading && !benefitAmountLoading ? (
        <>
          <div className="page-header">
            <Row className="navigation">
              <Col
                className="navigation-link"
                onClick={() => navigate('/benefit/basket/edit')}
              >
                <Icon name="chevronLeftGray" />
                <span>{t('redistribute.edit.back')}</span>
              </Col>
            </Row>
            <hr />
            <div className="title">
              <div className="text">
                <h1>{t('redistribute.basket.header.title')}</h1>
                <p>{t('redistribute.basket.header.description')}</p>
              </div>
            </div>
          </div>
          <div className="basket-redistribute-container">
            <Redistribute
              benefits={basketRedistribution}
              setBenefits={setBasketRedistribution}
              totalAmount={totalAmount}
              checkboxValue={checkboxValue}
              setCheckboxValue={setCheckboxValue}
            />
            {!disableConfirmButton() && (
              <div className="obs-label">
                <Row data-testid="obs-label" className="obs-container">
                  <div className="icon">
                    <Icon name="helpCircle" />
                  </div>
                  <div className="text">
                    <p className="title">
                      <Trans
                        t={t}
                        i18nKey="redistribute.obs.label.title"
                        components={{ bold: <span className="bold" /> }}
                      />
                    </p>
                    <p className="description">
                      {t('redistribute.obs.label.description')}
                    </p>
                  </div>
                </Row>
              </div>
            )}

            <div className="button-container">
              <Button
                onClick={openConfirmModal}
                disabled={disableConfirmButton()}
                className="right-button"
                data-testid="confirm-redistribute-button"
              >
                {t('redistribute.button')}
              </Button>
            </div>
            <ConfirmationModal
              showLeftButton={showLeftButton}
              open={openModal}
              data={modalData}
              handleConfirm={handleConfirm}
              close={() => {
                setOpenModal(false);
              }}
            />
            <ModalWithLoadingTrans
              loading={loadingModal}
              showFooter={!loadingModal}
              data={dataConfirmationModal}
              open={openConfirmationModal}
              contextTranslation="benefit"
              close={() => setOpenConfirmationModal(false)}
              handleConfirm={() => {
                if (dataConfirmationModal.type === 'success') {
                  navigate('/benefit');
                  setOpenConfirmationModal(false);
                } else {
                  setOpenConfirmationModal(false);
                }
              }}
            />
          </div>
        </>
      ) : (
        <BenefitBasketRedistributeLoader />
      )}
    </div>
  );
}

export default BenefitBasketRedistribute;
