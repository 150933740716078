import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import AppSkeleton from 'components/AppSkeleton';
import AdditionalBenefitsLoader from 'components/MyBenefits/AdditionalBenefits/AdditionalBenefitsLoader';
import BenefitInformationLoader from 'components/Benefits/BenefitPlanEdit/BenefitInformation/BenefitInformationLoader';
import './styles.scss';

function BenefitPlanEditLoader() {
  const BenefitKeys = Array.from(
    { length: 3 },
    (_, index) => `benefit-${index}`
  );

  const BenefitOffersType = Array.from(
    { length: 15 },
    (_, index) => `benefit-information-1${index}`
  );

  return (
    <>
      <div className="navigation-section-skeleton">
        <AppSkeleton
          style={{
            width: '24px',
            height: '24px',
          }}
        />
        <AppSkeleton
          style={{
            width: '200px',
            height: '24px',
          }}
        />
      </div>
      <hr />
      <div className="benefit-header-skeleton">
        <AppSkeleton
          style={{
            width: '72px',
            height: '72px',
          }}
        />
        <div className="sub-section">
          <AppSkeleton
            style={{
              width: '418px',
              height: '48px',
            }}
          />
          <AppSkeleton
            style={{
              width: '312px',
              height: '18px',
              marginTop: '6px',
            }}
          />
        </div>
      </div>
      <Container
        fluid
        className="manage-benefit-skeleton"
        data-testid="benefits-skeleton"
      >
        <AppSkeleton
          style={{
            width: '300px',
            height: '32px',
            marginLeft: '8px',
            marginBottom: '24px',
          }}
        />
        <Row>
          {BenefitKeys.map((key) => (
            <Col
              sm={4}
              data-testid="benefit-loader"
              style={{ marginBottom: '18px' }}
              data-key={key}
              key={key}
            >
              <AdditionalBenefitsLoader />
            </Col>
          ))}
        </Row>
      </Container>
      <Container
        fluid
        className="manage-benefit-skeleton"
        data-testid="benefits-skeleton"
      >
        <AppSkeleton
          style={{
            width: '300px',
            height: '32px',
            marginLeft: '8px',
            marginBottom: '24px',
          }}
        />
        <Row>
          {BenefitKeys.map((key) => (
            <Col
              sm={4}
              data-testid="information-benefit-loader"
              style={{ marginBottom: '18px' }}
              data-key={key}
              key={key}
            >
              <BenefitInformationLoader />
            </Col>
          ))}
        </Row>
        <AppSkeleton
          style={{
            width: '300px',
            height: '24px',
            marginLeft: '8px',
            marginBottom: '24px',
          }}
        />
        <Col sm={12}>
          <div className="information-skeleton">
            <div className="information-column-skeleton">
              {BenefitOffersType.map((key) => (
                <div
                  className="intomation-line-skeleton"
                  data-key={key}
                  key={key}
                >
                  <AppSkeleton
                    style={{
                      width: '24px',
                      height: '24px',
                      marginLeft: '8px',
                    }}
                  />
                  <AppSkeleton
                    style={{
                      width: '90%',
                      height: '24px',
                      marginLeft: '8px',
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </Col>
        <div className="information-amounts-skeleton">
          <AppSkeleton
            style={{
              width: '300px',
              height: '24px',
              marginLeft: '8px',
            }}
          />
          <hr />
          <div className="values-skeleton">
            <AppSkeleton
              style={{
                width: '300px',
                height: '24px',
                marginLeft: '24px',
              }}
            />
            <AppSkeleton
              style={{
                width: '96px',
                height: '24px',
                float: 'right',
              }}
            />
          </div>
          <hr />
          <div className="values-skeleton">
            <AppSkeleton
              style={{
                width: '300px',
                height: '24px',
                marginLeft: '24px',
              }}
            />
            <AppSkeleton
              style={{
                width: '96px',
                height: '24px',
                float: 'right',
              }}
            />
          </div>
          <hr />
          <div className="values-skeleton">
            <AppSkeleton
              style={{
                width: '96px',
                height: '24px',
                marginLeft: '24px',
              }}
            />
            <AppSkeleton
              style={{
                width: '96px',
                height: '24px',
                float: 'right',
              }}
            />
          </div>
        </div>
      </Container>
    </>
  );
}

export default BenefitPlanEditLoader;
