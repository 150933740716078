const attachmentStep = 1;

export const isConjugeFilesValid = (
  idFileName: string | undefined,
  kinship: string,
  stepIndex: number,
  certificateFileName: string
) =>
  !(
    stepIndex === attachmentStep &&
    kinship === 'Cônjuge' &&
    idFileName === '' &&
    certificateFileName !== ''
  );
