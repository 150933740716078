import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Logout from 'components/Logout';
import Home from 'pages/Home';
import TabsController from 'pages/TabsController';
import EmployeeLoader from 'pages/EmployeeLoader';
import DependantAdd from 'pages/DependantAdd';
import DependantDelete from 'pages/DependantDelete';
import DependantEdit from 'pages/DependantEdit';
import BenefitBasketEdit from 'pages/BenefitBasketEdit';
import BenefitBasketRedistribute from 'pages/BenefitBasketRedistribute';
import BenefitCancel from 'pages/BenefitCancel';
import BenefitChoose from 'pages/BenefitChoose';
import BenefitManageDependants from 'pages/BenefitManageDependants';
import BenefitPlanEdit from 'pages/BenefitPlanEdit';
import BenefitPlanUpgrade from 'pages/BenefitPlanUpgrade/BenefitPlanUpgrade';
import BenefitPrivateRoutes from './BenefitPrivateRoutes';
import DependantPrivateRoutes from './DependantPrivateRoutes';

function OtherRoutes() {
  return (
    <Routes>
      <Route element={<EmployeeLoader />}>
        <Route element={<Home />}>
          <Route path="*" element={<TabsController />} />
        </Route>
        <Route element={<DependantPrivateRoutes />}>
          <Route path="/dependant/add" element={<DependantAdd />} />
          <Route path="/dependant/edit" element={<DependantEdit />} />
          <Route path="/dependant/delete" element={<DependantDelete />} />
        </Route>
        <Route element={<BenefitPrivateRoutes />}>
          <Route path="/benefit/plan/edit" element={<BenefitPlanEdit />} />
          <Route
            path="/benefit/plan/upgrade"
            element={<BenefitPlanUpgrade />}
          />
          <Route path="/benefit/basket/edit" element={<BenefitBasketEdit />} />
          <Route
            path="/benefit/manage-dependants"
            element={<BenefitManageDependants />}
          />
          <Route
            path="/benefit/basket/redistribute"
            element={<BenefitBasketRedistribute />}
          />
          <Route path="/benefit/choose" element={<BenefitChoose />} />
          <Route path="/benefit/cancel" element={<BenefitCancel />} />
        </Route>
      </Route>
      <Route path="/logout" element={<Logout />} />
    </Routes>
  );
}

export default OtherRoutes;
