import React from 'react';
import './styles.scss';
import AppSkeleton from 'components/AppSkeleton';
import ProgressBarLoading from 'components/MyBenefits/ProgressBar/ProgressBarLoading';

function BenefitBasketRedistributeLoader() {
  return (
    <div
      className="benefit-basket-redistribute-loader-container"
      data-testid="benefit-basket-redistribute-loader"
    >
      <div className="header-loader">
        <div className="nav-loader">
          <div className="element">
            <AppSkeleton style={{ width: '24px', height: '24px' }} />
          </div>
          <div className="element">
            <AppSkeleton style={{ width: '144px', height: '16px' }} />
          </div>
        </div>
        <div className="header-content">
          <div className="element">
            <AppSkeleton style={{ width: '72px', height: '72px' }} />
          </div>
          <div className="header-description-loader">
            <AppSkeleton style={{ width: '419px', height: '48px' }} />
            <AppSkeleton style={{ width: '312px', height: '19px' }} />
          </div>
        </div>
      </div>
      <div className="benefit-card-loader">
        <ProgressBarLoading />
        <div className="card-content">
          <div className="card-row">
            <div className="element">
              <AppSkeleton style={{ width: '40px', height: '40px' }} />
            </div>
            <div className="element">
              <AppSkeleton style={{ width: '180px', height: '24px' }} />
            </div>
            <div className="element bar" />

            <div className="element">
              <AppSkeleton style={{ width: '160px', height: '56px' }} />
            </div>
          </div>
          <div className="card-row">
            <div className="element">
              <AppSkeleton style={{ width: '40px', height: '40px' }} />
            </div>
            <div className="element">
              <AppSkeleton style={{ width: '180px', height: '24px' }} />
            </div>
            <div className="element bar" />

            <div className="element">
              <AppSkeleton style={{ width: '160px', height: '56px' }} />
            </div>
          </div>
          <div className="footer">
            <AppSkeleton style={{ width: '100%', height: '56px' }} />
            <div className="element">
              <AppSkeleton
                style={{
                  width: '294px',
                  height: '48px',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BenefitBasketRedistributeLoader;
