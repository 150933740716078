import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import Icon from 'components/Icon';
import AuthContext from 'configs/context';
import './style.scss';
import UserPhoto from 'components/UserPhoto';

function Header() {
  const { state } = useContext(AuthContext);

  return (
    <Row className="header-container main-padding">
      <Col className="container">
        <Icon name="iterisLogoColor" />
        <hr className="separator" />
        <h4 className="title">HRO Colaborador</h4>
        <div className="user">
          <span className="name">{state.loggedUser.nameUser}</span>
          <UserPhoto className="image" alt="profile" />
        </div>
      </Col>
    </Row>
  );
}

export default Header;
