export type AddDependantStep = {
  name: string;
  title: string;
  description: string;
  required: boolean;
  previousStep: string;
  nextStep: string;
};

const steps: Array<AddDependantStep> = [
  {
    name: 'profile',
    title: 'Informações do dependente',
    description: '',
    required: true,
    previousStep: '',
    nextStep: 'attachment',
  },
  {
    name: 'attachment',
    title: 'Anexar documentos',
    description: '',
    required: true,
    previousStep: 'profile',
    nextStep: 'benefits',
  },
  {
    name: 'benefits',
    title: 'Adicionar nos benefícios',
    description: '',
    required: true,
    previousStep: 'attachment',
    nextStep: '',
  },
];

export { steps };
