import React from 'react';
import AppSkeleton from 'components/AppSkeleton';
import { Col, Row } from 'react-bootstrap';
import './styles.scss';

function BasketBenefitsLoader() {
  return (
    <div
      className="basket-benefits-container-skeleton"
      data-testid="basket-benefits"
    >
      <Row className="header">
        <Col xs={10} className="content">
          <div className="icon">
            <AppSkeleton
              style={{ width: '56px', height: '56px' }}
              data-testid="app-skeleton"
            />
          </div>
          <div className="text">
            <Col className="name">
              <AppSkeleton
                style={{ width: '215px', height: '32px' }}
                data-testid="app-skeleton"
              />
            </Col>
            <Col className="description">
              <AppSkeleton
                style={{ width: '300px', height: '16px' }}
                data-testid="app-skeleton"
              />
            </Col>
          </div>
        </Col>
        <Col xs={2} className="actions">
          <div className="actions-inner">
            <Col>
              <AppSkeleton
                style={{ width: '140px', height: '48px' }}
                data-testid="app-skeleton"
              />
            </Col>
          </div>
        </Col>
      </Row>

      <div className="line">
        <div className="icon">
          <AppSkeleton
            style={{ width: '40px', height: '40px' }}
            data-testid="app-skeleton"
          />
        </div>
        <div>
          <AppSkeleton
            style={{
              width: '140px',
              height: '16px',
              marginTop: '12px',
              marginLeft: '8px',
            }}
          />
        </div>
        <div className="value">
          <AppSkeleton
            style={{ width: '140px', height: '16px' }}
            data-testid="app-skeleton"
          />
        </div>
      </div>
      <div className="line">
        <div className="icon">
          <AppSkeleton
            style={{ width: '40px', height: '40px' }}
            data-testid="app-skeleton"
          />
        </div>
        <div>
          <AppSkeleton
            style={{
              width: '140px',
              height: '16px',
              marginTop: '12px',
              marginLeft: '8px',
            }}
            data-testid="app-skeleton"
          />
        </div>
        <div className="value">
          <AppSkeleton
            style={{ width: '140px', height: '16px' }}
            data-testid="app-skeleton"
          />
        </div>
      </div>
      <div className="line total">
        <div className="icon">
          <AppSkeleton
            style={{ width: '40px', height: '40px' }}
            data-testid="app-skeleton"
          />
        </div>
        <div>
          <AppSkeleton
            style={{
              width: '140px',
              height: '16px',
              marginTop: '12px',
              marginLeft: '8px',
            }}
            data-testid="app-skeleton"
          />
        </div>
        <div className="value">
          <AppSkeleton
            style={{ width: '140px', height: '16px' }}
            data-testid="app-skeleton"
          />
        </div>
      </div>
    </div>
  );
}

export default BasketBenefitsLoader;
