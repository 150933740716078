import React, { useEffect } from 'react';
import { BasketBenefit } from 'types/models/Benefit';
import masks from 'utils/MasksUtils';
import './styles.scss';

type RedistributeSliderProps = {
  benefit: BasketBenefit;
  redistributeValue: number;
  total: number;
  benefitValues: number;
  sliderDisabled?: boolean;
  handleChange: (benefit: BasketBenefit, value: number) => void;
};

function RedistributeSlider({
  benefit,
  benefitValues,
  sliderDisabled,
  handleChange,
  redistributeValue,
  total,
}: RedistributeSliderProps) {
  const slideColor = (benefitId: number) => {
    const colorMap: Record<number, string> = {
      3: total > 0 ? '--color-primary-01-base' : '--color-full-black-32',
      4:
        total > 0
          ? '--color-complementary-01-contrast'
          : '--color-full-black-32',
      12: total > 0 ? '--color-secondary-contrast' : '--color-full-black-32',
    };

    return colorMap[benefitId];
  };

  const disabled = benefit.locked === true;

  const sliderValue = benefitValues * 100;

  const backgroundSize =
    total === 0 ? '0%' : `${(benefitValues / total) * 100}%`;

  const handleSliderChange = (e: number) => {
    let value = e / 100;
    if (value > redistributeValue) {
      value = redistributeValue;
    }

    handleChange(benefit, value * 100);
  };

  useEffect(() => {
    if (redistributeValue <= 0) {
      document
        .getElementById(`slider-${benefit.id}`)
        ?.style.setProperty(
          '--thumbBorderColor',
          `var(--color-primary-02-base)` || ''
        );
    } else {
      document
        .getElementById(`slider-${benefit.id}`)
        ?.style.setProperty(
          '--thumbBorderColor',
          `var(${slideColor(benefit.id)})` || ''
        );
    }
  }, []);

  return (
    <div className="benefit-slider">
      <div className="content">
        <img
          src={benefit.urlImage}
          alt="benefit"
          className={total <= 0 ? 'img gray' : 'img'}
        />
        <div className="text">
          <h1 className="title">{benefit.name}</h1>
        </div>
      </div>

      <div className="actions">
        <input
          min="0"
          type="range"
          max={total * 100}
          value={sliderValue}
          style={{
            backgroundSize,
            backgroundImage: `linear-gradient(var(${slideColor(
              benefit.id
            )}), var(${slideColor(benefit.id)}))`,
          }}
          id={`slider-${benefit.id}`}
          disabled={total <= 0 || disabled || sliderDisabled}
        />
        <input
          type="text"
          disabled={total <= 0 || disabled}
          onChange={(e) => {
            const reformatData = Number(e.target.value.replace(/[^0-9]+/g, ''));
            handleSliderChange(reformatData);
          }}
          className={redistributeValue < 0 ? 'value disabled' : 'value'}
          value={masks.currencyWithoutStyle(sliderValue)}
        />
      </div>
    </div>
  );
}

export default RedistributeSlider;
