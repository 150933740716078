import React from 'react';
import AppSkeleton from 'components/AppSkeleton';
import AppUploadFile from './AppUploadFile';

export type AppFileDisplayProps = {
  displayFile: boolean;
  isRemovingFile: boolean;
  fieldName: string;
  onRemove: (name: string, fieldName: string) => void;
  translation: any;
  fileName: string;
  fileNameToRemove: string;
};

function AppFileDisplay({
  displayFile,
  isRemovingFile,
  fieldName,
  onRemove,
  translation,
  fileName,
  fileNameToRemove,
}: AppFileDisplayProps) {
  switch (true) {
    case displayFile && !isRemovingFile:
      return (
        <AppUploadFile
          fileName={fileName}
          fieldName={fieldName}
          onRemove={onRemove}
          translation={translation}
          valid
          fileNameToRemove={fileNameToRemove}
        />
      );
    case isRemovingFile:
      return (
        <div data-testid="remove-skeleton">
          <AppSkeleton
            xs={1}
            size="lg"
            style={{
              height: '24px',
            }}
          />
          <AppSkeleton
            xs={12}
            size="lg"
            style={{
              height: '56px',
              marginTop: '5px',
            }}
          />
        </div>
      );
    default:
      return null;
  }
}

export default AppFileDisplay;
