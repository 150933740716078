import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeFixedBenefit } from 'types/models/Benefit';
import masks from 'utils/MasksUtils';
import { DependantProfile } from '../models';
import './styles.scss';

export type DependantBenefitProps = {
  idx: number;
  benefit: EmployeeFixedBenefit;
  dependant: DependantProfile | undefined;
  removeBenefit: (benefit: EmployeeFixedBenefit) => void;
  selectBenefit: (benefit: EmployeeFixedBenefit) => void;
};

function DependantBenefit({
  idx,
  benefit,
  dependant,
  removeBenefit,
  selectBenefit,
}: DependantBenefitProps) {
  const { t } = useTranslation('dependant');
  const { selected } = benefit;
  const totalValue =
    benefit.amountUsedByBenefit +
    (selected === true ? benefit.valueDependant || 0 : 0);

  const radioClass = (value: boolean) => {
    if (value) return 'selected';
    return 'not-selected';
  };

  return (
    <div className="dependant-benefit-container">
      <h1>
        {`${t('add.dependant')} ‘${dependant?.name}’ ${t('add.plan')} `}
        <b>{benefit.name}</b>?<span className="label-required">*</span>
      </h1>
      <div className="options">
        <label
          htmlFor={`opt-in${idx}_y`}
          className={selected === undefined ? 'neutral' : radioClass(selected)}
        >
          <input
            id={`opt-in${idx}_y`}
            name={`opt-in${idx}`}
            type="radio"
            onChange={() => {
              selectBenefit(benefit);
            }}
            checked={selected === true}
            value="true"
          />
          {t('yes.input')}
        </label>
        <label
          htmlFor={`opt-in${idx}_n`}
          className={selected === undefined ? 'neutral' : radioClass(!selected)}
        >
          <input
            id={`opt-in${idx}_n`}
            name={`opt-in${idx}`}
            type="radio"
            onChange={() => {
              removeBenefit(benefit);
            }}
            checked={selected === false}
            value="false"
          />
          {t('no.input')}
        </label>
      </div>
      <table className="benefit-table">
        <thead>
          <tr>
            <th colSpan={2}>
              <div className="content">
                <img
                  className={selected === false ? 'gray' : ''}
                  src={benefit.urlImage}
                  alt="Ícone do benefício"
                />
                <div className="text">
                  <h1>{benefit.name}</h1>
                  <p>{benefit.subtitle}</p>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t('actual.plan')}</td>
            <td>{benefit.option}</td>
          </tr>
          <tr className={selected ? 'additional' : 'disabled'}>
            <td>{t('new.dependant.cost')}</td>
            <td>
              {selected
                ? masks.currency(
                    benefit.valueDependant ? benefit.valueDependant : 0
                  )
                : masks.currency(0)}
            </td>
          </tr>
          <tr>
            <td>{t('actual.benefit.cost')}</td>
            <td>
              {masks.currency(
                benefit.amountUsedByBenefit ? benefit.amountUsedByBenefit : 0
              )}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>{t('total')}</td>
            <td>{masks.currency(totalValue)}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}

export default DependantBenefit;
