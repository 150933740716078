import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Row, Col } from 'react-bootstrap';
import masks from 'utils/MasksUtils';
import { BenefitAmounts, BenefitResumeWithId } from 'types/models/Benefit';
import './styles.scss';

export type Props = {
  totalAmount: BenefitAmounts;
  benefits: Array<BenefitResumeWithId>;
};

function BenefitBasketInformation({ benefits, totalAmount }: Props) {
  const { t } = useTranslation('benefit');

  const { total, used } = totalAmount;
  const aleloValue = total - used;

  return (
    <div className="benefit-information-container">
      <h1 className="title">{t('benefit.manage.info')}</h1>
      <Row>
        {benefits.map((benefit) => (
          <Col key={benefit.benefitId} xs={6}>
            <Card>
              <Card.Body>
                <img
                  className="logo"
                  src={benefit.urlImage}
                  alt="benefit icon"
                />
                <h3 className="title">
                  {masks.currency(benefit.valueEmployee || 0)}
                </h3>
                <p className="description">{benefit.name}</p>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <h2 className="types">{t('benefit.manage.cost.details')}</h2>
      <div className="cost-details-basket">
        <div className="line total">
          <span className="cost">{t('total.basket')}</span>
          <span className="value">{masks.currency(total || 0)}</span>
        </div>
        <div className="line">
          <span className="cost">{t('amil.total.value')}</span>
          <span className="value">{masks.currency(used || 0)} </span>
        </div>
        <div className="line">
          <span className="cost">{t('alelo.total.value')}</span>
          <span className="value">
            {masks.currency(aleloValue >= 0 ? aleloValue : 0)}
          </span>
        </div>
        {aleloValue < 0 && (
          <div className="line exceded">
            <span className="cost">{t('discount')}</span>
            <span className="value">
              {masks.currency(aleloValue < 0 ? aleloValue : 0)}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default BenefitBasketInformation;
