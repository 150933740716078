import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import l from 'lodash';
import {
  BasketBenefit,
  BenefitAmounts,
  DependantBenefitOption,
  ManageDependantBenefitRequest,
} from 'types/models/Benefit';
import AuthContext from 'configs/context';
import { useContractType } from 'hooks/useContractType';
import Icon from 'components/Icon';
import BenefitService from 'services/BenefitService';
import DependantService from 'services/DependantService';
import ManageDependants from 'components/Shared/ManageDependants';
import ProgressBarBenefits from 'components/MyBenefits/ProgressBar';
import ManageDependantsLoading from 'components/Shared/ManageDependants/ManageDependantsLoading';
import ProgressBarLoading from 'components/MyBenefits/ProgressBar/ProgressBarLoading';
import AppButton from 'components/AppButton';
import ConfirmationBackModal from 'components/Shared/ConfirmationBackModal';
import ModalManageDependantsTerms from 'components/Shared/ModalManageDependantsTerms';
import RedistributeModal from 'components/Shared/Redistribute/RedistributeModal';
import ModalWithLoadingTrans from 'components/Shared/ModalWithLoadingTrans';
import ConfirmationModalTrans from 'components/Shared/ConfirmationModalTrans';
import './styles.scss';
import { pendingOperations } from 'store/user/actions';

function BenefitManageDependants() {
  const navigate = useNavigate();
  const { t } = useTranslation('benefit');
  const {
    dispatch,
    state: { benefitToManage },
  } = useContext(AuthContext);
  const contractType = useContractType();
  const [loading, setLoading] = useState(false);
  const [benefitLoading, setBenefitLoading] = useState(false);
  const [benefitBasketLoading, setBenefitBasketLoading] = useState(false);
  const [confirmationModalLoading, setConfirmationModalLoading] =
    useState(false);
  const [included, setIncluded] = useState<DependantBenefitOption[]>([]);
  const [available, setAvailable] = useState<DependantBenefitOption[]>([]);
  const [currentIncluded, setCurrentIncluded] = useState<
    DependantBenefitOption[]
  >([]);
  const [currentAvailable, setCurrentAvailable] = useState<
    DependantBenefitOption[]
  >([]);
  const [dependants, setDependants] = useState<DependantBenefitOption[]>([]);
  const [openBackModal, setOpenBackModal] = useState<boolean>(false);
  const [openTermsModal, setOpenTermsModal] = useState(false);
  const [openRedistributeModal, setOpenRedistributeModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openInternshipWarningModal, setOpenInternshipWarningModal] =
    useState(false);
  const [basketRedistribution, setBasketRedistribution] = useState<
    Array<BasketBenefit>
  >([]);
  const [totalAmount, setTotalAmount] = useState({
    total: 0,
    used: 0,
  });
  const [dataConfirmationModal, setDataConfirmationModal] = useState({
    type: '',
    icon: '',
    title: '',
    description: '',
    rightButtonText: '',
  });
  const benefitDenomination = benefitToManage.benefit.denominationBenefit;

  const amount: BenefitAmounts = {
    total: totalAmount.total,
    used: totalAmountWithPlanValue().used,
  };

  const internshipWarningModalData = {
    rightButtonText: t('modal.intern.warning.close.btn'),
    icon: 'warning',
    title: t('benefit.manage.dependant.intern.warning.modal.title'),
    description: t('benefit.manage.dependant.intern.warning.modal.description'),
  };

  useEffect(() => {
    setLoading(true);
    DependantService.dependantOptions(benefitToManage.benefit.idBenefitOption)
      .then((res) => {
        setDependants(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [benefitToManage.benefit.idBenefitOption]);

  useEffect(() => {
    const disconsiderAleloAssist = true;
    let disconsiderDentalAssist = true;
    let disconsiderMedicalAssist = true;
    setBenefitLoading(true);
    if (benefitDenomination === 'ASSISTENCIA_ODONTOLOGICA') {
      disconsiderMedicalAssist = false;
    }
    if (benefitDenomination === 'ASSISTENCIA_MEDICA') {
      disconsiderDentalAssist = false;
    }
    BenefitService.getAmounts(
      disconsiderAleloAssist,
      disconsiderDentalAssist,
      disconsiderMedicalAssist
    ).then((response) => {
      setTotalAmount({
        total: response.data.total,
        used: response.data.used,
      });
      setBenefitLoading(false);
    });
  }, []);

  useEffect(() => {
    const planIncluded = dependants.filter((e) => e.includedPlan === true);
    setIncluded(planIncluded);
    setCurrentIncluded(planIncluded);

    const notPlanIncluded = dependants.filter((e) => e.includedPlan === false);
    setAvailable(notPlanIncluded);
    setCurrentAvailable(notPlanIncluded);
  }, [dependants]);

  useEffect(() => {
    setBenefitBasketLoading(true);
    BenefitService.getDynamicBenefits().then((response) => {
      const formattedBenefits = response.data.map((b) => ({
        ...b,
        value: 0,
      }));
      setBasketRedistribution(formattedBenefits);
      setBenefitBasketLoading(false);
    });
  }, []);

  const dentalBenefitText = {
    backButton: t('benefit.manage.dependant.back.button.dental'),
    headerTitle: t('benefit.manage.dependant.header.title.dental'),
  };

  const medicalBenefitText = {
    backButton: t('benefit.manage.dependant.back.button.medical'),
    headerTitle: t('benefit.manage.dependant.header.title.medical'),
  };

  function areDependantsEqual() {
    const currentDependantsAvailable: Record<number, string> = {};
    const dependantsAvailable: Record<number, string> = {};
    const currentDependantsIncluded: Record<number, string> = {};
    const dependantsIncluded: Record<number, string> = {};

    currentAvailable.forEach((d) => {
      currentDependantsAvailable[d.idDependant] = d.name;
    });
    available.forEach((d) => {
      dependantsAvailable[d.idDependant] = d.name;
    });
    currentIncluded.forEach((d) => {
      currentDependantsIncluded[d.idDependant] = d.name;
    });
    included.forEach((d) => {
      dependantsIncluded[d.idDependant] = d.name;
    });

    const isArrayListEquals =
      l.isEqual(currentDependantsAvailable, dependantsAvailable) &&
      l.isEqual(currentDependantsIncluded, dependantsIncluded);
    return isArrayListEquals;
  }

  function handleRemoveAll() {
    setIncluded([]);
    setAvailable(dependants);
  }

  function handleIncludeAll() {
    setAvailable([]);
    setIncluded(dependants);
  }

  function totalAmountWithPlanValue() {
    const valueDependant = benefitToManage.benefit.valueByDependant;
    const updatedUsedValue = valueDependant * included.length;

    return {
      total: totalAmount.total,
      used:
        totalAmount.used +
        updatedUsedValue +
        benefitToManage.benefit.valueEmployee,
    };
  }

  function disableConfirmButton() {
    return areDependantsEqual();
  }

  function undoChanges() {
    setAvailable(currentAvailable);
    setIncluded(currentIncluded);
  }

  function setBenefitText() {
    let benefitText = {
      backButton: '',
      headerTitle: '',
    };
    if (benefitDenomination === 'ASSISTENCIA_ODONTOLOGICA') {
      benefitText = dentalBenefitText;
    }
    if (benefitDenomination === 'ASSISTENCIA_MEDICA') {
      benefitText = medicalBenefitText;
    }
    return benefitText;
  }

  function handleBackAction() {
    if (areDependantsEqual()) {
      navigate('/benefit/plan/edit');
    } else {
      setOpenBackModal(true);
    }
  }

  function handleConfirm() {
    const amountValidate =
      contractType === 'INTERNSHIP' &&
      totalAmountWithPlanValue().used > totalAmount.total;
    if (amountValidate) {
      setOpenInternshipWarningModal(true);
    } else {
      setOpenTermsModal(true);
    }
  }

  function confirmTermsAndContinue() {
    setOpenTermsModal(false);
    setOpenRedistributeModal(true);
  }

  function confirmRedistributeModal(confirmedBenefits: Array<BasketBenefit>) {
    const manageDependantBenefitRequest: ManageDependantBenefitRequest =
      buildBenefitRequest(confirmedBenefits);
    setOpenConfirmationModal(true);
    setOpenRedistributeModal(false);
    setDataConfirmationModal({
      ...dataConfirmationModal,
      title: t('modal.loading.title'),
      description: t('modal.loading.description'),
    });

    if (benefitDenomination === 'ASSISTENCIA_MEDICA') {
      manageMedicalDependantBenefit(manageDependantBenefitRequest);
    }

    if (benefitDenomination === 'ASSISTENCIA_ODONTOLOGICA') {
      manageDentalDependantBenefit(manageDependantBenefitRequest);
    }
  }

  function buildBenefitRequest(confirmedBenefits: Array<BasketBenefit>) {
    const addedDependantsIdList: number[] = [];
    const removedDependantsIdList: number[] = [];

    included.forEach((d) => {
      if (!d.includedPlan) addedDependantsIdList.push(d.idDependant);
    });
    available.forEach((d) => {
      if (d.includedPlan) removedDependantsIdList.push(d.idDependant);
    });

    const manageDependantBenefitRequest: ManageDependantBenefitRequest = {
      idBenefit: benefitToManage.benefit.benefitId,
      idBenefitOption: benefitToManage.benefit.idBenefitOption,
      addedDependentsIds: addedDependantsIdList,
      removedDependentsIds: removedDependantsIdList,
      benefitRedistribution: [],
    };
    confirmedBenefits.forEach((benefit) => {
      manageDependantBenefitRequest.benefitRedistribution.push({
        idBenefit: benefit.id,
        value: benefit.value,
      });
    });
    return manageDependantBenefitRequest;
  }

  async function manageDentalDependantBenefit(
    manageDependantBenefitRequest: ManageDependantBenefitRequest
  ) {
    setConfirmationModalLoading(true);
    await BenefitService.manageDentalDependantBenefit(
      manageDependantBenefitRequest
    )
      .then(() => {
        setDataConfirmationModal({
          type: 'success',
          icon: 'sentSuccess',
          title: t('benefit.manage.dependant.modal.success.title'),
          description: t('benefit.manage.dependant.modal.success.description'),
          rightButtonText: t(
            'benefit.dental.manage.dependant.modal.success.confirm.btn'
          ),
        });
        dispatch(pendingOperations(true));
      })
      .catch(() => {
        setDataConfirmationModal({
          type: 'error',
          icon: 'errorIcon',
          title: t('modal.error.title'),
          description: t('modal.error.description'),
          rightButtonText: t('modal.info.back.button'),
        });
      })
      .finally(() => {
        setConfirmationModalLoading(false);
      });
  }

  async function manageMedicalDependantBenefit(
    manageDependantBenefitRequest: ManageDependantBenefitRequest
  ) {
    setConfirmationModalLoading(true);
    await BenefitService.manageMedicalDependantBenefit(
      manageDependantBenefitRequest
    )
      .then(() => {
        setDataConfirmationModal({
          type: 'success',
          icon: 'sentSuccess',
          title: t('benefit.manage.dependant.modal.success.title'),
          description: t('benefit.manage.dependant.modal.success.description'),
          rightButtonText: t(
            'benefit.medical.manage.dependant.modal.success.confirm.btn'
          ),
        });
        dispatch(pendingOperations(true));
      })
      .catch(() => {
        setDataConfirmationModal({
          type: 'error',
          icon: 'errorIcon',
          title: t('modal.error.title'),
          description: t('modal.error.description'),
          rightButtonText: t('modal.info.back.button'),
        });
      })
      .finally(() => {
        setConfirmationModalLoading(false);
      });
  }

  return (
    <div className="manage-dependant-container main-padding">
      <div className="page-header">
        <Row className="navigation">
          <Col className="navigation-link" onClick={handleBackAction}>
            <Icon name="chevronLeftGray" />
            <span>{setBenefitText().backButton}</span>
          </Col>
        </Row>
        <hr />
        <div className="title">
          <div className="text">
            <h1>{setBenefitText().headerTitle}</h1>
            <p>{t('benefit.manage.dependant.header.description')}</p>
          </div>
        </div>
      </div>
      <div className="manage-dependant-card-container">
        {loading || benefitLoading || benefitBasketLoading ? (
          <>
            <ProgressBarLoading />
            <ManageDependantsLoading />
          </>
        ) : (
          <>
            <div>
              <ProgressBarBenefits amounts={totalAmountWithPlanValue()} />
            </div>

            <ManageDependants
              included={included}
              available={available}
              setIncluded={setIncluded}
              setAvailable={setAvailable}
              handleRemoveAll={handleRemoveAll}
              handleIncludeAll={handleIncludeAll}
              valueDependant={benefitToManage.benefit.valueByDependant}
            />

            <div className="button-container">
              <AppButton
                onClick={undoChanges}
                disabled={disableConfirmButton()}
                className="left-button"
              >
                {t('undo.changes.button')}
              </AppButton>
              <AppButton
                full
                onClick={handleConfirm}
                disabled={disableConfirmButton()}
                className="right-button"
              >
                {t('send.solicitation.btn')}
              </AppButton>
            </div>
            <ConfirmationBackModal
              open={openBackModal}
              rightButtonText={t('stay.in.page.button')}
              handleClose={() => {
                setOpenBackModal(false);
                navigate('/benefit/plan/edit');
              }}
              handleConfirm={() => {
                setOpenBackModal(false);
              }}
            />
            <ModalManageDependantsTerms
              open={openTermsModal}
              confirm={confirmTermsAndContinue}
              close={() => setOpenTermsModal(false)}
            />
            <RedistributeModal
              disableConfirm={amount.total > amount.used}
              totalAmount={amount}
              benefits={basketRedistribution}
              open={openRedistributeModal}
              setBenefits={setBasketRedistribution}
              close={() => {
                const formattedBenefits = basketRedistribution.map((b) => ({
                  ...b,
                  value: 0,
                }));

                setOpenRedistributeModal(false);
                setBasketRedistribution(formattedBenefits);
              }}
              confirm={(confirmedBenefits) =>
                confirmRedistributeModal(confirmedBenefits)
              }
            />
            <ModalWithLoadingTrans
              loading={confirmationModalLoading}
              showFooter={!confirmationModalLoading}
              data={dataConfirmationModal}
              open={openConfirmationModal}
              contextTranslation="benefit"
              close={() => setOpenConfirmationModal(false)}
              handleConfirm={() => {
                if (dataConfirmationModal.type === 'success') {
                  navigate('/benefit/plan/edit');
                  setOpenConfirmationModal(false);
                } else {
                  setOpenRedistributeModal(true);
                  setOpenConfirmationModal(false);
                }
              }}
            />
            <ConfirmationModalTrans
              showLeftButton={false}
              data={internshipWarningModalData}
              open={openInternshipWarningModal}
              close={() => setOpenInternshipWarningModal(false)}
              handleConfirm={() => setOpenInternshipWarningModal(false)}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default BenefitManageDependants;
