import React from 'react';
import Dependants from 'pages/Dependants';
import Benefits from 'pages/MyBenefits/MyBenefits';
import MyProfile from 'pages/MyProfile';

export type TabPermission = {
  profile: boolean;
  dependant: boolean;
  benefit: boolean;
  'home-data': boolean;
  'bank-data': boolean;
  documents: boolean;
};

export type MenuTabs = Array<{
  eventKey: keyof TabPermission;
  title: string;
  isDisabled: boolean;
  component: (props?: { isActive?: boolean }) => JSX.Element;
}>;

export const tabs: MenuTabs = [
  {
    eventKey: 'profile',
    title: 'MEU PERFIL',
    isDisabled: false,
    component: (props) => <MyProfile />,
  },
  {
    eventKey: 'home-data',
    title: 'DADOS RESIDENCIAIS',
    isDisabled: true,
    component: (props) => <div> DADOS RESIDENCIAIS</div>,
  },
  {
    eventKey: 'documents',
    title: 'DOCUMENTOS',
    isDisabled: true,
    component: (props) => <div>DOCUMENTOS</div>,
  },
  {
    eventKey: 'bank-data',
    title: 'DADOS BANCÁRIOS',
    isDisabled: true,
    component: (props) => <div>DADOS BANCÁRIOS</div>,
  },
  {
    eventKey: 'dependant',
    title: 'DEPENDENTES',
    isDisabled: false,
    component: (props) => <Dependants {...props} />,
  },
  {
    // . Essa opção não deverá ser mostrada para PJs e sócios
    //
    eventKey: 'benefit',
    title: 'BENEFÍCIOS',
    isDisabled: false,
    component: (props) => <Benefits {...props} />,
  },
];
