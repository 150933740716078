import React from 'react';
import AppSkeleton from 'components/AppSkeleton';
import './styles.scss';

function MyProfileLoader() {
  const profileLoaderKeys = Array.from(
    { length: 7 },
    (_, index) => `profile-loader-${index}`
  );

  return (
    <div className="my-profile-loader-container">
      <div className="header-loader">
        <AppSkeleton
          data-testid="profile-row-loader"
          style={{ width: '300px', height: '32px' }}
        />
        <AppSkeleton
          data-testid="profile-row-loader"
          style={{ width: '300px', height: '16px', marginTop: '8px' }}
        />
      </div>
      {profileLoaderKeys.map((key, index) => (
        <div
          className="profile-loader"
          data-testid="profile-row-loader"
          data-key={key}
          key={key}
        >
          {index === 1 || index === 2 || index === 3 || index === 4 ? (
            <div className="profile-row">
              <div className="small-loader">
                <AppSkeleton style={{ width: '112px', height: '19px' }} />
              </div>
              <div className="separator" />
              <div className="large-loader">
                <AppSkeleton style={{ width: '100%', height: '19px' }} />
              </div>
              <div className="small-loader">
                <AppSkeleton style={{ width: '112px', height: '19px' }} />
              </div>
              <div className="separator" />
              <div className="large-loader">
                <AppSkeleton style={{ width: '100%', height: '19px' }} />
              </div>
            </div>
          ) : (
            <div
              className="profile-row"
              data-testid="profile-row-loader"
              data-key={key}
              key={key}
            >
              <div className="small-loader">
                <AppSkeleton style={{ width: '112px', height: '19px' }} />
              </div>
              <div className="separator" />
              <div className="large-loader">
                <AppSkeleton style={{ width: '100%', height: '19px' }} />
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default MyProfileLoader;
