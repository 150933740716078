import api from 'configs/api';
import Benefit, {
  BasketBenefit,
  BenefitResume,
  BenefitAmounts,
  BenefitOptions,
  EmployeeFixedBenefit,
  CreateBenefitRequest,
  CancelBenefitRequest,
  BenefitsList,
  BenefitsRedistribution,
  ManageDependantBenefitRequest,
  UpgradePlanBenefitRequest,
  AllowedUpgradeBenefit,
} from 'types/models/Benefit';

async function getBenefitsResume() {
  return api.get<BenefitsList>('/benefits');
}

async function getAdditional() {
  return api.get<Array<Benefit>>('/benefits/additional');
}

async function getAmounts(
  redistribute?: boolean,
  isDentalBenefit?: boolean,
  isMedicalBenefit?: boolean
) {
  return api.get<BenefitAmounts>('/benefits/amounts', {
    params: { redistribute, isDentalBenefit, isMedicalBenefit },
  });
}

async function getAvailable() {
  return api.get<Array<BenefitResume>>('/benefits/available');
}

async function getBenefitOptions(id: number) {
  return api.get<Array<BenefitOptions>>(`/benefits/options/${id}`);
}

async function getDynamicBenefits() {
  return api.get<Array<BasketBenefit>>('/benefits/dynamic');
}

async function getEmployeeBenefits() {
  return api.get<Array<EmployeeFixedBenefit>>('/benefits/fixed');
}

async function createDentalBenefit(request: CreateBenefitRequest) {
  return api.post('/operations/dental-create', request);
}

async function cancelDentalBenefit(request: CancelBenefitRequest) {
  return api.post('/operations/dental-cancel', request);
}

async function manageDentalDependantBenefit(
  request: ManageDependantBenefitRequest
) {
  return api.post('/operations/dental-dependants', request);
}

async function manageMedicalDependantBenefit(
  request: ManageDependantBenefitRequest
) {
  return api.post('/operations/medical-dependants', request);
}

async function upgradePlanDentalBenefit(request: UpgradePlanBenefitRequest) {
  return api.post('/operations/dental-upgrade', request);
}

async function upgradePlanMedicalBenefit(request: UpgradePlanBenefitRequest) {
  return api.post('/operations/medical-upgrade', request);
}

async function redistribuitonDelay() {
  return api.get('/benefits/redistribution-delay');
}

async function redistributeBenefitBasket(
  request: BenefitsRedistribution[],
  isUpdate?: boolean
) {
  return api.put('benefits/redistribution', request, { params: { isUpdate } });
}

async function upgradeAvailable() {
  return api.get<Array<AllowedUpgradeBenefit>>('/benefits/upgrade-period');
}

const BenefitService = {
  getAmounts,
  getAvailable,
  getAdditional,
  getBenefitsResume,
  getBenefitOptions,
  getDynamicBenefits,
  getEmployeeBenefits,
  createDentalBenefit,
  cancelDentalBenefit,
  manageDentalDependantBenefit,
  manageMedicalDependantBenefit,
  redistribuitonDelay,
  redistributeBenefitBasket,
  upgradeAvailable,
  upgradePlanDentalBenefit,
  upgradePlanMedicalBenefit,
};

export default BenefitService;
