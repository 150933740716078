/* eslint-disable no-continue */
const UploadFileUtils = {
  isValidFormat(filename: string, validExtensions: string[]) {
    let isValid = false;
    for (let i = 0; i < validExtensions.length; i += 1) {
      if (!filename.toLocaleLowerCase().endsWith(validExtensions[i])) continue;
      isValid = true;
      break;
    }
    return isValid;
  },
  getFilename(name: string): string {
    return name.split('/').pop() || '';
  },
};

export default UploadFileUtils;
