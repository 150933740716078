import React, { useEffect } from 'react';
import { BenefitChooseStep, steps } from 'types/common/BenefitChooseStep';
import './styles.scss';

type Props = {
  active: BenefitChooseStep;
};

function BenefitChooseSteps({ active }: Props) {
  const isActive = (idx: number) =>
    idx <= steps.findIndex((s: any) => s.name === active.name);

  useEffect(() => {}, []);

  return (
    <div className="steps-container" data-testid="steps-container">
      <ul className="steps">
        {steps.map((step: any, idx: number) => (
          <li
            key={step.name}
            className={`${isActive(idx) ? 'active' : ''}`}
            style={{ width: `${100 / steps.length}%` }}
          >
            <span className="step"> </span>
            <span className="title">{step.title}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default BenefitChooseSteps;
