import React, { useEffect } from 'react';
import { AddDependantStep, steps } from 'types/common/AddDependantStep';
import './styles.scss';

type AddDependantStepsProps = {
  active: AddDependantStep;
};

function AddDependantSteps({ active }: AddDependantStepsProps) {
  const isActive = (idx: number) =>
    idx <= steps.findIndex((s: any) => s.name === active.name);

  useEffect(() => {}, []);

  return (
    <div className="steps-container">
      <ul className="steps">
        {steps.map((step: any, idx: number) => (
          <li
            key={step.name}
            className={`${isActive(idx) ? 'active' : ''}`}
            style={{ width: `${100 / steps.length}%` }}
          >
            <span className="step"> </span>
            <span className="title">{step.title}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default AddDependantSteps;
