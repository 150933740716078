import React, { useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import AuthContext from 'configs/context';
import ProfileService from 'services/ProfileService';
import { resume } from 'store/user/actions';

export default function EmployeeLoader() {
  const { dispatch } = useContext(AuthContext);

  useEffect(() => {
    ProfileService.getProfileResume().then((res) => {
      dispatch(resume(res.data));
    });
  }, []);

  return <Outlet />;
}
