import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import AppModal from 'components/AppModal';
import Icon, { IconTypes } from 'components/Icon';
import './styles.scss';

type modalProps = {
  showLeftButton?: boolean;
  open: boolean;
  data?: {
    leftButtonText?: string;
    rightButtonText?: string;
  };
  close: () => void;
  handleConfirm: () => void;
};

function BenefitGracePeriodModal({
  showLeftButton,
  open,
  close,
  data,
  handleConfirm,
}: modalProps) {
  const { t } = useTranslation('dependant');

  return (
    <AppModal
      open={open}
      leftButtonText={data?.leftButtonText}
      rightButtonText={data?.rightButtonText}
      showFooter
      showLeftButton={showLeftButton}
      confirm={() => {
        handleConfirm();
      }}
      close={() => {
        close();
      }}
    >
      <div className="modal-with-confirmation">
        <Row className="icon">
          <Col>
            <Icon name={'LogoAmilIcon' as IconTypes} />
          </Col>
        </Row>
        <Row className="text">
          <Col>
            <h2 className="title pe-0">
              <Trans
                t={t}
                i18nKey="modal.info.title"
                components={{ bold: <span className="bold" /> }}
              />
            </h2>
            <h4 className="description pe-0 mt-0">
              <Trans
                t={t}
                i18nKey="modal.info.description"
                components={{ bold: <span className="bold" /> }}
              />
            </h4>
          </Col>
        </Row>
      </div>
    </AppModal>
  );
}

export default BenefitGracePeriodModal;
