import React from 'react';
import { Row } from 'react-bootstrap';
import './styles.scss';

export type NewDependantProps = {
  data: {
    text: string;
    color: string;
    backgroundColor: string;
  };
};

function NewDependant({ data }: NewDependantProps) {
  return (
    <Row
      className="new-dependant"
      style={{ backgroundColor: data.backgroundColor }}
    >
      <div className="text" style={{ color: data.color }}>
        {data.text}
      </div>
    </Row>
  );
}

export default NewDependant;
