import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AppButton from 'components/AppButton';
import Icon from 'components/Icon';
import BenefitChoosePlans from 'components/Benefits/BenefitChoose/BenefitChoosePlans';
import BenefitChooseSteps from 'components/Benefits/BenefitChoose/BenefitChooseSteps';
import ManageDependantStep from 'components/Shared/ManageDependantStep';
import { steps } from 'types/common/BenefitChooseStep';
import BenefitService from 'services/BenefitService';
import {
  BasketBenefit,
  BenefitAmounts,
  BenefitOptions,
  DependantBenefitOption,
  UpgradePlanBenefitRequest,
} from 'types/models/Benefit';
import AuthContext from 'configs/context';
import ConfirmationBackModal from 'components/Shared/ConfirmationBackModal';
import DependantService from 'services/DependantService';
import ObsLabel from 'components/Shared/ObsLabel';
import BenefitChooseLoader from 'components/Benefits/BenefitChoose/BenefitChooseLoader';
import { useContractType } from 'hooks/useContractType';
import { AvailableBenefitsEnum } from 'components/MyBenefits/AvailableBenefits/AvailableBenefitsEnum';
import RedistributeModal from 'components/Shared/Redistribute/RedistributeModal';
import ModalWithLoadingTrans from 'components/Shared/ModalWithLoadingTrans';
import { pendingOperations } from 'store/user/actions';
import 'styles/base/page-header.scss';
import './styles.scss';

export default function BenefitPlanUpgrade() {
  const {
    dispatch,
    state: { benefitToManage },
  } = useContext(AuthContext);

  const navigate = useNavigate();
  const { t } = useTranslation('benefit');
  const [stepIndex, setStepIndex] = useState(0);
  const step = steps[stepIndex];

  const isMedical =
    benefitToManage.benefit.denominationBenefit ===
    AvailableBenefitsEnum.healthAssistance;

  const currentPlan = benefitToManage.benefit.idBenefitOption;

  const contractType = useContractType();
  const [benefitLoading, setBenefitLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmationModalLoading, setConfirmationModalLoading] =
    useState(false);
  const [hideButton, setHideButton] = useState(false);
  const [openBackModal, setOpenBackModal] = useState(false);
  const [openTermsModal, setOpenTermsModal] = useState(false);
  const [included, setIncluded] = useState<DependantBenefitOption[]>([]);
  const [available, setAvailable] = useState<DependantBenefitOption[]>([]);
  const [dependants, setDependants] = useState<DependantBenefitOption[]>([]);
  const [benefitsOptions, setBenefitsOptions] = useState<BenefitOptions[]>([]);
  const [openInternshipWarningModal, setOpenInternshipWarningModal] =
    useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openRedistributeModal, setOpenRedistributeModal] = useState(false);
  const [dataConfirmationModal, setDataConfirmationModal] = useState({
    type: '',
    icon: '',
    title: '',
    description: '',
    rightButtonText: '',
  });
  const [
    openInternshipWarningChoiceModal,
    setOpenInternshipWarningChoiceModal,
  ] = useState(false);
  const [basketRedistribution, setBasketRedistribution] = useState<
    Array<BasketBenefit>
  >([]);
  const [backStep, setBackstep] = useState(false);
  const [benefitOption, setBenefitOption] = useState<BenefitOptions>({
    id: benefitToManage.benefit.idBenefitOption,
    name: benefitToManage.benefit.option,
    value: benefitToManage.benefit.valueEmployee,
    valueDependant: benefitToManage.benefit.valueByDependant,
    coverage: [
      {
        name: '',
        color: '',
      },
    ],
  });

  const [totalAmount, setTotalAmount] = useState({
    total: 0,
    used: 0,
  });

  const originalIncluded = useMemo(
    () => dependants.filter((d) => d.includedPlan),
    [dependants]
  );

  const originalAvailable = useMemo(
    () => dependants.filter((d) => !d.includedPlan),
    [dependants]
  );

  const amount: BenefitAmounts = {
    total: totalAmount.total,
    used: totalAmountWithPlanValue().used,
  };

  const warningDataModal = {
    rightButtonText: t('modal.intern.warning.close.btn'),
    icon: 'warning',
    title: t('modal.intern.warning.title'),
    description: t('modal.intern.warning.description'),
  };

  const InternshipWarningChoiceData = {
    rightButtonText: t('modal.intern.warning.close.btn'),
    icon: 'warning',
    title: t('modal.intern.warning.choice.title'),
    description: t('modal.intern.warning.choice.description'),
  };

  const header = {
    title: <h2>{t(`choose.form.${step.name}.title`)}</h2>,
    description: (
      <div className="desc">
        <h3>{t(`choose.form.${step.name}.description`)}</h3>
        {step.name === 'plans' && <span>{t('choose.form.required')}</span>}
      </div>
    ),
  };

  const obsLabel = {
    classNameIcon: 'help-blue-icon',
    icon: 'helpCircle',
    title: t('benefit.manage.upgrade.obs.label.title'),
    titleColor: 'var(--color-secondary-variant-base)',
    description: t('benefit.manage.upgrade.obs.label.description'),
    backgroundColor: 'var(--color-secondary-neutral)',
  };

  const getNextButtonLabel = () => {
    if (!step.nextStep) {
      return t('send.solicitation.btn');
    }
    return t('next.button');
  };

  const handleNext = () => {
    const roundedBasketUsedValue = parseFloat(
      totalAmountWithPlanValue().used.toFixed(2)
    );
    const roundedTotalAmount = parseFloat(totalAmount.total.toFixed(2));
    const amountValidate =
      contractType === 'INTERNSHIP' &&
      roundedBasketUsedValue > roundedTotalAmount;
    if (!step.nextStep) {
      amountValidate
        ? setOpenInternshipWarningModal(true)
        : setOpenTermsModal(true);
    } else {
      amountValidate
        ? setOpenInternshipWarningChoiceModal(true)
        : setStepIndex(stepIndex + 1);
    }

    if (loading) {
      setHideButton(true);
    }
  };

  useEffect(() => {
    const planIncluded = dependants.filter((e) => e.includedPlan === true);
    setIncluded(planIncluded);

    const notPlanIncluded = dependants.filter((e) => e.includedPlan === false);
    setAvailable(notPlanIncluded);
  }, [dependants]);

  useEffect(() => {
    if (benefitToManage.benefit.benefitId === 0) {
      navigate('/benefit/');
      return;
    }

    const medicalFlags = [true, false, true];
    const dentalFlags = [true, true, false];

    const flags = isMedical ? medicalFlags : dentalFlags;

    setBenefitLoading(true);

    Promise.all([
      BenefitService.getAmounts(...flags),
      BenefitService.getDynamicBenefits(),
      BenefitService.getBenefitOptions(benefitToManage.benefit.benefitId),
    ])
      .then((results) => {
        const [amountsRes, dynamicBenefitsRes, benefitOptionsRes] = results;
        setTotalAmount({
          total: amountsRes.data.total,
          used: amountsRes.data.used,
        });

        const formattedBenefits = dynamicBenefitsRes.data.map((b) => ({
          ...b,
          value: 0,
        }));
        setBasketRedistribution(formattedBenefits);

        setBenefitsOptions(benefitOptionsRes.data);
      })
      .catch(() => navigate('/benefit/plan/edit'))
      .finally(() => setBenefitLoading(false));
  }, []);

  useEffect(() => {
    if (benefitOption.id !== 0) {
      setLoading(true);
      DependantService.dependantOptions(benefitOption.id)
        .then((res) => {
          setDependants(res.data);
        })
        .finally(() => {
          setHideButton(false);
          setLoading(false);
        });
    }
  }, []);

  const dentalAssistHeaderData = {
    headerTitle: t('benefit.manage.upgrade.dental.title'),
    backButtonTitle: t('benefit.manage.dependant.back.button.dental'),
  };

  const medicalAssistHeaderData = {
    headerTitle: t('benefit.manage.upgrade.medical.title'),
    backButtonTitle: t('benefit.manage.upgrade.medical.header.back'),
  };

  function handleRemoveAll() {
    setIncluded([]);
    setAvailable(dependants);
  }

  function handleIncludeAll() {
    setAvailable([]);
    setIncluded(dependants);
  }

  function restoreDependants() {
    setIncluded(originalIncluded);
    setAvailable(originalAvailable);
  }

  function totalAmountWithPlanValue() {
    const { value, valueDependant } = benefitOption;
    const updatedUsedValue = valueDependant * included.length;

    return {
      total: totalAmount.total,
      used: totalAmount.used + value + updatedUsedValue,
    };
  }

  function confirmTermsAndContinue() {
    setOpenTermsModal(false);
    setOpenRedistributeModal(true);
  }

  const handlePlanChoiceClick = (option: BenefitOptions) => {
    setBenefitOption(option);
  };

  function handleBackAction() {
    if (!benefitLoading) {
      !step.nextStep ? setOpenBackModal(true) : navigate('/benefit/plan/edit');
    }
  }

  function handleStepBackAction() {
    setBackstep(true);
    setOpenBackModal(true);
  }

  const renderStep = () => {
    const formMap: Partial<Record<string, JSX.Element>> = {
      plans: (
        <BenefitChoosePlans
          openWarningModal={openInternshipWarningChoiceModal}
          setOpenWarningModal={setOpenInternshipWarningChoiceModal}
          warningDataModal={InternshipWarningChoiceData}
          benefitOption={benefitOption}
          benefitsOptions={benefitsOptions}
          handlePlanChoiceClick={handlePlanChoiceClick}
          progressAmounts={totalAmountWithPlanValue()}
          header={header}
          lockId={currentPlan}
        />
      ),
      dependants: (
        <ManageDependantStep
          obsLabel={
            <div className="obs-label">
              <ObsLabel data={obsLabel} />
            </div>
          }
          loading={loading}
          progressAmounts={totalAmountWithPlanValue()}
          included={included}
          available={available}
          setIncluded={setIncluded}
          setAvailable={setAvailable}
          handleRemoveAll={handleRemoveAll}
          handleIncludeAll={handleIncludeAll}
          benefitOption={benefitOption}
          setOpenTermsModal={setOpenTermsModal}
          openTermsModal={openTermsModal}
          openWarningModal={openInternshipWarningModal}
          setOpenWarningModal={setOpenInternshipWarningModal}
          warningDataModal={warningDataModal}
          confirmTermsAndContinue={confirmTermsAndContinue}
          header={header}
          termsModalType="MANAGE"
        />
      ),
    };
    return formMap[step.name];
  };

  function confirmRedistributeModal(confirmedBenefits: Array<BasketBenefit>) {
    const upgradePlanBenefitRequest: UpgradePlanBenefitRequest =
      buildBenefitRequest(confirmedBenefits);
    setOpenConfirmationModal(true);
    setOpenRedistributeModal(false);
    setDataConfirmationModal({
      ...dataConfirmationModal,
      title: t('modal.loading.title'),
      description: t('modal.loading.description'),
    });
    upgradePlanBenefit(upgradePlanBenefitRequest);
  }

  function buildBenefitRequest(confirmedBenefits: Array<BasketBenefit>) {
    const addedDependantsIdList: number[] = [];
    const removedDependantsIdList: number[] = [];

    included.forEach((d) => {
      addedDependantsIdList.push(d.idDependant);
    });
    available.forEach((d) => {
      if (d.includedPlan) removedDependantsIdList.push(d.idDependant);
    });

    const upgradePlanBenefitRequest: UpgradePlanBenefitRequest = {
      idBenefit: benefitToManage.benefit.benefitId,
      idBenefitOption: benefitOption.id,
      addedDependentsIds: addedDependantsIdList,
      removedDependentsIds: removedDependantsIdList,
      benefitRedistribution: [],
    };

    confirmedBenefits.forEach((benefit) => {
      upgradePlanBenefitRequest.benefitRedistribution.push({
        idBenefit: benefit.id,
        value: benefit.value,
      });
    });

    return upgradePlanBenefitRequest;
  }

  async function upgradePlanBenefit(
    upgradePlanBenefitRequest: UpgradePlanBenefitRequest
  ) {
    const serviceFn = isMedical
      ? BenefitService.upgradePlanMedicalBenefit
      : BenefitService.upgradePlanDentalBenefit;

    setConfirmationModalLoading(true);
    await serviceFn(upgradePlanBenefitRequest)
      .then(() => {
        setDataConfirmationModal({
          type: 'success',
          icon: 'sentSuccess',
          title: isMedical
            ? t('benefit.manage.upgrade.medical.modal.success.title')
            : t('benefit.manage.upgrade.dental.modal.success.title'),
          description: t(
            'benefit.manage.upgrade.medical.modal.success.description'
          ),
          rightButtonText: isMedical
            ? t('benefit.manage.upgrade.medical.modal.success.confirm.btn')
            : t('benefit.manage.upgrade.dental.modal.success.confirm.btn'),
        });
        dispatch(pendingOperations(true));
      })
      .catch(() => {
        setDataConfirmationModal({
          type: 'error',
          icon: 'errorIcon',
          title: t('modal.error.title'),
          description: t('modal.error.description'),
          rightButtonText: t('modal.info.back.button'),
        });
      })
      .finally(() => {
        setConfirmationModalLoading(false);
      });
  }

  function setBenefitData() {
    let benefitData = {
      headerTitle: '',
      backButtonTitle: '',
    };
    benefitData = dentalAssistHeaderData;
    if (isMedical) {
      benefitData = medicalAssistHeaderData;
    }
    return benefitData;
  }

  return (
    <div className="plan-upgrade-container main-padding">
      <Row>
        <Col>
          <div className="page-header">
            <Row className="navigation">
              <Col
                title={t('back.button')}
                className={
                  benefitLoading
                    ? `navigation-link disabled`
                    : `navigation-link`
                }
                onClick={() => handleBackAction()}
              >
                <Icon name="chevronLeftGray" />
                <span>{setBenefitData().backButtonTitle}</span>
              </Col>
            </Row>
            <hr />
            <Row className="title">
              <h1>{setBenefitData().headerTitle}</h1>
              <div className="desc">
                <p>{t('benefit.manage.upgrade.medical.description')}</p>
              </div>
            </Row>
            <Row>
              <Col>
                <BenefitChooseSteps active={step} />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <div className="page-body">
        {benefitLoading ? (
          <BenefitChooseLoader header={header} />
        ) : (
          renderStep()
        )}
        {!hideButton && (
          <Row className="buttons">
            <Col>
              {step.name === 'dependants' && (
                <AppButton onClick={handleStepBackAction}>
                  {t('back.button')}
                </AppButton>
              )}
              {!benefitLoading && (
                <AppButton
                  disabled={
                    benefitOption.id === 0 || benefitOption.id === currentPlan
                  }
                  full
                  onClick={() => handleNext()}
                >
                  {getNextButtonLabel()}
                </AppButton>
              )}
            </Col>
          </Row>
        )}

        <Row>
          {!step.nextStep && (
            <ConfirmationBackModal
              open={openBackModal}
              rightButtonText={t('stay.in.page.button')}
              handleClose={() => {
                backStep
                  ? setStepIndex(stepIndex - 1)
                  : navigate('/benefit/plan/edit');
                restoreDependants();
                setBackstep(false);
                setOpenBackModal(false);
              }}
              handleConfirm={() => {
                setBackstep(false);
                setOpenBackModal(false);
              }}
            />
          )}
          <RedistributeModal
            disableConfirm={amount.total > amount.used}
            totalAmount={amount}
            benefits={basketRedistribution}
            open={openRedistributeModal}
            setBenefits={setBasketRedistribution}
            close={() => {
              const formattedBenefits = basketRedistribution.map((b) => ({
                ...b,
                value: 0,
              }));

              setOpenRedistributeModal(false);
              setBasketRedistribution(formattedBenefits);
            }}
            confirm={(confirmedBenefits) =>
              confirmRedistributeModal(confirmedBenefits)
            }
          />
          <ModalWithLoadingTrans
            loading={confirmationModalLoading}
            showFooter={!confirmationModalLoading}
            data={dataConfirmationModal}
            open={openConfirmationModal}
            contextTranslation="benefit"
            close={() => setOpenConfirmationModal(false)}
            handleConfirm={() => {
              if (dataConfirmationModal.type === 'success') {
                navigate('/benefit/plan/edit');
                setOpenConfirmationModal(false);
              } else {
                setOpenRedistributeModal(true);
                setOpenConfirmationModal(false);
              }
            }}
          />
        </Row>
      </div>
    </div>
  );
}
