import React, { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import ConfirmationModal from 'components/Shared/ConfirmationModal';
import ModalWithLoading from 'components/Shared/ModalWithLoading/ModalWithLoading';
import DependantService from 'services/DependantService';
import * as FormModel from '../Dependants/models';
import UploadedFile from './UploadedFile';
import AppUploadInput from './AppUploadInput';
import AppFileDisplay from './AppFileDisplay';

export type formStatus = 'none' | 'valid' | 'error' | 'pending';

type DependantAttachmentsType = 'ID' | 'CERTIFICATE';

type AttachmentResponse = {
  document: string;
  field: string;
};

export type AdmissionFormConfig = {
  isEditing: boolean;
  status: formStatus;
  validationResults: string[];
  modifiedAttachments: string[];
};

export type AppUploadProps = {
  uploadedFile: UploadedFile;
  formMethods: UseFormReturn<FormModel.DependantAttachment>;
  fieldName: string;
  inputSubtitleAppend?: string;
  path: string;
  required?: boolean;
  translation: any;
  extensions?: string[];
  loading: boolean;
  setLoading: (loading: boolean) => void;
  isRemoving: boolean;
  setFilesToRemove: (isRemoving: boolean) => void;
  handleResponse: (response: AttachmentResponse) => void;
  dependantFirstName: string;
};

function AppUpload({
  uploadedFile,
  fieldName,
  path,
  formMethods,
  required = false,
  inputSubtitleAppend = '',
  translation,
  extensions,
  loading,
  setLoading,
  isRemoving,
  setFilesToRemove,
  handleResponse,
  dependantFirstName,
}: AppUploadProps) {
  const [fileName, setFileName] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [displayFile, setDisplayFile] = useState(false);
  const [fileNameToRemove, setFileNameToRemove] = useState('');
  const loadingModalCommonProps = {
    loading: true,
    showFooter: false,
    handleConfirm: () => {},
    close: () => {},
  };

  const uploadFileDataModal = {
    title: translation('uploadFileModal.title'),
    description: translation('uploadFileModal.description'),
  };

  const modalData = {
    rightButtonText: translation('modal.back.button'),
    icon: 'errorIcon',
    title: translation('modal.error.exclude.title'),
    description: translation('modal.error.exclude.description'),
  };

  const showDisplayFile = (name: string) => {
    if (name !== '') {
      setDisplayFile(true);
    } else {
      setDisplayFile(false);
    }
  };

  useEffect(() => {
    const value = formMethods.getValues(fieldName as DependantAttachmentsType);
    setFileName(value !== undefined ? value : '');
    showDisplayFile(value !== undefined ? value : '');
  }, []);

  const handleComplete = (name: string) => {
    setFileName(name);
    showDisplayFile(name);
    enableNextButton(name, false);
  };

  const enableNextButton = (name: string, isBeingRemoved: boolean) => {
    const value = {
      shouldValidate: !isBeingRemoved,
    };
    formMethods.setValue(fieldName as DependantAttachmentsType, name, value);
  };

  const removeFile = (name: string, field: string) => {
    setFilesToRemove(true);
    enableNextButton(name, true);

    const config = { params: { name } };
    DependantService.deleteAttachment({
      config,
      handleThen: () => {
        formMethods.setError(field as DependantAttachmentsType, {});
        setFileName('');
        showDisplayFile('');
        handleResponse({ document: '', field: fieldName });
        formMethods.resetField(field as DependantAttachmentsType);
        formMethods.clearErrors(field as DependantAttachmentsType);
        formMethods.trigger(field as DependantAttachmentsType);
        setFilesToRemove(false);
      },
      handleCatch: () => {
        setFileName(name);
        setOpenModal(true);
        showDisplayFile(name);
        enableNextButton(name, true);
        setFilesToRemove(false);
      },
    });
  };

  return (
    <section className="upload-container">
      {!isRemoving && (
        <span title="app-upload" className="title">
          {translation(`upload.field.${fieldName}${inputSubtitleAppend}`)}
          <span className="required">{required ? '*' : ''}</span>
        </span>
      )}

      {!displayFile && !isRemoving && (
        <AppUploadInput
          setNameToRemove={setFileNameToRemove}
          handleResponse={handleResponse}
          path={path}
          loading={loading}
          setLoading={setLoading}
          uploadedFile={uploadedFile}
          fieldName={fieldName}
          onComplete={handleComplete}
          inputSubtitleAppend={inputSubtitleAppend}
          required={required}
          translation={translation}
          extensions={extensions}
          dependantFirstName={dependantFirstName}
        />
      )}

      <AppFileDisplay
        displayFile={displayFile}
        isRemovingFile={isRemoving}
        fieldName={fieldName}
        onRemove={removeFile}
        translation={translation}
        fileName={fileName}
        fileNameToRemove={fileNameToRemove}
      />

      <ConfirmationModal
        open={openModal}
        data={modalData}
        handleConfirm={() => {
          setOpenModal(false);
        }}
        close={() => {
          setOpenModal(false);
        }}
      />

      <ModalWithLoading
        open={loading}
        data={uploadFileDataModal}
        {...loadingModalCommonProps}
      />
    </section>
  );
}

export default AppUpload;
