import React, { useState, useEffect } from 'react';
import masks from 'utils/MasksUtils';
import { BenefitAmounts } from 'types/models/Benefit';
import { useTranslation } from 'react-i18next';
import benefitService from 'services/BenefitService';
import './styles.scss';

type ProgressBarBenefitsProps = {
  amounts?: BenefitAmounts;
};

function ProgressBarBenefits({ amounts }: ProgressBarBenefitsProps) {
  const { t } = useTranslation('shared');

  const [percentage, setPercentage] = useState('100%');
  const [total, setTotal] = useState(0);
  const [used, setUsed] = useState(0);
  const roundedTotal = parseFloat(total.toFixed(2));
  const roundedUsed = parseFloat(used.toFixed(2));
  const bodyStyles = document.body.style;

  const valueAvailable = roundedTotal - roundedUsed;

  useEffect(() => {
    bodyStyles.setProperty('--progress-bar-percentage', percentage);
  }, [percentage]);

  useEffect(() => {
    if (used <= total) {
      setPercentage(`${(used * 100) / total}%`);
      bodyStyles.setProperty(
        '--progress-bar-color',
        'var(--color-secondary-neutral)'
      );
    } else {
      setPercentage(`${(total * 100) / used}%`);
      bodyStyles.setProperty(
        '--progress-bar-color',
        'var(--color-error-contrast)'
      );
    }
  }, [bodyStyles, total, used]);

  useEffect(() => {
    let isMounted = true;

    if (amounts) {
      setTotal(amounts.total);
      setUsed(amounts.used);
    } else {
      benefitService.getAmounts().then((response) => {
        if (isMounted) {
          setTotal(response.data.total);
          setUsed(response.data.used);
        }
      });
    }

    return () => {
      isMounted = false;
    };
  }, [amounts]);

  return (
    <div className="progress-bar" data-testid="progress-bar">
      <div className="info">
        <div className="titles">
          <h4 className="text">{t('progress.bar.used')}</h4>
          <h4 className="text">
            {roundedUsed > roundedTotal
              ? t('progress.bar.discount')
              : t('progress.bar.available')}
          </h4>
        </div>
        <div className="progress-bar-base">
          <div className="progress-bar-base-secondary" />
        </div>
        <div className="values">
          <h4>{masks.currency(used)}</h4>
          <h4>{masks.currency(valueAvailable)}</h4>
        </div>
      </div>
      <div className="total">
        <h5 className="title">{t('progress.bar.basket')}</h5>
        <h4 className="value">{masks.currency(total)}</h4>
      </div>
    </div>
  );
}

export default ProgressBarBenefits;
