import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import ProfileService from 'services/ProfileService';
import BenefitService from 'services/BenefitService';
import AuthContext from 'configs/context';
import {
  BenefitBasketResume,
  ProfileResponse,
  ProfileResume,
} from 'types/models/Profile';
import DateUtils from 'utils/DateUtils';
import ConfirmationModal from 'components/Shared/ConfirmationModal';
import ModalInformative from 'components/Shared/ModalInformative';
import BenefitAdjustWarningModal from 'components/Shared/BenefitAdjustWarningModal';
import ModalWithLoadingTrans from 'components/Shared/ModalWithLoadingTrans';
import { BasketBenefit, BenefitsRedistribution } from 'types/models/Benefit';
import RedistributeModal from 'components/Shared/Redistribute/RedistributeModal';
import { reloadBenefits } from 'store/user/actions';
import OperationService from 'services/OperationService';
import FieldMyProfile from '../../components/Shared/FieldProfile';
import MyProfileLoader from './MyProfileLoader';
import './styles.scss';

const defaultNotAvailable = 'Não informado';

function MyProfile() {
  const [profile, setProfile] = useState<ProfileResponse>();
  const [openBenefitUpdateModal, setOpenBenefitUpdateModal] = useState(false);
  const [openBenefitAdjustWarningModal, setOpenBenefitAdjustWarningModal] =
    useState(false);
  const [benefitAdjustWarningModalData, setBenefitAdjustWarningModalData] =
    useState<BenefitBasketResume>({
      dentalInsuranceValue: 0,
      medicalInsuranceValue: 0,
      vouchersValue: 0,
      payrollDiscount: 0,
    });
  const [openRedistributeModal, setOpenRedistributeModal] = useState(false);
  const [openLoadingModal, setOpenLoadingModal] = useState(false);
  const [loadingRedistribute, setLoadingRedistribute] = useState(false);
  const [benefitLoading, setBenefitLoading] = useState(false);
  const [profileResume, setProfileResume] = useState<ProfileResume>();
  const [openInternshipWarningModal, setOpenInternshipWarningModal] =
    useState(false);
  const [openPendingOperationModal, setOpenPendingOperationModal] =
    useState(false);
  const [openUpdateProfileOperationModal, setOpenUpdateProfileOperationModal] =
    useState(false);
  const [profileLoading, setProfileLoading] = useState(false);
  const [totalAmount, setTotalAmount] = useState({
    total: 0,
    used: 0,
  });

  const [dataConfirmationModal, setDataConfirmationModal] = useState({
    type: '',
    icon: '',
    title: '',
    description: '',
    rightButtonText: '',
  });
  const [basketRedistribution, setBasketRedistribution] = useState<
    Array<BasketBenefit>
  >([]);
  const navigate = useNavigate();
  const { t } = useTranslation('profile');
  const {
    dispatch,
    state: { pendingOperation },
  } = useContext(AuthContext);

  const benefitUpdateModalData = {
    title: 'benefit.update.info.modal.title',
    description: 'benefit.update.info.modal.description',
    secondDescription: 'benefit.update.info.modal.description.2',
    buttonText: 'benefit.update.info.modal.button',
    date: profile?.firstAccessModal?.modalData.finalDateUpgrade,
  };

  const warningDataModal = {
    rightButtonText: t('profile.modal.warning.close.btn'),
    icon: 'warning',
    title: t('profile.modal.intern.warning.title'),
    description: t('profile.modal.intern.warning.description'),
  };

  const pendingOperationDataModal = {
    rightButtonText: t('profile.modal.warning.close.btn'),
    icon: 'warning',
    title: t('profile.modal.pending.operation.title'),
    description: t('profile.modal.pending.operation.description'),
  };

  useEffect(() => {
    setProfileLoading(true);
    ProfileService.get().then((response) => {
      const flag = response.data.profileUpdateOperation?.flagViewed;

      if (flag !== undefined) {
        setOpenUpdateProfileOperationModal(!flag);
      }

      setProfileLoading(false);
      setProfile(response.data);
      if (response.data.firstAccessModal) {
        if (
          response.data.firstAccessModal.modalType ===
          'BENEFIT_UPGRADE_DOWNGRADE'
        ) {
          setOpenBenefitUpdateModal(true);
        }
        if (
          response.data.firstAccessModal.modalType ===
          'BENEFIT_OPTION_VALUE_CHANGE'
        ) {
          setBenefitAdjustWarningModalData(
            response.data.firstAccessModal.modalData.benefitBasket
          );
          setOpenBenefitAdjustWarningModal(true);
        }
      }
    });
  }, []);

  useEffect(() => {
    ProfileService.getProfileResume().then((res) => {
      setProfileResume(res.data);
    });
  }, []);

  useEffect(() => {
    setBenefitLoading(true);
    Promise.all([
      BenefitService.getAmounts(true),
      BenefitService.getDynamicBenefits(),
    ])
      .then((results) => {
        const [amountRes, dynamicRes] = results;
        setTotalAmount(amountRes.data);
        const formattedBenefits = dynamicRes.data.map((b) => ({
          ...b,
          value: 0,
        }));
        setBasketRedistribution(formattedBenefits);
      })
      .finally(() => setBenefitLoading(false));
  }, []);

  function confirmRedistributeModal(confirmedBenefits: Array<BasketBenefit>) {
    setOpenRedistributeModal(false);
    setOpenLoadingModal(true);
    const basketRedistributionRequest: BenefitsRedistribution[] = [];
    confirmedBenefits.forEach((b) => {
      const benefit: BenefitsRedistribution = {
        idBenefit: b.id,
        value: b.value,
      };
      basketRedistributionRequest.push(benefit);
    });
    redistributeBasket(basketRedistributionRequest);
  }

  async function redistributeBasket(
    basketRedistributionRequest: BenefitsRedistribution[]
  ) {
    const isUpdate = true;
    setLoadingRedistribute(true);
    setDataConfirmationModal({
      ...dataConfirmationModal,
      title: t('profile.redistribute.modal.loading.title'),
      description: t('profile.modal.loading.description'),
    });
    await BenefitService.redistributeBenefitBasket(
      basketRedistributionRequest,
      isUpdate
    )
      .then(() => {
        setLoadingRedistribute(false);
        setDataConfirmationModal({
          type: 'success',
          icon: 'sentSuccess',
          title: t('profile.redistribute.modal.success.title'),
          description: 'redistribute.modal.success.description',
          rightButtonText: t(
            'profile.redistribute.modal.success.confirm.button'
          ),
        });
      })
      .catch(() => {
        setLoadingRedistribute(false);
        setDataConfirmationModal({
          type: 'error',
          icon: 'errorIcon',
          title: t('profile.redistribute.modal.error.title'),
          description: t('profile.redistribute.modal.error.description'),
          rightButtonText: t('profile.modal.info.back.button'),
        });
      });
  }

  function handleBenefitUpdateModalConfirm() {
    setOpenBenefitUpdateModal(false);
    sessionStorage.setItem('first_access_modal_update', 'benefit_update');
    navigate('/benefit');
  }

  function goToMyBenefits() {
    setOpenBenefitAdjustWarningModal(false);
    sessionStorage.setItem('first_access_modal', 'benefit_adjust');
    navigate('/benefit');
  }

  function handleBenefitAdjustConfirm() {
    const roundedTotal = parseFloat(totalAmount.total.toFixed(2));
    const roundedUsed = parseFloat(totalAmount.used.toFixed(2));
    if (
      profileResume?.contractType === 'INTERNSHIP' &&
      roundedTotal < roundedUsed
    ) {
      setOpenInternshipWarningModal(true);
    } else if (pendingOperation) {
      setOpenPendingOperationModal(true);
    } else {
      setOpenBenefitAdjustWarningModal(false);
      setOpenRedistributeModal(true);
    }
  }

  function handleConfirmModal() {
    sessionStorage.setItem('first_access_modal', 'benefit_adjust');
    setOpenPendingOperationModal(false);
    setOpenBenefitAdjustWarningModal(false);
    setOpenInternshipWarningModal(false);
  }

  const title = () => (
    <Trans
      t={t}
      i18nKey="profile.update.modal.title"
      components={{ bold: <span className="bold" /> }}
    />
  );

  const description = () => {
    const profileUpdateData = Object.keys(
      profile?.profileUpdateOperation?.operationData.profileUpdate || {}
    );

    const handleDataText = (data: string) => {
      const dataMap: Record<string, string> = {
        name: t('name'),
        preferredName: t('preferred.name'),
        countryOfBirth: t('country.of.birth'),
        sex: t('sex'),
        race: t('race'),
        civilStatus: t('civil.status'),
        schooling: t('schooling'),
        deficiency: t('deficiency'),
        parentage: t('parentage'),
        secondParentage: t('second.parentage'),
        dateOfBirth: t('date.of.birth'),
      };
      return dataMap[data];
    };

    return (
      <div className="description-update">
        <Trans
          t={t}
          i18nKey="profile.update.modal.description"
          components={{ bold: <span className="bold" /> }}
        />
        <hr className="separator" />
        <div className="texts">
          <h3 className="text">{t('edited.data')}</h3>
          <div className="box">
            {profileUpdateData.map((data) => (
              <span className="data">{handleDataText(data)}</span>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const CloseUpdateProfileModal = () => {
    const id = profile?.profileUpdateOperation?.id;

    try {
      if (id !== undefined) {
        OperationService.updateOperationVisibility(id);
        setOpenUpdateProfileOperationModal(false);
      }
    } catch (error) {
      setOpenUpdateProfileOperationModal(false);
    }
  };

  return (
    <div data-testid="my-profile-container" className="my-profile-container">
      {profileLoading ? (
        <MyProfileLoader />
      ) : (
        <>
          <Row>
            <Col className="title-col">
              <h2>Meu perfil</h2>
              <h4>
                Visualize as suas informações de perfil do colaborador Iteris
              </h4>
            </Col>
          </Row>
          {profile && (
            <div data-testid="container-fields" className="container-fields">
              <FieldMyProfile
                data-testid="field-name"
                firstFieldName="NOME COMPLETO"
                firstValue={profile.employeeInformation.name || ''}
                secondFieldName=""
                secondValue=""
              />
              <FieldMyProfile
                firstFieldName="COMO PODEMOS TE CHAMAR"
                firstValue={
                  profile.employeeInformation.preferredName ||
                  defaultNotAvailable
                }
                secondFieldName="PAÍS DE NASCIMENTO"
                secondValue={
                  profile.employeeInformation.countryOfBirth ||
                  defaultNotAvailable
                }
              />
              <FieldMyProfile
                firstFieldName="DATA DE NASCIMENTO"
                firstValue={
                  DateUtils.format(
                    profile.employeeInformation.dateOfBirth || '',
                    'dd/MM/yyyy'
                  ) || defaultNotAvailable
                }
                secondFieldName="SEXO"
                secondValue={
                  profile.employeeInformation.sex || defaultNotAvailable
                }
              />
              <FieldMyProfile
                firstFieldName="RAÇA/COR"
                firstValue={
                  profile.employeeInformation.race || defaultNotAvailable
                }
                secondFieldName="ESTADO CIVIL"
                secondValue={
                  profile.employeeInformation.civilStatus || defaultNotAvailable
                }
              />
              <FieldMyProfile
                firstFieldName="ESCOLARIDADE"
                firstValue={
                  profile.employeeInformation.schooling || defaultNotAvailable
                }
                secondFieldName="PESSOA COM DEFICIÊNCIA"
                secondValue={
                  profile.employeeInformation.deficiency || defaultNotAvailable
                }
              />
              <FieldMyProfile
                firstFieldName="FILIAÇÃO 1"
                firstValue={
                  profile.employeeInformation.parentage || defaultNotAvailable
                }
                secondFieldName=""
                secondValue=""
              />
              <FieldMyProfile
                firstFieldName="FILIAÇÃO 2"
                firstValue={
                  profile.employeeInformation.secondParentage ||
                  defaultNotAvailable
                }
                secondFieldName=""
                secondValue=""
              />
            </div>
          )}
        </>
      )}
      {!sessionStorage.getItem('first_access_modal_update') &&
        (profileResume?.contractType === 'CLT' ||
          profileResume?.contractType === 'INTERNSHIP') &&
        !profileResume.benefitOptionValueChanged && (
          <ModalInformative
            open={openBenefitUpdateModal}
            close={() => {
              sessionStorage.setItem(
                'first_access_modal_update',
                'benefit_update'
              );
              setOpenBenefitUpdateModal(false);
            }}
            handleConfirm={handleBenefitUpdateModalConfirm}
            contextTranslation="benefit"
            data={benefitUpdateModalData}
          />
        )}
      {!sessionStorage.getItem('first_access_modal') &&
        (profileResume?.contractType === 'CLT' ||
          profileResume?.contractType === 'INTERNSHIP') &&
        !benefitLoading && (
          <BenefitAdjustWarningModal
            totalBasketValue={totalAmount.total}
            benefitBasketResume={benefitAdjustWarningModalData}
            benefitFinalDate={
              profile?.firstAccessModal?.modalData.finalDateUpgrade
            }
            open={openBenefitAdjustWarningModal}
            close={() => goToMyBenefits()}
            handleConfirm={handleBenefitAdjustConfirm}
          />
        )}
      <RedistributeModal
        disableConfirm={totalAmount.total > totalAmount.used}
        totalAmount={totalAmount}
        benefits={basketRedistribution}
        open={openRedistributeModal}
        setBenefits={setBasketRedistribution}
        close={() => {
          const formattedBenefits = basketRedistribution.map((b) => ({
            ...b,
            value: 0,
          }));

          setOpenRedistributeModal(false);
          setBasketRedistribution(formattedBenefits);
        }}
        confirm={(confirmedBenefits) =>
          confirmRedistributeModal(confirmedBenefits)
        }
      />
      <ModalWithLoadingTrans
        loading={loadingRedistribute}
        showFooter={!loadingRedistribute}
        data={dataConfirmationModal}
        open={openLoadingModal}
        contextTranslation="benefit"
        close={() => setOpenLoadingModal(false)}
        handleConfirm={() => {
          if (dataConfirmationModal.type === 'success') {
            setOpenLoadingModal(false);
            dispatch(reloadBenefits(true));
            navigate('/benefit');
          } else {
            setOpenRedistributeModal(true);
            setOpenLoadingModal(false);
          }
        }}
      />
      <ConfirmationModal
        showLeftButton={false}
        data={warningDataModal}
        open={openInternshipWarningModal}
        close={() => setOpenInternshipWarningModal(false)}
        handleConfirm={handleConfirmModal}
      />
      <ConfirmationModal
        showLeftButton={false}
        data={pendingOperationDataModal}
        open={openPendingOperationModal}
        close={() => setOpenPendingOperationModal(false)}
        handleConfirm={handleConfirmModal}
      />
      <ConfirmationModal
        close={() => {}}
        open={openUpdateProfileOperationModal}
        data={{
          icon: 'checkCircleFull',
          title: title(),
          description: description(),
          rightButtonText: 'Fechar',
        }}
        showLeftButton={false}
        handleConfirm={() => {
          CloseUpdateProfileModal();
        }}
      />
    </div>
  );
}

export default MyProfile;
