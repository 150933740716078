import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { DependantProfile } from 'types/models/Dependant';
import './styles.scss';

type FieldProps = {
  firstFieldName: string;
  firstValue: string;
  secondFieldName: string;
  secondValue: string;
  dependant?: DependantProfile;
};

function FieldProfile({
  dependant,
  firstFieldName,
  firstValue,
  secondFieldName,
  secondValue,
}: FieldProps) {
  const { t } = useTranslation('shared');

  const nameUpdatedField = (field: string) =>
    dependant?.editedFields &&
    ((field === 'NOME COMPLETO' && dependant?.editedFields?.name) ||
      (field === 'DECLARADO NO IR' &&
        dependant.editedFields?.includeIR !== undefined));

  return (
    <Row>
      <Col className="profile-info">
        <h4>{firstFieldName}</h4>
        <div />
        <h3>{firstValue}</h3>
        {nameUpdatedField(firstFieldName) && (
          <span data-testid="edited-element" className="edited">
            {t('edited')}
          </span>
        )}
      </Col>
      {secondFieldName === '' ? null : (
        <Col className="profile-info-col">
          <h4>{secondFieldName}</h4>
          <div />
          <h3>{secondValue}</h3>
          {nameUpdatedField(secondFieldName) && (
            <span className="edited">{t('edited')}</span>
          )}
        </Col>
      )}
      <Col
        className={
          secondFieldName === '' ? 'col-separator' : 'col-separator-half'
        }
      >
        <hr />
        {secondFieldName === '' ? null : <hr />}
      </Col>
    </Row>
  );
}

export default FieldProfile;
