import { MenuTabs, TabPermission } from 'constants/tabs';
import { ContractType } from 'types/common/ContractType';
import { isValidContractType } from 'types/validation/ContractType';

export const contractMap: Record<ContractType, TabPermission> = {
  CLT: {
    profile: true,
    dependant: true,
    benefit: true,
    'home-data': false,
    'bank-data': false,
    documents: false,
  },
  PJ: {
    profile: true,
    dependant: false,
    benefit: false,
    'home-data': false,
    'bank-data': false,
    documents: false,
  },
  PARTNER: {
    profile: true,
    dependant: false,
    benefit: false,
    'home-data': false,
    'bank-data': false,
    documents: false,
  },
  INTERNSHIP: {
    profile: true,
    dependant: true,
    benefit: true,
    'home-data': false,
    'bank-data': false,
    documents: false,
  },
  FOREIGN_CLT: {
    profile: true,
    dependant: true,
    benefit: true,
    'home-data': false,
    'bank-data': false,
    documents: false,
  },
};

export function filterTabsByContractType(
  defaultTabs: MenuTabs,
  contract: string
) {
  if (!isValidContractType(contract)) return [];

  return defaultTabs.map((t) => ({
    ...t,
    isDisabled: !contractMap[contract][t.eventKey],
  }));
}
