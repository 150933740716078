import React, { useContext, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AppButton from 'components/AppButton';
import Icon from 'components/Icon';
import Checkbox from 'components/Shared/Checkbox';
import RedistributeModal from 'components/Shared/Redistribute/RedistributeModal';
import BenefitService from 'services/BenefitService';
import { BasketBenefit, CancelBenefitRequest } from 'types/models/Benefit';
import ModalWithLoadingTrans from 'components/Shared/ModalWithLoadingTrans';
import AuthContext from 'configs/context';
import 'styles/base/page-header.scss';
import './styles.scss';

function BenefitCancel() {
  const navigate = useNavigate();
  const { t } = useTranslation('benefit');
  const {
    state: { benefitToManage },
  } = useContext(AuthContext);

  const { benefitsToRedistribute, benefit, totalAmount } = benefitToManage;

  const [checked, setChecked] = useState(false);
  const [openRedistributeModal, setOpenRedistributeModal] = useState(false);
  const [basketRedistribution, setBasketRedistribution] = useState<
    Array<BasketBenefit>
  >(benefitsToRedistribute);
  const [confirmationModalLoading, setConfirmationModalLoading] =
    useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [dataConfirmationModal, setDataConfirmationModal] = useState({
    type: '',
    icon: '',
    title: '',
    description: '',
    rightButtonText: '',
  });

  const amounts = () => ({
    used: totalAmount.used - benefit.total,
    total: totalAmount.total,
  });

  const handleOncloseRedistribute = () => {
    const formattedBenefits: BasketBenefit[] = basketRedistribution.map(
      (b) => ({
        ...b,
        value: 0,
      })
    );

    setOpenRedistributeModal(false);
    setBasketRedistribution(formattedBenefits);
  };

  function confirmRedistributeModal(confirmedBenefits: Array<BasketBenefit>) {
    const cancel: CancelBenefitRequest = buildBenefitRequest(confirmedBenefits);
    setOpenConfirmationModal(true);
    setOpenRedistributeModal(false);
    setDataConfirmationModal({
      ...dataConfirmationModal,
      title: t('modal.loading.title'),
      description: t('modal.loading.description'),
    });

    cancelBenefit(cancel);
  }

  function buildBenefitRequest(confirmedBenefits: Array<BasketBenefit>) {
    const cancelDental: CancelBenefitRequest = {
      idBenefit: benefit.benefitId,
      idBenefitOption: benefit.idBenefitOption,
      benefitsRedistribution: [],
    };

    confirmedBenefits.forEach((b) => {
      cancelDental.benefitsRedistribution.push({
        idBenefit: b.id,
        value: b.value,
      });
    });

    return cancelDental;
  }

  async function cancelBenefit(cancelBenefitRequest: CancelBenefitRequest) {
    setConfirmationModalLoading(true);
    await BenefitService.cancelDentalBenefit(cancelBenefitRequest)
      .then(() => {
        setDataConfirmationModal({
          type: 'success',
          icon: 'sentSuccess',
          title: t('modal.success.cancel.title'),
          description: t('modal.success.cancel.description'),
          rightButtonText: t('modal.success.confirm.button'),
        });
      })
      .catch(() => {
        setDataConfirmationModal({
          type: 'error',
          icon: 'errorIcon',
          title: t('modal.error.title'),
          description: t('modal.error.description'),
          rightButtonText: t('modal.info.back.button'),
        });
      })
      .finally(() => {
        setConfirmationModalLoading(false);
      });
  }

  return (
    <div className="benefit-cancel-container main-padding">
      <div className="page-header">
        <Row className="navigation">
          <Col
            className="navigation-link"
            onClick={() => navigate('/benefit/plan/edit')}
          >
            <Icon name="chevronLeft" />
            <span>{t('cancel.back')}</span>
          </Col>
        </Row>
        <hr />
        <div className="title">
          <h1>{t('cancel.header.title')}</h1>
        </div>
      </div>
      <div className="page-body">
        <Row>
          <Col>
            <h4 className="title">{t('benefit.cancel.terms')}</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="terms">
              <b className="position">1</b>
              {t('benefit.cancel.terms.first')}
            </p>
            <p className="terms">
              <b className="position">2</b>
              {t('benefit.cancel.terms.second')}
            </p>
            <p className="terms">
              <b className="position">3</b>
              {t('benefit.cancel.terms.third')}
            </p>
            <p className="terms">
              <b className="position">4</b>
              {t('benefit.cancel.terms.fourth')}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="disclaimer">
              <Checkbox checkedValue={checked} setCheckedValue={setChecked} />
              <span className="text">{t('benefit.cancel.checbock.text')}</span>
            </div>
          </Col>
        </Row>

        <Row className="buttons">
          <Col>
            <AppButton
              disabled={!checked}
              full
              onClick={() => setOpenRedistributeModal(true)}
            >
              {t('benefit.cancel.confirm.button')}
            </AppButton>
          </Col>
        </Row>
      </div>

      <RedistributeModal
        totalAmount={amounts()}
        benefits={basketRedistribution}
        open={openRedistributeModal}
        setBenefits={setBasketRedistribution}
        close={() => handleOncloseRedistribute()}
        disableConfirm={amounts().total > amounts().used}
        confirm={(confirmedBenefits) =>
          confirmRedistributeModal(confirmedBenefits)
        }
      />

      <ModalWithLoadingTrans
        loading={confirmationModalLoading}
        showFooter={!confirmationModalLoading}
        data={dataConfirmationModal}
        open={openConfirmationModal}
        contextTranslation="benefit"
        close={() => setOpenConfirmationModal(false)}
        handleConfirm={() => {
          if (dataConfirmationModal.type === 'success') {
            navigate('/benefit');
            setOpenConfirmationModal(false);
          } else {
            setOpenRedistributeModal(true);
            setOpenConfirmationModal(false);
          }
        }}
      />
    </div>
  );
}

export default BenefitCancel;
