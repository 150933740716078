import * as yup from 'yup';
import { VALIDATION_MESSAGE } from 'constants/formValidation';
import {
  DependantAttachment,
  DependantEditProfile,
  DependantProfile,
} from './models';

export const FORM_SCHEMA_PROFILE: yup.SchemaOf<DependantProfile> = yup
  .object()
  .shape({
    idDependant: yup.number(),
    name: yup
      .string()
      .required(VALIDATION_MESSAGE.REQUIRED)
      .max(50, VALIDATION_MESSAGE.MAX_LENGTH(50)),
    cpf: yup
      .string()
      .required(VALIDATION_MESSAGE.REQUIRED)
      .length(14, VALIDATION_MESSAGE.LENGTH(11)),
    birthdate: yup
      .string()
      .required(VALIDATION_MESSAGE.REQUIRED)
      .length(10, VALIDATION_MESSAGE.MAX_LENGTH(8)),
    kinship: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
    includeIR: yup.boolean().required(VALIDATION_MESSAGE.REQUIRED),
  });

export const FORM_SCHEMA_DEPENDANT_EDIT: yup.SchemaOf<DependantEditProfile> =
  yup.object().shape({
    dependantId: yup.number().required(),
    name: yup
      .string()
      .required(VALIDATION_MESSAGE.REQUIRED)
      .max(50, VALIDATION_MESSAGE.MAX_LENGTH(50)),
    includeIR: yup.boolean().required(VALIDATION_MESSAGE.REQUIRED),
  });

export const FORM_SCHEMA_ATTACHMENT: yup.SchemaOf<DependantAttachment> = yup
  .object()
  .shape({
    ID: yup.string(),
    CERTIFICATE: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
  });
