export type BenefitChooseStep = {
  name: string;
  title: string;
  previousStep: string;
  nextStep: string;
  required: boolean;
};

const steps: Array<BenefitChooseStep> = [
  {
    name: 'plans',
    title: 'Escolher o plano',
    previousStep: '',
    nextStep: 'dependants',
    required: true,
  },
  {
    name: 'dependants',
    title: 'Adicionar dependentes',
    previousStep: 'plans',
    nextStep: '',
    required: true,
  },
];

export { steps };
