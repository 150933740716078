import React, { useContext, useEffect, useState } from 'react';
import AuthContext from 'configs/context';
import {
  AllowedUpgradeBenefit,
  BasketBenefit,
  BenefitAmounts,
  BenefitOptions,
  DependantBenefitOption,
} from 'types/models/Benefit';
import BenefitService from 'services/BenefitService';
import DependantService from 'services/DependantService';
import BenefitEditHeader from 'components/Benefits/BenefitPlanEdit/BenefitEditHeader';
import ManageBenefit from 'components/Benefits/BenefitPlanEdit/BenefitManage';
import BenefitInformation from 'components/Benefits/BenefitPlanEdit/BenefitInformation';
import { AvailableBenefitsEnum } from 'components/MyBenefits/AvailableBenefits/AvailableBenefitsEnum';
import { useContractType } from 'hooks/useContractType';
import BenefitPlanEditLoader from './BenefitPlanEditLoader';

function BenefitEdit() {
  const [benefitLoading, setBenefitLoading] = useState(true);
  const [dependantLoading, setDependantLoading] = useState(true);
  const [benefitOptions, setBenefitOptions] = useState<BenefitOptions[]>();
  const [dependants, setDependants] = useState<DependantBenefitOption[]>([]);
  const [totalAmount, setTotalAmount] = useState<BenefitAmounts>({
    total: 0,
    used: 0,
  });
  const [basketRedistribution, setBasketRedistribution] = useState<
    BasketBenefit[]
  >([]);
  const [upgradeAllowed, setUpgradeAllowed] = useState<
    Array<AllowedUpgradeBenefit>
  >([]);
  const {
    state: { benefitToManage },
  } = useContext(AuthContext);
  const contractType = useContractType();

  useEffect(() => {
    setBenefitLoading(true);
    const disconsiderAleloAssist = true;
    Promise.all([
      BenefitService.getBenefitOptions(benefitToManage.benefit.benefitId),
      BenefitService.getDynamicBenefits(),
      BenefitService.getAmounts(disconsiderAleloAssist),
      BenefitService.upgradeAvailable(),
    ]).then((results) => {
      const [benefitOptionsRes, dynamicRes, amountsRes, upgradeAllowedRes] =
        results;
      filterBenefits(benefitOptionsRes.data);
      const formattedBenefits = dynamicRes.data.map((b) => ({
        ...b,
        value: 0,
      }));
      setBasketRedistribution(formattedBenefits);
      setTotalAmount(amountsRes.data);
      setUpgradeAllowed(upgradeAllowedRes.data);
      setBenefitLoading(false);
    });
  }, []);

  useEffect(() => {
    setDependantLoading(true);
    DependantService.dependantOptions(benefitToManage.benefit.idBenefitOption)
      .then((res) => {
        setDependants(res.data);
      })
      .finally(() => {
        setDependantLoading(false);
      });
  }, [benefitToManage.benefit.idBenefitOption]);

  function filterBenefits(benefitsInfo: BenefitOptions[]) {
    setBenefitOptions(
      benefitsInfo.filter(
        (benefit) => benefit.name === benefitToManage.benefit.option
      )
    );
  }

  return (
    <div className="edit-benefit-container main-padding">
      {dependantLoading || benefitLoading ? (
        <BenefitPlanEditLoader />
      ) : (
        <>
          <BenefitEditHeader
            denominationBenefit={benefitToManage.benefit.denominationBenefit}
          />
          <ManageBenefit
            numberOfDependants={dependants.length}
            hideUpgrade={
              contractType === 'INTERNSHIP' &&
              benefitToManage.benefit.denominationBenefit ===
                AvailableBenefitsEnum.healthAssistance
            }
            basketRedistribution={basketRedistribution}
            totalAmount={totalAmount}
            upgradeAllowed={upgradeAllowed}
          />
          <BenefitInformation
            benefit={benefitToManage.benefit}
            benefitOptions={benefitOptions}
          />
        </>
      )}
    </div>
  );
}

export default BenefitEdit;
