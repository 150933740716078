import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Conditional from 'components/AppModal/Conditional';
import ProgressBarBenefits from 'components/MyBenefits/ProgressBar';
import ConfirmationModal from 'components/Shared/ConfirmationModal';
import { BenefitAmounts, EmployeeFixedBenefit } from 'types/models/Benefit';
import { useContractType } from 'hooks/useContractType';
import DependantBenefit from './DependantBenefit';
import { DependantProfile } from '../models';
import './styles.scss';

type DependantBenefitsProps = {
  totalAmount: BenefitAmounts;
  benefits: Array<EmployeeFixedBenefit>;
  dependant: DependantProfile | undefined;
  setBenefits: (benefits: EmployeeFixedBenefit[]) => void;
  dependantBenefitsValidation: (validation: boolean) => void;
};

function DependantBenefits({
  benefits,
  dependant,
  totalAmount,
  setBenefits,
  dependantBenefitsValidation,
}: DependantBenefitsProps) {
  const { t } = useTranslation('dependant');
  const contractType = useContractType();
  const [openModal, setOpenModal] = useState(false);

  const modalData = {
    rightButtonText: t('modal.close.button'),
    icon: 'warning',
    title: t('modal.close.title'),
    description: t('modal.close.description'),
  };

  const selectBenefit = (benefit: EmployeeFixedBenefit) => {
    if (!benefit.valueDependant) return;

    const current = totalAmount.used;
    if (
      benefit.valueDependant + current > totalAmount.total &&
      contractType === 'INTERNSHIP'
    ) {
      setOpenModal(true);
      return;
    }

    const idx = benefits.findIndex((b) => b.id === benefit.id);
    const updatedBenefits = [...benefits];
    updatedBenefits[idx] = {
      ...benefit,
      selected: true,
    };
    setBenefits(updatedBenefits);
    validateSelected(updatedBenefits);
  };

  const removeBenefit = (benefit: EmployeeFixedBenefit) => {
    if (!benefit.valueDependant) return;

    const idx = benefits.findIndex((b) => b.id === benefit.id);
    const updatedBenefits = [...benefits];

    updatedBenefits[idx] = {
      ...benefit,
      selected: false,
    };

    setBenefits(updatedBenefits);
    validateSelected(updatedBenefits);
  };

  const validateSelected = (employeeBenefits: EmployeeFixedBenefit[]) => {
    let valid = true;
    Object.values(employeeBenefits).forEach((b) => {
      if (b.selected === undefined) valid = false;
    });
    dependantBenefitsValidation(valid);
  };

  return (
    <Conditional if={dependant}>
      <div className="dependant-benefits-container">
        <ProgressBarBenefits amounts={totalAmount} />
        {benefits &&
          benefits.map((benefit: EmployeeFixedBenefit, idx) => (
            <div key={benefit.id}>
              <DependantBenefit
                idx={idx}
                benefit={benefit}
                dependant={dependant}
                selectBenefit={selectBenefit}
                removeBenefit={removeBenefit}
              />
              {idx < benefits.length - 1 && <hr />}
            </div>
          ))}
      </div>
      <ConfirmationModal
        open={openModal}
        data={modalData}
        handleConfirm={() => {
          setOpenModal(false);
        }}
        close={() => {
          setOpenModal(false);
        }}
      />
    </Conditional>
  );
}

export default DependantBenefits;
