import { format, utcToZonedTime } from 'date-fns-tz';
import { ptBR } from 'date-fns/locale';

const DateUtils = {
  format(date: string, fmt: string) {
    return format(utcToZonedTime(date, 'UTC'), fmt, {
      timeZone: 'UTC',
      locale: ptBR,
    });
  },
};

export default DateUtils;
