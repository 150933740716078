import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import AppSkeleton from 'components/AppSkeleton';
import ProgressBarLoading from 'components/MyBenefits/ProgressBar/ProgressBarLoading';
import ActiveBenefitLoader from 'components/MyBenefits/ActiveBenefit/ActiveBenefitLoader';
import BasketBenefitsLoader from 'components/MyBenefits/BasketBenefits/BasketBenefitsLoader';
import AdditionalBenefitsLoader from 'components/MyBenefits/AdditionalBenefits/AdditionalBenefitsLoader';
import AvailableBenefitsLoader from 'components/MyBenefits/AvailableBenefits/AvailableBenefitsLoader';
import './styles.scss';

function MyBenefitsLoader() {
  const additionalBenefitKeys = Array.from(
    { length: 9 },
    (_, index) => `additional-benefit-${index}`
  );

  return (
    <>
      <Container
        fluid
        className="my-benefits-skeleton"
        data-testid="benefits-skeleton"
      >
        <Row>
          <Col className="header">
            <AppSkeleton style={{ width: '300px', height: '32px' }} />
            <AppSkeleton
              style={{ width: '300px', height: '16px', marginTop: '8px' }}
            />
          </Col>
          <div className="progress-cont">
            <ProgressBarLoading />
          </div>
          <Col className="benefits-type">
            <AppSkeleton style={{ width: '215px', height: '24px' }} />
            <ActiveBenefitLoader />
            <BasketBenefitsLoader />
          </Col>
        </Row>
        <AppSkeleton
          style={{ width: '200px', height: '24px', marginBottom: '24px' }}
        />
        <div className="available-cont" style={{ marginBottom: '18px' }}>
          <AvailableBenefitsLoader />
        </div>
      </Container>
      <Container fluid className="additional-container">
        <Col className="header-additional">
          <AppSkeleton style={{ width: '300px', height: '32px' }} />
          <AppSkeleton
            style={{ width: '300px', height: '16px', marginTop: '8px' }}
          />
        </Col>

        <Row>
          {additionalBenefitKeys.map((key) => (
            <Col
              sm={4}
              data-testid="additional-benefits-loader"
              style={{ marginBottom: '18px' }}
              data-key={key}
              key={key}
            >
              <AdditionalBenefitsLoader />
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
}

export default MyBenefitsLoader;
