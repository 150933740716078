import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Row, Col } from 'react-bootstrap';
import Icon from 'components/Icon';
import masks from 'utils/MasksUtils';
import { BenefitOptions, BenefitResumeWithId } from 'types/models/Benefit';
import './styles.scss';

type BenefitInformationProps = {
  benefitOptions?: Array<BenefitOptions>;
  benefit: BenefitResumeWithId;
};

function BenefitInformation({
  benefitOptions,
  benefit,
}: BenefitInformationProps) {
  const { t } = useTranslation('benefit');

  return (
    <div className="benefit-information-container">
      <h1 className="header">{t('benefit.manage.info')}</h1>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Icon name="crossIcon" />
              <h3 className="title">{benefit.option}</h3>
              <p className="description">{t('benefit.manage.plan')}</p>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <Icon name="paidBlue" />
              <h3 className="title">{masks.currency(benefit.valueEmployee)}</h3>
              <p className="description">{t('cost.benefit')}</p>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <Icon name="personIconBlue" />
              <h3 className="title">
                {masks.currency(benefit.valueByDependant)}
              </h3>
              <p className="description">{t('dental.dependant.cost')}</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <h2 className="types">{t('benefit.manage.offer')}</h2>
      <div className="coverage">
        {benefitOptions?.map((list: BenefitOptions) => (
          <div key={list.id} className="column">
            {list.coverage.map((item) => (
              <div key={item.name} className="item">
                <Icon name="checkFlag" />
                <span className="text">{item.name}</span>
              </div>
            ))}
          </div>
        ))}
      </div>
      <h2 className="types">{t('benefit.manage.cost.details')}</h2>
      <div className="cost-details">
        <div className="line">
          <span className="cost">{t('benefit.employee.cost')}</span>
          <span className="value">
            {masks.currency(benefit.valueEmployee || 0)}
          </span>
        </div>
        <div className="line">
          <span className="cost">
            {t('benefit.manage.dependant.cost')} ({benefit.numberDependants})
          </span>
          <span className="value">
            {masks.currency(benefit.amountDependants || 0)}
          </span>
        </div>
        <div className="line total">
          <span className="cost">{t('total')}</span>
          <span className="value">{masks.currency(benefit.total)}</span>
        </div>
      </div>
    </div>
  );
}

export default BenefitInformation;
