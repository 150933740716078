import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'react-bootstrap';
import Icon from 'components/Icon';
import { BenefitAmounts, BenefitOptions } from 'types/models/Benefit';
import ProgressBarBenefits from 'components/MyBenefits/ProgressBar';
import ConfirmationModal from 'components/Shared/ConfirmationModal';
import useScrollTop from 'hooks/useScrollTop';
import masks from 'utils/MasksUtils';
import './styles.scss';

type HeaderData = {
  title: React.ReactNode | string;
  description: React.ReactNode | string;
};

type ConfirmationModalData = {
  icon: string;
  title: string;
  description: React.ReactNode | string;
  rightButtonText: string;
};

export type ChoosePlansProps = {
  benefitOption: BenefitOptions;
  benefitsOptions: Array<BenefitOptions>;
  progressAmounts: BenefitAmounts;
  header: HeaderData;
  warningDataModal: ConfirmationModalData;
  openWarningModal: boolean;
  setOpenWarningModal: (openWarningModal: boolean) => void;
  handlePlanChoiceClick: (option: BenefitOptions) => void;
  lockId?: number;
};

function BenefitChoosePlans({
  benefitOption,
  benefitsOptions,
  progressAmounts,
  header,
  openWarningModal,
  warningDataModal,
  setOpenWarningModal,
  handlePlanChoiceClick,
  lockId = -1,
}: ChoosePlansProps) {
  useScrollTop();
  const { t } = useTranslation('benefit');

  return (
    <div data-testid="choose-plans-component" className="choose-plans">
      <div>
        <div className="header-title">{header.title}</div>
        <div className="header-description">{header.description}</div>
      </div>

      <div>
        <ProgressBarBenefits amounts={progressAmounts} />
      </div>

      <Row className="cards-container">
        {benefitsOptions.map((benefit, idx) => (
          <Col key={benefit.id}>
            <Card
              className={`${
                benefitOption.id === benefit.id && lockId !== benefit.id
                  ? 'active'
                  : ''
              }`}
            >
              <Card.Body>
                <div className="name-container">
                  <h3 className="name">{benefit.name}</h3>
                  {lockId === benefit.id ? (
                    <span className="info-label">{t('actual.plan')}</span>
                  ) : (
                    ''
                  )}
                </div>
                <h4 className="description">{t('dental.description')}</h4>
                <div className="items">
                  {benefit.coverage.map((item) => (
                    <div key={item.name} className="item">
                      <Icon
                        name={
                          item.color === '#14CC80'
                            ? 'checkFlagSuccess'
                            : 'checkFlagSuccessOrange'
                        }
                      />
                      <span className="name">{item.name}</span>
                    </div>
                  ))}
                </div>
                <div className="benefit-cost">
                  <h6 className="title">{t('dental.title')}</h6>
                  <span className="value">{masks.currency(benefit.value)}</span>
                </div>
                <div className="benefit-cost">
                  <h6 className="title">{t('dental.dependant.cost')}</h6>
                  <span className="value">
                    {masks.currency(benefit.valueDependant)}
                  </span>
                </div>
              </Card.Body>
              <Card.Footer>
                <label
                  htmlFor={`opt-in${idx}`}
                  className={`label${lockId === benefit.id ? ' locked' : ''}`}
                >
                  <Icon
                    name={
                      benefitOption.id === benefit.id && lockId !== benefit.id
                        ? 'radioButtonChecked'
                        : 'radioButtonUnchecked'
                    }
                  />
                  <input
                    className="input"
                    disabled={lockId === benefit.id}
                    onClick={() => handlePlanChoiceClick(benefit)}
                    id={`opt-in${idx}`}
                    name="plan"
                    type="radio"
                  />
                  {t('dental.choose.plan')} {benefit.name}
                </label>
              </Card.Footer>
            </Card>
          </Col>
        ))}
      </Row>
      <ConfirmationModal
        showLeftButton={false}
        data={warningDataModal}
        open={openWarningModal}
        close={() => setOpenWarningModal(false)}
        handleConfirm={() => setOpenWarningModal(false)}
      />
    </div>
  );
}

export default BenefitChoosePlans;
