import React from 'react';
import AppSkeleton from 'components/AppSkeleton';
import { Row } from 'react-bootstrap';
import './styles.scss';
import ProgressBarLoading from 'components/MyBenefits/ProgressBar/ProgressBarLoading';

type HeaderData = {
  title: React.ReactNode | string;
  description: React.ReactNode | string;
};

export type ChoosePlansLoaderProps = {
  header: HeaderData;
};

const benefitChooseCardCount = 3;
const benefitChooseOptionCount = 12;
const benefitChooseOption2Count = 14;
const benefitChooseOption3Count = 16;
const footerOptionCount = 2;

function generateKey(index: number) {
  const key = index + 1;
  return key;
}

function BenefitChooseLoader({ header }: ChoosePlansLoaderProps) {
  return (
    <div data-testid="benefit-choose-loading" className="benefit-choose-loader">
      <div>
        <div>{header.title}</div>
        <div>{header.description}</div>
      </div>
      <ProgressBarLoading />
      <div className="benefit-choose-card-loader-container">
        {Array.from(Array(benefitChooseCardCount), (card, cardIndex) => (
          <div
            key={generateKey(cardIndex)}
            className="benefit-choose-card-loader"
          >
            <div className="header first-line">
              <div className="first-bar">
                <AppSkeleton style={{ width: '100%', height: '29px' }} />
              </div>
              <div className="second-bar">
                <AppSkeleton style={{ width: '100%', height: '24px' }} />
              </div>
            </div>
            <div className="header second-line">
              <AppSkeleton style={{ width: '100%', height: '22px' }} />
            </div>
            <div className="body">
              {cardIndex === 0 && (
                <>
                  {Array.from(
                    Array(benefitChooseOptionCount),
                    (option, index) => (
                      <div key={generateKey(index)} className="option">
                        <div className="icon-placeholder">
                          <AppSkeleton
                            style={{ width: '21px', height: '21px' }}
                          />
                        </div>
                        <div className="text-placeholder">
                          <AppSkeleton
                            style={{
                              width: '100%',
                              height: '21px',
                            }}
                          />
                        </div>
                      </div>
                    )
                  )}
                </>
              )}
              {cardIndex === 1 && (
                <>
                  {Array.from(
                    Array(benefitChooseOption2Count),
                    (option, index) => (
                      <div key={generateKey(index)} className="option">
                        <div className="icon-placeholder">
                          <AppSkeleton
                            style={{ width: '24px', height: '24px' }}
                          />
                        </div>
                        <div className="text-placeholder">
                          <AppSkeleton
                            style={{
                              width: '100%',
                              height: '22px',
                            }}
                          />
                        </div>
                      </div>
                    )
                  )}
                </>
              )}
              {cardIndex === 2 && (
                <>
                  {Array.from(
                    Array(benefitChooseOption3Count),
                    (option, index) => (
                      <div key={generateKey(index)} className="option">
                        <div className="icon-placeholder">
                          <AppSkeleton
                            style={{ width: '24px', height: '24px' }}
                          />
                        </div>
                        <div className="text-placeholder">
                          <AppSkeleton
                            style={{
                              width: '100%',
                              height: '22px',
                            }}
                          />
                        </div>
                      </div>
                    )
                  )}
                </>
              )}
            </div>
            <div className="footer">
              {Array.from(Array(footerOptionCount), (item, index) => (
                <div key={generateKey(index)} className="footer-item">
                  <div className="first-bar">
                    <AppSkeleton style={{ width: '100%', height: '24px' }} />
                  </div>
                  <div className="second-bar">
                    <AppSkeleton style={{ width: '100%', height: '24px' }} />
                  </div>
                </div>
              ))}
              <div className="footer-item last">
                <div className="first-bar">
                  <AppSkeleton style={{ width: '100%', height: '24px' }} />
                </div>
                <div className="second-bar">
                  <AppSkeleton style={{ width: '100%', height: '24px' }} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Row>
        <div className="button-loader">
          <AppSkeleton
            style={{ width: '116px', height: '48px', marginRight: 'auto' }}
          />
        </div>
      </Row>
    </div>
  );
}
export default BenefitChooseLoader;
