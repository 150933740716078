import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { DependantTexts } from 'constants/localization/DependantTexts';
import { RedistributeTexts } from 'constants/localization/RedistributeTexts';
import { BenefitTexts } from 'constants/localization/BenefitTexts';
import { SharedMessages } from 'constants/localization/SharedMessages';
import { ProfileTexts } from 'constants/localization/ProfileTexts';

export default function configLocalization() {
  i18n.use(initReactI18next).init({
    resources: {
      'pt-BR': {
        benefit: BenefitTexts['pt-BR'],
        dependant: DependantTexts['pt-BR'],
        redistribute: RedistributeTexts['pt-BR'],
        profile: ProfileTexts['pt-BR'],
        shared: SharedMessages['pt-BR'],
      },
    },
    lng: 'pt-BR',
    fallbackLng: 'pt-BR',

    defaultNS: 'profile',

    interpolation: {
      escapeValue: false,
    },
  });
}
