import { Configuration, RedirectRequest } from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID!,
    authority:
      'https://login.microsoftonline.com/727d1266-ccaa-4c9f-91b5-e392a5fb335d',
    postLogoutRedirectUri: '/logout',
    redirectUri: process.env.REACT_APP_MSAL_REDIRECT,
    navigateToLoginRequestUrl: true,
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
  scopes: [
    'User.Read',
    'User.Read.All',
    'Directory.Read.All',
    `api://${process.env.REACT_APP_API_ID}/User.Impersonation`,
  ],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
  graphMePhotoEndpoint: 'https://graph.microsoft.com/v1.0/me/photo/$value',
};
