import React from 'react';
import { Row, Col } from 'react-bootstrap';
import AppSkeleton from 'components/AppSkeleton';
import './styles.scss';

function DependantDeleteLoading() {
  const header = { xl: [1, 9, 2], size: 3, width: ['30px', '418px', '148px'] };

  const table = [
    {
      id: 1,
      width: ['6vw', '5px', '56.7vw'],
      height: ['18px', '5px', '18px'],
      mdWidth: ['6vw', '5px', '56.4vw'],
    },
    {
      id: 2,
      width: ['6vw', '5px', '24vw', '6vw', '5px', '24vw'],
      height: ['18px', '5px', '18px', '18px', '5px', '18px'],
      mdWidth: ['6vw', '5px', '23vw', '5.5vw', '5px', '24vw'],
    },
    {
      id: 3,
      width: ['6vw', '5px', '24vw', '6vw', '5px', '24vw'],
      height: ['18px', '5px', '18px', '18px', '5px', '18px'],
      mdWidth: ['6vw', '5px', '23vw', '5.5vw', '5px', '24vw'],
    },
    {
      id: 4,
      width: ['6vw', '5px', '28vw', '28vw'],
      height: ['18px', '5px', '18px', '18px'],
      mdWidth: ['6vw', '5px', '28vw', '27vw'],
    },
  ];

  return (
    <Row className="loading-container">
      <Row className="header">
        {header.width?.map((width, index) => (
          <AppSkeleton
            key={header.xl[index]}
            className="item"
            xl={header.xl?.[index]}
            data-testid="header-item"
            style={{ width, height: '24px', borderRadius: '4px' }}
          />
        ))}
      </Row>
      <hr className="separator" />
      <Row className="body">
        {table.map((col) => (
          <Col key={col.id} data-testid="table-item" className="table-item">
            {col.width?.map((width, i) => (
              <AppSkeleton
                key={i.valueOf()}
                className="item"
                style={{
                  minHeight: '5px',
                  marginRight: '16px',
                  height: col.height?.[i],
                  width: window.innerWidth < 1439 ? col.mdWidth?.[i] : width,
                }}
              />
            ))}
          </Col>
        ))}
      </Row>

      <AppSkeleton
        className="footer"
        style={{
          width: '216px',
          height: '48px',
          marginTop: '40px',
        }}
      />
    </Row>
  );
}

export default DependantDeleteLoading;
