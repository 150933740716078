import React from 'react';
import Icon, { IconTypes } from 'components/Icon';
import { Row } from 'react-bootstrap';
import './styles.scss';

export type WaitingApprovalProps = {
  data: {
    text: string;
    icon: string;
    color: string;
    backgroundColor: string;
  };
};

function WaitingApproval({ data }: WaitingApprovalProps) {
  return (
    <Row
      className="waiting-approval"
      style={{ backgroundColor: data.backgroundColor }}
    >
      <div data-testid="waitingApprovalIcon" className="icon">
        <Icon name={data?.icon as IconTypes} />
      </div>
      <div className="text" style={{ color: data.color }}>
        {data.text}
      </div>
    </Row>
  );
}

export default WaitingApproval;
