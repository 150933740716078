import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Icon from 'components/Icon';
import AuthContext from 'configs/context';
import AppButton from 'components/AppButton';
import DependantDetailFields from 'components/Dependants/DependantDetailFields';
import benefitService from 'services/BenefitService';
import DependantService from 'services/DependantService';
import Checkbox from 'components/Shared/Checkbox';
import {
  BasketBenefit,
  BenefitAmounts,
  EmployeeFixedBenefit,
} from 'types/models/Benefit';
import RedistributeModal from 'components/Shared/Redistribute/RedistributeModal';
import ModalWithLoading from 'components/Shared/ModalWithLoading';
import { DeleteDependantRequest } from 'types/models/Dependant';
import DependantDeleteLoading from 'components/Dependants/DependantDeleteLoading';
import 'styles/base/page-header.scss';
import './styles.scss';

type DependantOperationModalData = {
  type: string;
  icon: string;
  title: string;
  description: React.ReactNode | string;
  rightButtonText: string;
};

function DependantDelete() {
  const navigate = useNavigate();
  const { t } = useTranslation('dependant');
  const {
    state: { cancelDependant },
  } = useContext(AuthContext);
  const isTotalWithoutAlelo = true;
  const [loading, setLoading] = useState(false);
  const [openRedistributeModal, setOpenRedistributeModal] = useState(false);
  const benefits = cancelDependant.benefits || [];
  const benefitsIncluded = benefits.length > 0;
  const [checkboxValue, setCheckboxValue] = useState(!benefitsIncluded);
  const [basketBenefits, setBasketBenefits] = useState<BasketBenefit[]>([]);
  const [basketRedistribution, setBasketRedistribution] = useState<
    BasketBenefit[]
  >([]);
  const [dependantBenefits, setDependantBenefits] = useState<
    EmployeeFixedBenefit[]
  >([]);
  const [totalAmount, setTotalAmount] = useState<BenefitAmounts>({
    total: 0,
    used: 0,
  });
  const [dataConfirmationModal, setDataConfirmationModal] =
    useState<DependantOperationModalData>({
      type: '',
      icon: '',
      title: '',
      description: '',
      rightButtonText: '',
    });
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [loadingSkeleton, setLoadingSkeleton] = useState(false);

  useEffect(() => {
    if (cancelDependant.name === '') navigate('/dependant');

    if (benefitsIncluded) {
      setLoadingSkeleton(true);

      benefitService
        .getDynamicBenefits()
        .then((response) => {
          const formattedBenefits = response.data.map((b) => ({
            ...b,
            value: 0,
          }));
          setBasketBenefits(formattedBenefits);
          setBasketRedistribution(formattedBenefits);
        })
        .finally(() => {
          setLoadingSkeleton(false);
        });

      benefitService.getAmounts(isTotalWithoutAlelo).then((response) => {
        setTotalAmount(response.data);
      });

      benefitService.getEmployeeBenefits().then((response) => {
        setDependantBenefits(response.data);
      });
    }
  }, []);

  const calculateReleasedAmount = (): number => {
    const selectedBenefits = dependantBenefits.filter((benefit) =>
      benefits.includes(benefit.name)
    );

    const totalValue = selectedBenefits.reduce(
      (acc, benefit) => acc + (benefit.valueDependant || 0),
      0
    );

    return totalValue;
  };

  const amount: BenefitAmounts = {
    total: totalAmount.total,
    used: totalAmount.used - calculateReleasedAmount(),
  };

  async function confirmRedistributeModal(
    confirmedBenefits: Array<BasketBenefit>
  ) {
    setOpenRedistributeModal(false);
    setLoading(true);
    setOpenConfirmationModal(true);

    setDataConfirmationModal({
      ...dataConfirmationModal,
      title: t('modal.loading.title'),
      description: t('modal.loading.description'),
    });

    try {
      const deleteRequest: DeleteDependantRequest = {
        dependantId: cancelDependant.id,
        benefitsRedistribution: confirmedBenefits.map(({ id, value }) => ({
          idBenefit: id,
          value,
        })),
      };

      await DependantService.deleteDependant(deleteRequest);

      setDataConfirmationModal({
        type: 'success',
        icon: 'sentSuccess',
        title: t('dependant.operation.delete.send.success.title'),
        description: (
          <>
            {t('dependant.operation.delete.send.success.description')}
            <span style={{ fontWeight: 'bold' }}>
              {t('dependant.operation.description.email')}
            </span>
          </>
        ),
        rightButtonText: t('modal.success.confirm.button'),
      });
    } catch (error) {
      setDataConfirmationModal({
        type: 'error',
        icon: 'errorIcon',
        title: t('modal.error.title'),
        description: t('modal.error.description'),
        rightButtonText: t('back.app.button.text'),
      });
    } finally {
      setLoading(false);
    }
  }

  const handleConfirmationModalConfirm = () => {
    if (dataConfirmationModal.type === 'success') {
      navigate('/dependant');
    }
    setOpenConfirmationModal(false);
  };

  const handleOncloseRedistribute = () => {
    const formattedBenefits: BasketBenefit[] = basketRedistribution.map(
      (b) => ({
        ...b,
        value: 0,
      })
    );

    setOpenRedistributeModal(false);
    setBasketRedistribution(formattedBenefits);
  };

  const deleteDependant = async () => {
    setLoading(true);
    setOpenConfirmationModal(true);

    setDataConfirmationModal({
      ...dataConfirmationModal,
      title: t('modal.loading.title'),
      description: t('modal.loading.description'),
    });

    try {
      const deleteRequest: DeleteDependantRequest = {
        dependantId: cancelDependant.id,
      };

      await DependantService.deleteDependant(deleteRequest);

      setDataConfirmationModal({
        type: 'success',
        icon: 'sentSuccess',
        title: t('dependant.operation.delete.send.success.title'),
        description: (
          <>
            {t('dependant.operation.delete.send.success.description')}
            <span style={{ fontWeight: 'bold' }}>
              {t('dependant.operation.description.email')}
            </span>
          </>
        ),
        rightButtonText: t('modal.success.confirm.button'),
      });
    } catch (error) {
      setDataConfirmationModal({
        type: 'error',
        icon: 'errorIcon',
        title: t('modal.error.title'),
        description: t('modal.error.description'),
        rightButtonText: t('back.app.button.text'),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="dependant-delete-container main-padding">
      <Row>
        <Col>
          <div className="page-header">
            <Row className="navigation">
              <Col
                className="navigation-link"
                onClick={() => {
                  if (!loadingSkeleton) navigate('/dependant');
                }}
              >
                <div className={`icon ${loadingSkeleton ? 'disabled' : ''}`}>
                  <Icon name="chevronLeftGray" />
                  <span>{t('delete.back.dependants')}</span>
                </div>
              </Col>
            </Row>
            <hr />
            <Row className="title">
              <h1>{t('delete.header.title')}</h1>
              <div className="desc">
                <p>{t('delete.header.description')}</p>
              </div>
            </Row>
          </div>
        </Col>
      </Row>
      <div className="page-body">
        {loadingSkeleton || !cancelDependant.name ? (
          <DependantDeleteLoading />
        ) : (
          <>
            <Row>
              <Col className="header">
                <Icon name="errorIcon" />
                <span className="title">
                  {t('delete.dependant.header.warning.text')}
                </span>
                <span className="name">{cancelDependant.name}</span>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <DependantDetailFields dependant={cancelDependant} />
              </Col>
            </Row>

            {benefitsIncluded && (
              <Row>
                <Col>
                  <div className="warning">
                    <h3 className="title">
                      {t('delete.dependant.warning.title')}
                    </h3>
                    <div className="description">
                      <p className="part">
                        {t('delete.dependant.warning.description.part1')}{' '}
                      </p>
                      <p className="bold">
                        {t('delete.dependant.warning.description.part2')}
                      </p>
                      <p className="part">
                        {t('delete.dependant.warning.description.part3')}
                      </p>
                      <p className="bold">
                        {t('delete.dependant.warning.description.part4')}
                      </p>
                    </div>
                    <div className="accept">
                      <Checkbox
                        checkedValue={checkboxValue}
                        setCheckedValue={setCheckboxValue}
                      />
                      <span className="title">
                        {t('delete.dependant.warning.checkbox.text')}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            )}
            <Row className="button">
              <Col>
                <AppButton
                  className="btn"
                  full
                  disabled={!checkboxValue}
                  onClick={() => {
                    benefitsIncluded
                      ? setOpenRedistributeModal(true)
                      : deleteDependant();
                  }}
                >
                  {t('delete.dependant.solicitation.button')}
                </AppButton>
              </Col>
            </Row>
          </>
        )}

        <RedistributeModal
          totalAmount={amount}
          benefits={basketRedistribution}
          open={openRedistributeModal}
          setBenefits={setBasketRedistribution}
          close={() => handleOncloseRedistribute()}
          disableConfirm={amount.total > amount.used}
          confirm={(confirmedBenefits) =>
            confirmRedistributeModal(confirmedBenefits)
          }
        />

        <ModalWithLoading
          loading={loading}
          showFooter={!loading}
          data={dataConfirmationModal}
          open={openConfirmationModal}
          handleConfirm={() => handleConfirmationModalConfirm()}
          close={() => setOpenConfirmationModal(false)}
        />
      </div>
    </div>
  );
}

export default DependantDelete;
