import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'react-bootstrap';
import Icon from 'components/Icon';
import './styles.scss';

function BenefitWarning() {
  const navigate = useNavigate();
  const { t } = useTranslation('dependant');

  return (
    <Row>
      <Col>
        <div className="benefit-warning-container">
          <div data-testid="helpIcon" className="title">
            <Icon name="helpIcon" />
            <div>
              <h4>{t('dependant.edit.benefits')}</h4>
              <h5>{t('manage.dependants.benefits')}</h5>
            </div>
          </div>
          <div data-testid="chevronRightBlue" className="actions">
            <Button onClick={() => navigate('/benefit')}>
              {t('go.to.benefits')}
              <Icon name="chevronRightBlue" />
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default BenefitWarning;
