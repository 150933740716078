import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FieldProfile from 'components/Shared/FieldProfile';
import { DependantProfile } from 'types/models/Dependant';
import DateUtils from 'utils/DateUtils';
import './styles.scss';

type Props = {
  dependant: DependantProfile;
};

function DependantDetailFields({ dependant }: Props) {
  const { t } = useTranslation('dependant');

  const benefits = dependant.benefits || [];

  function formatBirthdate() {
    return DateUtils.format(dependant.birthdate, 'dd/MM/yyyy');
  }

  return (
    <div className="dependant-fields-container">
      <FieldProfile
        dependant={dependant}
        firstFieldName={t('full.name')}
        firstValue={dependant.name}
        secondFieldName=""
        secondValue=""
      />
      <FieldProfile
        dependant={dependant}
        firstFieldName={t('document')}
        firstValue={dependant.document || ''}
        secondFieldName={t('kinship')}
        secondValue={dependant.kinship}
      />
      <FieldProfile
        dependant={dependant}
        firstFieldName={t('birthdate')}
        firstValue={formatBirthdate()}
        secondFieldName={t('included.IR')}
        secondValue={dependant.includeIR ? 'Sim' : 'Não'}
      />
      <Row className="dependant-benefits">
        <Col className="profile-info">
          <h4>{t('field.profile.benefits.included')}</h4>
          <div />
          {benefits.length > 0 &&
            benefits.map((benefit) => <span key={benefit}>{benefit}</span>)}
          {benefits.length === 0 && (
            <span className="no-benefits">
              {t('field.profile.not.included')}
            </span>
          )}
        </Col>
        <Col className="col-separator">
          <hr />
        </Col>
      </Row>
    </div>
  );
}

export default DependantDetailFields;
