import React from 'react';
import Icon, { IconTypes } from 'components/Icon';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/esm/types';
import { OperationStatus } from 'types/common/OperationStatus';
import './styles.scss';

export type GenericTooltipMessageProps = {
  icon: IconTypes;
  message: string;
  title: string;
  type: OperationStatus | string;
  placement: Placement;
};

function GenericTooltipMessage({
  icon,
  message,
  title,
  type,
  placement,
}: GenericTooltipMessageProps) {
  return (
    <div className="tooltip-container">
      <OverlayTrigger
        placement={placement}
        overlay={
          <Popover className={`popover-${type}`}>
            <Popover.Body>
              <div className="body">
                <h2 className="title">{title}</h2>
                <p className="message">{message}</p>
              </div>
            </Popover.Body>
          </Popover>
        }
      >
        <div className="tooltip-message" data-testid="icon">
          <Icon name={icon} />
        </div>
      </OverlayTrigger>
    </div>
  );
}

export default GenericTooltipMessage;
