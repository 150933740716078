import { DependantEditProfile, DependantProfile } from 'types/models/Dependant';
import { ProfileResume } from 'types/models/Profile';
import { BenefitToManage } from 'types/models/Benefit';
import { LoggedUser, StoreAction, types } from './types';

export const resume = (data: ProfileResume): StoreAction => ({
  type: types.RESUME,
  payload: data,
});

export const loggedUser = (data: LoggedUser): StoreAction => ({
  type: types.LOGGED,
  payload: data,
});

export const editCurrentDependant = (
  data: DependantEditProfile
): StoreAction => ({
  type: types.EDIT_DEPENDANT,
  payload: data,
});

export const cancelCurrentDependant = (
  data: DependantProfile
): StoreAction => ({
  type: types.CANCEL_DEPENDANT,
  payload: data,
});

export const benefitManage = (data: BenefitToManage): StoreAction => ({
  type: types.MANAGE_BENEFIT,
  payload: data,
});

export const idBenefit = (data: number): StoreAction => ({
  type: types.ID_BENEFIT_PLAN,
  payload: data,
});

export const pendingOperations = (data: boolean): StoreAction => ({
  type: types.PENDING_OPERATION,
  payload: data,
});

export const reloadBenefits = (data: boolean): StoreAction => ({
  type: types.RELOAD_BENEFIT,
  payload: data,
});
