/* eslint-disable import/prefer-default-export */
export const SharedMessages = {
  'pt-BR': {
    'progress.bar.used': 'VALOR UTILIZADO',
    'progress.bar.discount': 'DESCONTO EM FOLHA',
    'progress.bar.available': 'VALOR DISPONÍVEL',
    'progress.bar.basket': 'Total da cesta',
    'pending.operation.message':
      'Há uma solicitação em andamento! Assim que finalizar novas solicitações poderão ser realizadas.',
    edited: 'EDITADO',
  },
};
