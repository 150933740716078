import { PublicClientApplication } from '@azure/msal-browser';
import { loginRequest, graphConfig, msalConfig } from '../authConfig';
import Storage from './storage';

export default async function CallMsGraph(account: any) {
  const msalInstance = new PublicClientApplication(msalConfig);

  if (!account) {
    throw Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    );
  }

  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account,
  });

  const headers = new Headers();
  const bearer = `Bearer ${response.accessToken}`;

  headers.append('Authorization', bearer);
  headers.append('Content-type', 'image/jpeg');

  const options = {
    method: 'GET',
    headers,
  };

  return fetch(graphConfig.graphMePhotoEndpoint, options).then(async (res) => {
    if (!res.ok) {
      return;
    }
    res.blob().then((photo: any) => {
      const reader = new FileReader();
      reader.readAsDataURL(photo);
      reader.onload = () => {
        const base64data = reader.result;
        Storage.setAvatar(base64data as string);
      };
    });
  });
}
