import React from 'react';
import AppSkeleton from 'components/AppSkeleton';
import './styles.scss';

function BenefitBasketEditLoader() {
  return (
    <div
      className="benefit-basket-edit-loader-container"
      data-testid="benefit-basket-edit-loader"
    >
      <div className="header-loader">
        <div className="nav-loader">
          <div className="element">
            <AppSkeleton style={{ width: '24px', height: '24px' }} />
          </div>
          <div className="element">
            <AppSkeleton style={{ width: '144px', height: '16px' }} />
          </div>
        </div>
        <div className="header-content">
          <div className="element">
            <AppSkeleton style={{ width: '72px', height: '72px' }} />
          </div>
          <div className="header-description-loader">
            <AppSkeleton style={{ width: '419px', height: '48px' }} />
            <AppSkeleton style={{ width: '312px', height: '19px' }} />
          </div>
        </div>
      </div>
      <div className="benefit-card-loader">
        <AppSkeleton style={{ width: '300px', height: '32px' }} />
        <div className="benefit-small-card-loader">
          <AppSkeleton style={{ width: '40px', height: '40px' }} />
          <div className="middle-content">
            <AppSkeleton style={{ width: '349px', height: '29px' }} />
            <AppSkeleton style={{ width: '173px', height: '19px' }} />
          </div>
          <div className="footer-content">
            <AppSkeleton style={{ width: '309px', height: '48px' }} />
            <AppSkeleton style={{ width: '32px', height: '32px' }} />
          </div>
        </div>
      </div>
      <div className="benefit-card-loader">
        <AppSkeleton style={{ width: '300px', height: '32px' }} />
        <div className="card-loader-container">
          <div className="benefit-small-alt-card-loader">
            <AppSkeleton style={{ width: '40px', height: '40px' }} />
            <div className="middle-content">
              <AppSkeleton style={{ width: '100%', height: '29px' }} />
              <AppSkeleton style={{ width: '173px', height: '19px' }} />
            </div>
          </div>
          <div className="benefit-small-alt-card-loader">
            <AppSkeleton style={{ width: '40px', height: '40px' }} />
            <div className="middle-content">
              <AppSkeleton style={{ width: '100%', height: '29px' }} />
              <AppSkeleton style={{ width: '173px', height: '19px' }} />
            </div>
          </div>
        </div>
        <div className="footer">
          <AppSkeleton style={{ width: '300px', height: '24px' }} />
          <div className="rows">
            <div className="row first">
              <div className="element">
                <AppSkeleton style={{ width: '96px', height: '22px' }} />
              </div>
              <div className="element">
                <AppSkeleton style={{ width: '96px', height: '22px' }} />
              </div>
            </div>
            <div className="row second">
              <div className="element">
                <AppSkeleton style={{ width: '300px', height: '22px' }} />
              </div>
              <div className="element">
                <AppSkeleton style={{ width: '96px', height: '22px' }} />
              </div>
            </div>
            <div className="row third">
              <div className="element">
                <AppSkeleton style={{ width: '300px', height: '22px' }} />
              </div>
              <div className="element">
                <AppSkeleton style={{ width: '96px', height: '22px' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BenefitBasketEditLoader;
