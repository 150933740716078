import React from 'react';
import { Col, Row } from 'react-bootstrap';
import AppModal from 'components/AppModal';
import Icon from 'components/Icon';
import './styles.scss';
import { useTranslation } from 'react-i18next';

export type ModalIRProps = {
  open: boolean;
  close: () => void;
};

function ModalIR({ open, close }: ModalIRProps) {
  const { t } = useTranslation('dependant');
  return (
    <AppModal
      open={open}
      rightButtonText={t('income.tax.modal.button')}
      showLeftButton={false}
      confirm={() => {
        close();
      }}
      close={() => {
        close();
      }}
    >
      <div className="modal-with-confirmation">
        <Row className="icon">
          <Col>
            <Icon name="warning" />
          </Col>
        </Row>
        <Row className="text">
          <Col>
            <h2 className="title pe-0">{t('income.tax.modal.title')}</h2>
            <div className="pe-0 modal-ir">
              <div className="line">
                <div className="dot">1</div>
                <h4 className="text">
                  {t('income.tax.modal.first.dot')}
                  <span className="strong">
                    {' '}
                    {t('income.tax.modal.first.dot.strong.text')}
                  </span>
                  {t('income.tax.modal.first.dot.continuation')}
                </h4>
              </div>
              <br />
              <div className="line">
                <div className="dot">2</div>
                <h4 className="text">
                  {t('income.tax.second.dot')}{' '}
                  <span className="strong">
                    {t('income.tax.modal.second.dot.strong.text')}
                  </span>{' '}
                  {t('income.tax.modal.second.dot.continuation')}
                </h4>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </AppModal>
  );
}

export default ModalIR;
