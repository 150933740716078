import React, { useState } from 'react';
import Icon from 'components/Icon';
import ConfirmationModal from 'components/Shared/ConfirmationModal';
import './style.scss';

export type AppUploadFileProps = {
  fieldName: string;
  onRemove: (name: string, fieldName: string) => void;
  valid: boolean;
  translation: any;
  fileName: string;
  fileNameToRemove: string;
};

function AppUploadFile({
  fieldName,
  valid = true,
  fileName,
  onRemove,
  translation,
  fileNameToRemove,
}: AppUploadFileProps) {
  const [openModal, setOpenModal] = useState(false);
  const modalData = {
    leftButtonText: translation('modal.cancel.button'),
    rightButtonText: translation('modal.confirm.button'),
    icon: 'errorIcon',
    title: translation('modal.confirm.exclude.title'),
    description: translation('modal.confirm.exclude.description'),
  };

  return (
    <label
      htmlFor={`input-${fieldName}-valid`}
      className="file-container valid"
    >
      <div className="file">
        <span className="label">{fileName}</span>
        <button
          type="button"
          onClick={() => {
            setOpenModal(true);
          }}
        >
          <Icon name="trashCan" />
        </button>
      </div>

      {!valid && (
        <div className="description">
          <span>{translation(`upload.description.${fieldName}`)}</span>
          <span className="example">{translation(`upload.field.example`)}</span>
        </div>
      )}
      <ConfirmationModal
        open={openModal}
        data={modalData}
        showLeftButton={openModal}
        handleConfirm={() => {
          setOpenModal(false);
          onRemove(fileNameToRemove, fieldName);
        }}
        close={() => {
          setOpenModal(false);
        }}
      />
    </label>
  );
}
export default AppUploadFile;
