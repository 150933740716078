import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BenefitOperation, BenefitResumeWithId } from 'types/models/Benefit';
import AppButton from 'components/AppButton';
import Icon from 'components/Icon';
import masks from 'utils/MasksUtils';
import { benefitManage } from 'store/user/actions';
import AuthContext from 'configs/context';
import ObsLabel from 'components/Shared/ObsLabel';

import {
  AvailableBenefitsEnum,
  BenefitStatus,
} from '../AvailableBenefits/AvailableBenefitsEnum';
import './styles.scss';

type ActiveBenefitProps = {
  benefits: BenefitResumeWithId[];
  operation?: BenefitOperation;
};

function ActiveBenefit({ benefits, operation }: ActiveBenefitProps) {
  const navigate = useNavigate();
  const { t } = useTranslation('benefit');
  const {
    state: { benefitToManage },
    dispatch,
  } = useContext(AuthContext);

  benefits.sort((a, b) => a.benefitId - b.benefitId);

  const handleClick = (benefit: BenefitResumeWithId) => {
    dispatch(
      benefitManage({
        ...benefitToManage,
        benefit,
      })
    );
    navigate('/benefit/plan/edit');
  };

  return (
    <>
      {benefits.map((benefit) => {
        if (!benefit.option) return true;
        return (
          <div className="active-benefit-container" key={benefit.name}>
            <Row className="header">
              <Col xs={12}>
                <div data-testid="benefit-item" className="content">
                  <img src={benefit.urlImage} alt="benefit icon" />
                  <div className="title">
                    <div className="text">
                      <h1 className="name">
                        {benefit.name}
                        {benefit.mandatory && <span>{t('mandatory')}</span>}
                      </h1>
                      <p className="description">{benefit.subtitle}</p>
                    </div>
                    <div>
                      {(benefit.denominationBenefit ===
                        AvailableBenefitsEnum.dentalAssistance ||
                        benefit.denominationBenefit ===
                          AvailableBenefitsEnum.healthAssistance) && (
                        <AppButton
                          className="settings"
                          onClick={() => {
                            handleClick(benefit);
                          }}
                        >
                          {t('benefit.manage')} <Icon name="settingsOutlined" />
                        </AppButton>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="actions d-none">
                <span>{t('show.actions')}</span>
                <Icon name="chevronRightBlue" />
              </Col>
            </Row>
            <div>
              <div className="line">
                <span>{t('actual.plan')}</span>
                <span className="value">{benefit.option}</span>
              </div>
              <div className="line">
                <span>{t('cost.benefit')}</span>
                <span className="value">
                  {masks.currency(benefit.valueEmployee || 0)}
                </span>
              </div>
              <div className="line">
                <span>{`${t('total.cost')} (${
                  benefit.numberDependants
                })`}</span>
                <span className="value">
                  {masks.currency(benefit.amountDependants || 0)}
                </span>
              </div>
              <div className="line total">
                <span>{t('total')}</span>
                <span className="value">{masks.currency(benefit.total)}</span>
              </div>
            </div>
            <div className="footer">
              {benefit.denominationBenefit ===
                AvailableBenefitsEnum.dentalAssistance &&
                (benefit.operationCancelStatus === BenefitStatus.analysis ||
                  benefit.operationCancelStatus ===
                    BenefitStatus.notStarted) && (
                  <ObsLabel
                    data={{
                      backgroundColor: 'var(--color-complementary-02-neutral)',
                      descriptionColor: 'var(--color-primary-02-contrast)',
                      description: t('pending.cancel.dental.label.description'),
                      title: t('pending.cancel.dental.label.title'),
                      titleColor: 'var(--color-primary-01-dark)',
                      icon: 'warning',
                      classNameIcon: 'warning',
                    }}
                  />
                )}
              {(benefit.operationUpdateStatus === BenefitStatus.analysis ||
                benefit.operationUpdateStatus === BenefitStatus.notStarted) && (
                <ObsLabel
                  data={{
                    classNameIcon: 'help-blue-icon',
                    icon: 'infoCircle',
                    title: t('benefit.manage.upgrade.pending.obs.label.title'),
                    titleColor: 'var(--color-secondary-variant-base)',
                    description: t(
                      'benefit.manage.upgrade.pending.obs.label.description'
                    ),
                    backgroundColor: 'var(--color-secondary-neutral)',
                  }}
                />
              )}
            </div>
            {(operation?.status === 'NOT_STARTED' ||
              operation?.status === 'ANALYSIS') &&
              benefit.denominationBenefit ===
                operation?.operationData?.denomination && (
                <Row className="pendency-row">
                  <Col className="pendency-info">
                    <Icon name="historyToggle" />
                    <h2>{t('benefit.manage.dependant.banner.message')}</h2>
                  </Col>
                </Row>
              )}
          </div>
        );
      })}
    </>
  );
}

export default ActiveBenefit;
