import React from 'react';
import { Row, Col } from 'react-bootstrap';
import AppSkeleton from 'components/AppSkeleton';

function AdditionalBenefitsLoader() {
  return (
    <Row>
      <Col
        sm={12}
        style={{ margin: '8px' }}
        className="additional-skeleton"
        data-testid="additional-skeleton"
      >
        <div className="benefit-card">
          <AppSkeleton
            style={{ width: '56px', height: '56px', marginBottom: '24px' }}
            data-testid="app-skeleton"
          />
          <div className="text-container">
            <AppSkeleton
              style={{ width: '100%', height: '29px', marginBottom: '8px' }}
              data-testid="app-skeleton"
            />
            <AppSkeleton
              style={{ width: '70%', height: '19px' }}
              data-testid="app-skeleton"
            />
          </div>
          <div className="button-div d-flex justify-content-between align-items-center">
            <div style={{ flex: '1 1 0', minWidth: 0 }}>
              <AppSkeleton
                style={{ width: '100%', height: '48px', marginTop: '24px' }}
                data-testid="app-skeleton"
              />
            </div>
            <div>
              <AppSkeleton
                style={{
                  width: '32px',
                  height: '32px',
                  marginLeft: '8px',
                  marginTop: '26px',
                }}
                data-testid="app-skeleton"
              />
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default AdditionalBenefitsLoader;
