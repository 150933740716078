import React from 'react';
import { Col, Row } from 'react-bootstrap';
import AppModal from 'components/AppModal';
import Icon, { IconTypes } from 'components/Icon';
import './styles.scss';
import { Trans, useTranslation } from 'react-i18next';

type modalProps = {
  showLeftButton?: boolean;
  open: boolean;
  data?: {
    icon?: string;
    title?: string;
    description?: any;
    leftButtonText?: string;
    rightButtonText?: string;
  };
  close: () => void;
  handleConfirm: () => void;
};

function CancelDependantModal({
  showLeftButton,
  data,
  open,
  close,
  handleConfirm,
}: modalProps) {
  const { t } = useTranslation('dependant');

  return (
    <AppModal
      open={open}
      leftButtonText={data?.leftButtonText}
      rightButtonText={data?.rightButtonText}
      showFooter
      showLeftButton={showLeftButton}
      confirm={() => {
        handleConfirm();
      }}
      close={() => {
        close();
      }}
    >
      <div className="modal-with-confirmation">
        <Row className="icon">
          <Col>
            <Icon name={data?.icon as IconTypes} />
          </Col>
        </Row>
        <Row className="text">
          <Col>
            <h2 className="title pe-0">{data?.title}</h2>
            <h4
              className={
                data?.title !== ''
                  ? 'description pe-0'
                  : 'description pe-0 mt-0'
              }
            >
              <Trans
                t={t}
                i18nKey="modal.dependant.cancel.description"
                components={{ bold: <span className="bold" /> }}
              />
            </h4>
          </Col>
        </Row>
      </div>
    </AppModal>
  );
}

export default CancelDependantModal;
