import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from '../ConfirmationModal';

export type Props = {
  handleConfirm: () => void;
  handleClose: () => void;
  open: boolean;
  rightButtonText?: string;
};

function ConfirmationBackModal({
  handleClose,
  handleConfirm,
  open,
  rightButtonText,
}: Props) {
  const { t } = useTranslation('dependant');
  const data = {
    rightButtonText: rightButtonText || t('modal.right.button'),
    leftButtonText: t('modal.left.button'),
    icon: 'warning',
    title: t('modal.title'),
    description: t('modal.description'),
  };

  return (
    <ConfirmationModal
      showLeftButton
      close={() => handleClose()}
      handleConfirm={() => handleConfirm()}
      open={open}
      data={data}
    />
  );
}

export default ConfirmationBackModal;
