import React, { useEffect } from 'react';
import './styles.scss';
import masks from 'utils/MasksUtils';
import { BenefitBasketResume } from 'types/models/Profile';
import { useTranslation } from 'react-i18next';

export type BenefitResumeProps = {
  benefitBasketResume: BenefitBasketResume;
  totalBasketValue: number;
};

function BenefitsResume({
  benefitBasketResume,
  totalBasketValue,
}: BenefitResumeProps) {
  const { t } = useTranslation('benefit');

  function calculatePayrollDiscount() {
    const dentalInsuranceValue = benefitBasketResume.dentalInsuranceValue || 0;
    const { medicalInsuranceValue } = benefitBasketResume;
    const { vouchersValue } = benefitBasketResume;
    const payrollDiscount =
      totalBasketValue -
      dentalInsuranceValue -
      medicalInsuranceValue -
      vouchersValue;
    const formattedPayrollDiscount = parseFloat(payrollDiscount.toFixed(2));
    return formattedPayrollDiscount;
  }

  return (
    <div className="benefits-resume-list-container">
      <div className="benefits-resume-list-title">
        <span>{t('benefit.adjust.warning.modal.resume.title')}</span>
      </div>
      <div className="benefits-resume-list">
        <div className="header">
          <span className="header-description">
            {t('benefit.adjust.warning.modal.resume.total')}
          </span>
          <span className="header-value">
            {masks.currency(totalBasketValue)}
          </span>
        </div>
        <div className="resume-content">
          <div className="resume-line">
            <span className="description">
              {t('benefit.adjust.warning.modal.resume.medical')}
            </span>
            <span className="value">
              {masks.currency(benefitBasketResume.medicalInsuranceValue)}
            </span>
          </div>
          {benefitBasketResume.dentalInsuranceValue && (
            <div className="resume-line">
              <span className="description">
                {t('benefit.adjust.warning.modal.resume.dental')}
              </span>
              <span className="value">
                {masks.currency(benefitBasketResume.dentalInsuranceValue)}
              </span>
            </div>
          )}
          <div
            className={
              calculatePayrollDiscount() >= 0
                ? 'resume-line last'
                : 'resume-line'
            }
          >
            <span className="description">
              {t('benefit.adjust.warning.modal.resume.alelo')}
            </span>
            <span className="value">
              {masks.currency(benefitBasketResume.vouchersValue)}
            </span>
          </div>
          {calculatePayrollDiscount() < 0 && (
            <div className="resume-line discount">
              <span className="description">
                {t('benefit.adjust.warning.modal.resume.discount')}
              </span>
              <span className="value">
                {masks.currency(calculatePayrollDiscount())}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default BenefitsResume;
