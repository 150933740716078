import React from 'react';
import ptBR from 'date-fns/locale/pt-BR';
import { range } from 'lodash';
import { getYear, getMonth, format } from 'date-fns';
import Icon from 'components/Icon';
import ReactDatePicker, {
  registerLocale,
  ReactDatePickerProps,
  setDefaultLocale,
  ReactDatePickerCustomHeaderProps,
} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './style.scss';

registerLocale('ptBR', ptBR);
setDefaultLocale('ptBR');

function CustomHeader({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
}: ReactDatePickerCustomHeaderProps) {
  const years = range(1900, getYear(new Date()) + 1, 1);

  const months = Array.from({ length: 12 }, (_, i) => {
    const month = format(new Date(0, i), 'MMMM', { locale: ptBR });

    return `${month.charAt(0).toUpperCase()}${month.slice(1)}`;
  });

  return (
    <div className="custom-header">
      <select
        className="month"
        value={months[getMonth(date)]}
        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
      >
        {months.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <select
        className="year"
        value={getYear(date)}
        onChange={({ target: { value } }) => changeYear(Number(value))}
      >
        {years.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <button type="button" className="decrease" onClick={decreaseMonth}>
        <Icon name="leftIndicator" />
      </button>
      <button type="button" className="increase" onClick={increaseMonth}>
        <Icon name="rightIndicator" />
      </button>
    </div>
  );
}

interface CustomInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  onArrowClick: () => void;
}

const CustomInput = React.forwardRef((props: CustomInputProps, ref) => {
  const { onArrowClick, ...rest } = props;

  return (
    <div className="date-picker-dropdown">
      <input {...rest} />

      <button className="calendar-icon" type="button" onClick={onArrowClick}>
        <Icon name="calendar" />
      </button>
    </div>
  );
});

export default function DatePicker({
  onCalendarOpen,
  onCalendarClose,
  onChange,
  ...rest
}: ReactDatePickerProps) {
  const datePickerRef = React.useRef<ReactDatePicker>(null);

  const handleCalendarOpen = () => {
    onCalendarOpen && onCalendarOpen();
  };

  const handleCalendarClose = () => {
    onCalendarClose && onCalendarClose();
  };

  const onArrowClick = () => {
    const isCalendarOpen = datePickerRef.current?.isCalendarOpen() || false;
    if (!isCalendarOpen) {
      datePickerRef.current?.setOpen(true);
    }
  };

  return (
    <ReactDatePicker
      ref={datePickerRef}
      maxDate={new Date()}
      minDate={new Date('1900/01/01')}
      dateFormat="dd/MM/yyyy"
      showPopperArrow={false}
      popperClassName="popper"
      popperPlacement="bottom-end"
      placeholderText="DD/MM/AAAA"
      wrapperClassName="date-picker"
      renderCustomHeader={CustomHeader}
      onCalendarOpen={handleCalendarOpen}
      onCalendarClose={handleCalendarClose}
      onChange={(date, event) => {
        onChange(date, event);
      }}
      formatWeekDay={(nameOfDay) =>
        nameOfDay.substring(0, 1).toLocaleUpperCase()
      }
      customInput={<CustomInput onArrowClick={onArrowClick} />}
      onKeyDown={(e) => {
        e.preventDefault();
      }}
      {...rest}
    />
  );
}
