import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import ProgressBarBenefits from 'components/MyBenefits/ProgressBar';
import AdditionalBenefits from 'components/MyBenefits/AdditionalBenefits';
import ActiveBenefit from 'components/MyBenefits/ActiveBenefit';
import AvailableBenefits from 'components/MyBenefits/AvailableBenefits';
import BasketBenefits from 'components/MyBenefits/BasketBenefits';
import {
  BenefitOperation,
  BenefitResumeWithId,
  BenefitsList,
} from 'types/models/Benefit';
import benefitService from 'services/BenefitService';
import ConfirmationModal from 'components/Shared/ConfirmationModal';
import OperationService from 'services/OperationService';
import AuthContext from 'configs/context';
import { benefitManage, pendingOperations } from 'store/user/actions';
import {
  BenefitStatus,
  AvailableBenefitsEnum,
} from 'components/MyBenefits/AvailableBenefits/AvailableBenefitsEnum';
import { OperationStatus } from 'types/common/OperationStatus';
import PendingOperationBanner from 'components/Shared/PendingOperationBanner';
import MyBenefitsLoader from './MyBenefitsLoader';
import './styles.scss';

type ConfirmationModalDataType = {
  icon: string;
  title: React.ReactNode | string;
  description: React.ReactNode | string;
  rightButtonText: string;
  leftButtonText?: string;
};

type OperationModalData = {
  [key in OperationStatus]?: ConfirmationModalDataType;
};

const DEFAULT_MODAL_DATA: ConfirmationModalDataType = {
  icon: '',
  title: '',
  description: '',
  rightButtonText: '',
};

type MyBenefitsProps = {
  isActive?: boolean;
};

function Benefits({ isActive }: MyBenefitsProps) {
  const navigate = useNavigate();
  const { t } = useTranslation('benefit');
  const {
    dispatch,
    state: { benefitToManage, pendingOperation, reloadBenefit },
  } = useContext(AuthContext);

  const CLOSE_BUTTON_TEXT = t('benefit.operation.close.button');

  const [loading, setLoading] = useState(false);
  const [benefits, setBenefits] = useState<BenefitResumeWithId[]>([]);
  const [openOperationModal, setOpenOperationModal] = useState(false);
  const [operations, setOperations] = useState<BenefitsList>({
    benefitsResume: [],
  });

  const {
    benefitCancelOperation,
    benefitCreateOperation,
    benefitUpgradeOperation,
    dependantBenefitUpdateOperation,
  } = operations;

  useEffect(() => {
    setLoading(true);
    benefitService
      .getBenefitsResume()
      .then((response) => {
        setBenefits(response.data.benefitsResume || []);
        setOperations(response.data);

        if (response.data.benefitCreateOperation) {
          disableActions(response.data.benefitCreateOperation);

          const createOperation = response.data.benefitCreateOperation;

          setOpenOperationModal(
            createOperation?.flagViewed === false &&
              createOperation?.status !== BenefitStatus.notStarted &&
              createOperation?.status !== BenefitStatus.analysis
          );
        }

        if (response.data.benefitCancelOperation) {
          disableActions(response.data.benefitCancelOperation);

          const cancelOperation = response.data.benefitCancelOperation;
          setOpenOperationModal(
            cancelOperation?.flagViewed === false &&
              cancelOperation?.status !== BenefitStatus.notStarted &&
              cancelOperation?.status !== BenefitStatus.analysis
          );
        }

        if (response.data.dependantBenefitUpdateOperation) {
          disableActions(response.data.dependantBenefitUpdateOperation);

          const dependantUpdateOperation =
            response.data.dependantBenefitUpdateOperation;

          setOpenOperationModal(
            dependantUpdateOperation?.flagViewed === false &&
              dependantUpdateOperation?.status !== BenefitStatus.notStarted &&
              dependantUpdateOperation?.status !== BenefitStatus.analysis
          );
        }
        if (response.data.benefitUpgradeOperation) {
          disableActions(response.data.benefitUpgradeOperation);

          const createOperation = response.data.benefitUpgradeOperation;

          setOpenOperationModal(
            createOperation?.flagViewed === false &&
              createOperation?.status !== BenefitStatus.notStarted &&
              createOperation?.status !== BenefitStatus.analysis
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [reloadBenefit]);

  function disableActions(data: BenefitOperation) {
    const notStartedDentalBenefit = data?.status === BenefitStatus.notStarted;
    const hasAnalysDentalBenefit = data?.status === BenefitStatus.analysis;

    if (notStartedDentalBenefit || hasAnalysDentalBenefit) {
      dispatch(pendingOperations(true));
    }
  }

  function toggleOperationVisibility(operation?: BenefitOperation): void {
    try {
      if (operation !== undefined) {
        OperationService.updateOperationVisibility(operation.id);
        setOpenOperationModal(false);
        dispatch(pendingOperations(false));
      }
    } catch (error) {
      setOpenOperationModal(false);
    }
  }

  function renderConfirmationModal(
    close: () => void,
    handleConfirm: () => void,
    openState: boolean,
    data: ConfirmationModalDataType
  ) {
    return (
      <ConfirmationModal
        showLeftButton={
          benefitCreateOperation?.status === BenefitStatus.approved ||
          dependantBenefitUpdateOperation?.status === BenefitStatus.approved ||
          benefitUpgradeOperation?.status === BenefitStatus.approved
        }
        close={close}
        handleConfirm={handleConfirm}
        open={openState}
        data={data}
      />
    );
  }

  function getCancelBenefitModalData(): ConfirmationModalDataType {
    const statusData: OperationModalData = {
      DENIED: {
        icon: 'errorIcon',
        title: (
          <Trans
            t={t}
            i18nKey={t('benefit.operation.cancel.denied.title')}
            components={{ bold: <span className="bold" /> }}
          />
        ),
        description: (
          <Trans
            t={t}
            i18nKey={t('benefit.operation.cancel.denied.description')}
            components={{ bold: <span className="bold" /> }}
          />
        ),
        rightButtonText: CLOSE_BUTTON_TEXT,
      },
      APPROVED: {
        icon: 'checkCircleFull',
        title: (
          <Trans
            t={t}
            i18nKey={t('benefit.operation.cancel.approved.title')}
            components={{ bold: <span className="bold" /> }}
          />
        ),
        description: (
          <Trans
            t={t}
            i18nKey={t('benefit.operation.cancel.approved.description')}
            components={{ bold: <span className="bold" /> }}
          />
        ),
        rightButtonText: CLOSE_BUTTON_TEXT,
      },
    };

    return (
      statusData[benefitCancelOperation?.status ?? ''] || DEFAULT_MODAL_DATA
    );
  }

  function getCreateBenefitModalData(): ConfirmationModalDataType {
    const statusData: OperationModalData = {
      APPROVED: {
        icon: 'LogoAmilIcon',
        title: (
          <Trans
            t={t}
            i18nKey={t('benefit.operation.create.approved.title')}
            components={{ bold: <span className="bold" /> }}
          />
        ),
        description: (
          <Trans
            t={t}
            i18nKey={t('benefit.operation.create.approved.description')}
            components={{ bold: <span className="bold" /> }}
          />
        ),
        rightButtonText: t('benefit.operation.right.button.approved'),
        leftButtonText: CLOSE_BUTTON_TEXT,
      },
      DENIED: {
        icon: 'createDenied',
        title: (
          <Trans
            t={t}
            i18nKey={t('benefit.operation.create.denied.title')}
            components={{ bold: <span className="bold" /> }}
          />
        ),
        description: (
          <Trans
            t={t}
            i18nKey={t('benefit.operation.create.denied.description')}
            components={{ bold: <span className="bold" /> }}
          />
        ),
        rightButtonText: CLOSE_BUTTON_TEXT,
      },
    };

    return (
      statusData[benefitCreateOperation?.status ?? ''] || DEFAULT_MODAL_DATA
    );
  }

  function getDependantUpdateBenefitModalData(
    denomination: string
  ): ConfirmationModalDataType {
    if (denomination === AvailableBenefitsEnum.dentalAssistance) {
      const statusData: OperationModalData = {
        APPROVED: {
          icon: 'checkCircleFull',
          title: (
            <Trans
              t={t}
              i18nKey={t('dental.dependant.update.success.title')}
              components={{ bold: <span className="bold" /> }}
            />
          ),
          description: (
            <Trans
              t={t}
              i18nKey={t('dependant.update.success.description')}
              components={{ bold: <span className="bold" /> }}
            />
          ),
          rightButtonText: t('dental.dependant.update.success.right.button'),
          leftButtonText: CLOSE_BUTTON_TEXT,
        },
        DENIED: {
          icon: 'errorIcon',
          title: (
            <Trans
              t={t}
              i18nKey={t('dental.dependant.update.denied.title')}
              components={{ bold: <span className="bold" /> }}
            />
          ),
          description: (
            <Trans
              t={t}
              i18nKey={t('dependant.update.denied.description')}
              components={{ bold: <span className="bold" /> }}
            />
          ),
          rightButtonText: CLOSE_BUTTON_TEXT,
        },
      };

      return (
        statusData[dependantBenefitUpdateOperation?.status ?? ''] ||
        DEFAULT_MODAL_DATA
      );
    }

    const statusData: OperationModalData = {
      APPROVED: {
        icon: 'checkCircleFull',
        title: (
          <Trans
            t={t}
            i18nKey={t('medical.dependant.update.success.title')}
            components={{ bold: <span className="bold" /> }}
          />
        ),
        description: (
          <Trans
            t={t}
            i18nKey={t('dependant.update.success.description')}
            components={{ bold: <span className="bold" /> }}
          />
        ),
        rightButtonText: t('medical.dependant.update.success.right.button'),
        leftButtonText: CLOSE_BUTTON_TEXT,
      },
      DENIED: {
        icon: 'errorIcon',
        title: (
          <Trans
            t={t}
            i18nKey={t('medical.dependant.update.denied.title')}
            components={{ bold: <span className="bold" /> }}
          />
        ),
        description: (
          <Trans
            t={t}
            i18nKey={t('dependant.update.denied.description')}
            components={{ bold: <span className="bold" /> }}
          />
        ),
        rightButtonText: CLOSE_BUTTON_TEXT,
      },
    };

    return (
      statusData[dependantBenefitUpdateOperation?.status ?? ''] ||
      DEFAULT_MODAL_DATA
    );
  }

  function getBenefitUpdateModalData(
    denomination: string
  ): ConfirmationModalDataType {
    if (denomination === AvailableBenefitsEnum.dentalAssistance) {
      const statusData: OperationModalData = {
        APPROVED: {
          icon: 'LogoAmilIcon',
          title: (
            <Trans
              t={t}
              i18nKey={`${t(
                'benefit.update.success.modal.dental.title'
              )} <bold>${benefitUpgradeOperation?.operationData?.name}!</bold>`}
              components={{ bold: <span className="bold" /> }}
            />
          ),
          description: (
            <Trans
              t={t}
              i18nKey={t('benefit.update.success.modal.description')}
              components={{ bold: <span className="bold" /> }}
            />
          ),
          rightButtonText: t('benefit.operation.right.button.approved'),
          leftButtonText: CLOSE_BUTTON_TEXT,
        },
        DENIED: {
          icon: 'errorIcon',
          title: (
            <Trans
              t={t}
              i18nKey={t('benefit.update.error.modal.dental.title')}
              components={{ bold: <span className="bold" /> }}
            />
          ),
          description: (
            <Trans
              t={t}
              i18nKey={t('benefit.update.error.modal.description')}
              components={{ bold: <span className="bold" /> }}
            />
          ),
          rightButtonText: CLOSE_BUTTON_TEXT,
        },
      };

      return (
        statusData[benefitUpgradeOperation?.status ?? ''] || DEFAULT_MODAL_DATA
      );
    }

    const statusData: OperationModalData = {
      APPROVED: {
        icon: 'LogoAmilIcon',
        title: (
          <Trans
            t={t}
            i18nKey={`${t(
              'benefit.update.success.modal.medical.title'
            )} <bold>${benefitUpgradeOperation?.operationData?.name}!</bold>`}
            components={{ bold: <span className="bold" /> }}
          />
        ),
        description: (
          <Trans
            t={t}
            i18nKey={t('benefit.update.success.modal.description')}
            components={{ bold: <span className="bold" /> }}
          />
        ),
        rightButtonText: t('benefit.operation.right.button.approved'),
        leftButtonText: CLOSE_BUTTON_TEXT,
      },
      DENIED: {
        icon: 'errorIcon',
        title: (
          <Trans
            t={t}
            i18nKey={t('benefit.update.error.modal.medical.title')}
            components={{ bold: <span className="bold" /> }}
          />
        ),
        description: (
          <Trans
            t={t}
            i18nKey={t('benefit.update.error.modal.description')}
            components={{ bold: <span className="bold" /> }}
          />
        ),
        rightButtonText: CLOSE_BUTTON_TEXT,
      },
    };

    return (
      statusData[benefitUpgradeOperation?.status ?? ''] || DEFAULT_MODAL_DATA
    );
  }

  if (loading) {
    return <MyBenefitsLoader />;
  }

  const goToManage = (status: OperationStatus, denomination?: string) => {
    const benefit = benefits.find(
      (item) => item.denominationBenefit === denomination
    );

    if (benefit && status === BenefitStatus.approved) {
      dispatch(
        benefitManage({
          ...benefitToManage,
          benefit,
        })
      );
      navigate('benefit/plan/edit');
    }
  };

  return (
    <>
      <div className="my-benefits">
        <div className="header">
          <h2 className="title">{t('my.benefits.title')}</h2>
          <p className="description">{t('my.benefits.description')}</p>
        </div>
        {pendingOperation && <PendingOperationBanner />}

        <ProgressBarBenefits />
        <h3 className="benefits-type">{t('my.benefits.active.benefits')}</h3>
        <ActiveBenefit
          benefits={benefits}
          operation={dependantBenefitUpdateOperation}
        />
        <BasketBenefits benefits={benefits} />
        <h3 className="benefits-type">{t('my.benefits.benefits.to.add')}</h3>
        <AvailableBenefits
          operation={benefitCancelOperation || benefitCreateOperation}
        />
      </div>
      <AdditionalBenefits />

      {renderConfirmationModal(
        () => toggleOperationVisibility(benefitCreateOperation),
        () => {
          toggleOperationVisibility(benefitCreateOperation);
          goToManage(
            benefitCreateOperation?.status || '',
            AvailableBenefitsEnum.dentalAssistance
          );
        },
        !!benefitCreateOperation && openOperationModal && !!isActive,
        getCreateBenefitModalData()
      )}

      {renderConfirmationModal(
        () => toggleOperationVisibility(benefitCancelOperation),
        () => toggleOperationVisibility(benefitCancelOperation),
        !!benefitCancelOperation && openOperationModal && !!isActive,
        getCancelBenefitModalData()
      )}

      {renderConfirmationModal(
        () => toggleOperationVisibility(dependantBenefitUpdateOperation),
        () => {
          toggleOperationVisibility(dependantBenefitUpdateOperation);
          goToManage(
            dependantBenefitUpdateOperation?.status || '',
            dependantBenefitUpdateOperation?.operationData?.denomination
          );
        },
        !!dependantBenefitUpdateOperation && openOperationModal && !!isActive,
        getDependantUpdateBenefitModalData(
          dependantBenefitUpdateOperation?.operationData?.denomination || ''
        )
      )}

      {renderConfirmationModal(
        () => toggleOperationVisibility(benefitUpgradeOperation),
        () => {
          toggleOperationVisibility(benefitUpgradeOperation);
          goToManage(
            benefitUpgradeOperation?.status || '',
            benefitUpgradeOperation?.operationData?.denomination
          );
        },
        !!benefitUpgradeOperation && openOperationModal && !!isActive,
        getBenefitUpdateModalData(
          benefitUpgradeOperation?.operationData?.denomination || ''
        )
      )}
    </>
  );
}

export default Benefits;
