/* eslint-disable import/prefer-default-export */
export const customStylesDefault = (
  hasError: any,
  shouldDisable: boolean = false,
  wrap: boolean = false,
  width: string = '100%'
) => ({
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: '20px',
    height: '55px',
    display: 'flex',
    alignItems: 'center',
    padding: 10,
    backgroundColor: state.isFocused ? 'rgba(18, 18, 18, 0.08)' : '',
    color: 'var(--color-black-standard)',
  }),
  control: () => ({
    width,
    height: '56px',
    backgroundColor: shouldDisable ? '#FFFFFF' : '#FFFFFF',
    border: hasError
      ? '1px solid #e25c5a'
      : '1px solid var(--opacity-black-53)',
    borderRadius: '8px',
    display: 'flex',
    paddingRight: 10,
    justifyContent: 'space-between',
    caretColor: 'transparent',

    '&:hover': {
      border: '1px solid #f58220',
    },
  }),

  singleValue: (provided: any, state: any) => ({
    whiteSpace: provided.whiteSpace,
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    '@media (min-width: 1200px)': {
      fontSize: 18,
    },
  }),

  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    color: '#f58220',
    paddingTop: 10,
    transition: 'all .2s ease',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',

    '&:hover': {
      color: '#f58220',
    },
  }),

  indicatorSeparator: (provided: any, state: any) => ({
    background: 'none',
  }),

  menu: (provided: any, state: any) => ({
    ...provided,
    borderRadius: '8px',
    width: '100%',
  }),

  valueContainer: (provided: any, state: any) => ({
    display: 'flex',
    justifyContainer: 'space-between',
    alignItems: 'center',
    paddingLeft: 10,
    width: 250,
    lineHeight: '120%',
  }),
});
