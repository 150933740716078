import AppButton from 'components/AppButton';
import React, { useEffect, useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import benefitService from 'services/BenefitService';
import Benefit from 'types/models/Benefit';
import { useTranslation } from 'react-i18next';
import AdditionalBenefitsModal from '../AdditionalBenefitsModal';
import './style.scss';

function AdditionalBenefits() {
  const { t } = useTranslation('benefit');
  const [benefits, setBenefits] = useState<Benefit[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState({
    id: 0,
    icon: 'errorIcon',
    name: `${t('life.insurance')}`,
    subtitle: `${t('employees.are.entitled')}`,
    description: '',
  });

  useEffect(() => {
    let isMounted = true;

    benefitService.getAdditional().then((response) => {
      if (isMounted) {
        setBenefits(response.data);
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  const openBenefitModal = (benefit: Benefit) => {
    setModalData({
      id: benefit.id,
      icon: benefit.urlImage,
      name: benefit.name,
      description: benefit.description,
      subtitle: benefit.subtitle,
    });
    setOpenModal(true);
  };

  return (
    <div
      className="additional-benefits-container"
      data-testid="additional-benefits-component"
    >
      <div className="header">
        <h2>{t('more.benefits')}</h2>
        <p>{t('understand.benefits')}</p>
      </div>
      <Row>
        {benefits.map((benefit: Benefit, idx: number) => (
          <Col xs={4} key={idx.valueOf()}>
            <div className="benefit-card">
              <Image
                src={benefit.urlImage}
                alt="img-benefit"
                className="card-image"
              />
              <div className="text-container">
                <h2 className="card-title">{benefit.name}</h2>
                <span className="card-description">{benefit.subtitle}</span>
              </div>
              <AppButton
                className="card-button"
                onClick={() => openBenefitModal(benefit)}
                outline
              >
                {t('know.more')}
              </AppButton>
            </div>
          </Col>
        ))}
      </Row>
      <AdditionalBenefitsModal
        data-testid="modal"
        open={openModal}
        showFooter={openModal}
        data={modalData}
        buttonText={t('back.to.benefits')}
        handleConfirm={() => {
          setOpenModal(false);
        }}
        close={() => {
          setOpenModal(false);
        }}
      />
    </div>
  );
}

export default AdditionalBenefits;
