import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Card, Row, Col } from 'react-bootstrap';
import Icon from 'components/Icon';
import AppButton from 'components/AppButton';
import BenefitService from 'services/BenefitService';
import AuthContext from 'configs/context';
import { benefitManage } from 'store/user/actions';
import {
  AllowedUpgradeBenefit,
  BasketBenefit,
  BenefitAmounts,
} from 'types/models/Benefit';
import GenericTooltipMessage from 'components/Shared/GenericTooltipMessage';
import ConfirmationModal from 'components/Shared/ConfirmationModal';
import './styles.scss';
import PendingOperationBanner from 'components/Shared/PendingOperationBanner';

export type BenefitManageProps = {
  numberOfDependants: number;
  hideUpgrade: boolean;
  totalAmount: BenefitAmounts;
  basketRedistribution: BasketBenefit[];
  upgradeAllowed: AllowedUpgradeBenefit[];
};

function BenefitManage({
  numberOfDependants,
  hideUpgrade,
  totalAmount,
  basketRedistribution,
  upgradeAllowed,
}: BenefitManageProps) {
  const { t } = useTranslation('benefit');
  const navigate = useNavigate();
  const {
    dispatch,
    state: { benefitToManage, pendingOperation },
  } = useContext(AuthContext);
  const [openNoDependantModal, setOpenNoDependantModal] = useState(false);

  const denomination = benefitToManage.benefit.denominationBenefit;
  const allowed =
    upgradeAllowed
      .filter((u) => u.denomination === denomination)
      .map((u) => u.upgradeAllowed)[0] || false;

  const noDependantsModalData = {
    rightButtonText: t('modal.add.dependant.button'),
    leftButtonText: t('back.button'),
    icon: 'warning',
    title: t('benefit.manage.no.dependants.warning.modal.title'),
    description: t('benefit.manage.no.dependants.warning.modal.description'),
  };

  const dentalAssistHeaderData = {
    firstCardTitle: t('benefit.manage.dental.assist.solicitation'),
    firstCardIcon: 'toothIcon',
    firstCardDescription: t('benefit.manage.dental.assist.solicitation.info'),
    secondCardTitle: t('benefit.manage.dental.assist.dependant'),
    thirdCardDescription: t('benefit.manage.dental.assist.upgrade.info'),
  };

  const medicalAssistHeaderData = {
    firstCardTitle: t('benefit.manage.medical.assist.solicitation'),
    firstCardIcon: 'emergency',
    firstCardDescription: t('benefit.manage.medical.assist.solicitation.info'),
    secondCardTitle: t('benefit.manage.medical.assist.dependant'),
    thirdCardDescription: t('benefit.manage.medical.assist.upgrade.info'),
  };

  function setBenefitData() {
    let benefitData = {
      firstCardTitle: '',
      firstCardIcon: '',
      firstCardDescription: '',
      secondCardTitle: '',
      thirdCardDescription: '',
    };
    if (
      benefitToManage.benefit.denominationBenefit === 'ASSISTENCIA_ODONTOLOGICA'
    ) {
      benefitData = dentalAssistHeaderData;
    }
    if (benefitToManage.benefit.denominationBenefit === 'ASSISTENCIA_MEDICA') {
      benefitData = medicalAssistHeaderData;
    }
    return benefitData;
  }

  const handleCancel = () => {
    dispatch(
      benefitManage({
        ...benefitToManage,
        totalAmount,
        benefitsToRedistribute: basketRedistribution,
      })
    );
    navigate('/benefit/cancel');
  };

  const handleManageDependants = () => {
    if (numberOfDependants !== 0) {
      navigate('/benefit/manage-dependants');
    } else {
      setOpenNoDependantModal(true);
    }
  };

  return (
    <div className="benefit-manage-container">
      <h1 className="header">{t('benefit.manage.card')}</h1>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              {benefitToManage.benefit.denominationBenefit ===
                'ASSISTENCIA_MEDICA' && <Icon name="emergency" />}
              {benefitToManage.benefit.denominationBenefit ===
                'ASSISTENCIA_ODONTOLOGICA' && <Icon name="toothIcon" />}
              <h3 className="title">{setBenefitData().firstCardTitle}</h3>
              <p className="description">
                {setBenefitData().firstCardDescription}
              </p>
            </Card.Body>
            <div className="footer">
              <AppButton
                className="cancel-button"
                type="button"
                disabled={pendingOperation || benefitToManage.benefit.mandatory}
                onClick={() => {
                  handleCancel();
                }}
              >
                {benefitToManage.benefit.mandatory
                  ? t('benefit.manage.plan.mandatory')
                  : t('benefit.manage.plan.cancel')}
              </AppButton>
            </div>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <Icon name="groupAddOutline" />
              <h3 className="title">{setBenefitData().secondCardTitle}</h3>
              <p className="description">{t('benefit.manage.dependant.add')}</p>
            </Card.Body>
            <div className="footer">
              <AppButton
                disabled={pendingOperation}
                className="manage-dependants"
                onClick={handleManageDependants}
              >
                {t('benefit.dependant.manage')}
              </AppButton>
            </div>
          </Card>
        </Col>
        <Col>
          {!hideUpgrade && (
            <Card>
              <Card.Body>
                <Icon name="timePlanChange" />
                <h3 className="title">{t('benefit.manage.upgrade')}</h3>
                <p className="description">
                  {setBenefitData().thirdCardDescription}
                </p>
              </Card.Body>
              <div className="footer">
                <AppButton
                  className={
                    !allowed ? 'update-benefit' : 'update-benefit full'
                  }
                  disabled={!allowed || pendingOperation}
                  onClick={() => {
                    navigate('/benefit/plan/upgrade');
                  }}
                >
                  {allowed
                    ? t('benefit.manage.plan.solicitation.allowed')
                    : t('benefit.manage.plan.solicitation')}
                </AppButton>
                {!allowed && (
                  <div className="alert-tooltip">
                    <GenericTooltipMessage
                      placement="right-end"
                      type="alert-tooltip"
                      icon="attention"
                      title={t('tooltip.update.plan.title')}
                      message={t('tooltip.update.plan.description')}
                    />
                  </div>
                )}
              </div>
            </Card>
          )}
        </Col>
      </Row>
      {pendingOperation && <PendingOperationBanner />}
      <ConfirmationModal
        data={noDependantsModalData}
        open={openNoDependantModal}
        showLeftButton
        close={() => {
          setOpenNoDependantModal(false);
        }}
        handleConfirm={() => {
          setOpenNoDependantModal(false);
          navigate('/dependant/add');
        }}
      />
    </div>
  );
}

export default BenefitManage;
