import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { Col, Row } from 'react-bootstrap';
import { useForm, UseFormReturn } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AddDependantHeader from 'components/Dependants/AddDependant/AddDependantHeader';
import DependantForm from 'components/Dependants/AddDependant/DependantForm';
import DependantAttachments from 'components/Dependants/DependantAttachments';
import { steps } from 'types/common/AddDependantStep';
import DependantBenefits from 'components/Dependants/DependantBenefits';
import * as FormModel from 'components/Dependants/models';
import {
  FORM_SCHEMA_PROFILE,
  FORM_SCHEMA_ATTACHMENT,
} from 'components/Dependants/validation';
import AppButton from 'components/AppButton';
import ConfirmationBackModal from 'components/Shared/ConfirmationBackModal';
import RedistributeModal from 'components/Shared/Redistribute/RedistributeModal';
import {
  BasketBenefit,
  BenefitAmounts,
  EmployeeFixedBenefit,
} from 'types/models/Benefit';
import benefitService from 'services/BenefitService';
import DependantService from 'services/DependantService';
import { CreateDependantRequest } from 'types/models/Dependant';
import validationService from 'services/ValidationService';
import ModalWithLoading from 'components/Shared/ModalWithLoading';
import { isConjugeFilesValid } from './Validation';
import BenefitGracePeriodModal from './BenefitGracePeriodModal';
import ModalWithLoadingTrans from '../../components/Shared/ModalWithLoadingTrans';
import './styles.scss';

type DependantAddTestMock = {
  stepIndexTest?: number;
};

type AttachmentResponse = {
  document: string;
  field: string;
};

type DependantAttachmentsType = 'ID' | 'CERTIFICATE';

function DependantAdd({ stepIndexTest = 0 }: DependantAddTestMock) {
  const isTotalWithoutAlelo = true;
  const navigate = useNavigate();
  const { t } = useTranslation('dependant');
  const [stepIndex, setStepIndex] = useState(stepIndexTest);
  const step = steps[stepIndex];
  const [totalAmount, setTotalAmount] = useState<BenefitAmounts>({
    total: 0,
    used: 0,
  });
  const [dependantBenefits, setDependantBenefits] = useState<
    Array<EmployeeFixedBenefit>
  >([]);
  const [basketRedistribution, setBasketRedistribution] = useState<
    Array<BasketBenefit>
  >([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [removingFile, setRemovingFile] = useState(false);
  const [validBenefits, setValidBenefits] = useState(false);
  const [openBackModal, setOpenBackModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openRedistributeModal, setOpenRedistributeModal] = useState(false);
  const [openExcludeAttachment, setOpenExcludeAttachment] = useState(false);
  const [loadingExcludeAttachment, setLoadingExcludeAttachment] =
    useState(false);
  const [backStep, setBackStep] = useState(false);
  const [dataConfirmationModal, setDataConfirmationModal] = useState({
    type: '',
    icon: '',
    title: '',
    description: '',
    rightButtonText: '',
  });
  const [attachmentsResponse, setAttachmentsResponse] = useState<
    AttachmentResponse[]
  >([]);

  const [dataExcludeAttachment, setDataExcludeAttachment] = useState({
    title: '',
    description: '',
    rightButtonText: '',
    icon: '',
  });

  const modalData = {
    leftButtonText: t('modal.info.close.button'),
    rightButtonText: t('modal.info.confirm.button'),
  };

  const formMethodsProfile: UseFormReturn<FormModel.DependantProfile> =
    useForm<FormModel.DependantProfile>({
      resolver: yupResolver(FORM_SCHEMA_PROFILE),
      mode: 'onBlur',
      defaultValues: {
        cpf: '',
        birthdate: '',
        name: '',
        kinship: '',
      },
    });

  const formMethodsAttachment: UseFormReturn<FormModel.DependantAttachment> =
    useForm<FormModel.DependantAttachment>({
      resolver: yupResolver(FORM_SCHEMA_ATTACHMENT),
      mode: 'onBlur',
      defaultValues: {
        ID: '',
        CERTIFICATE: '',
      },
    });

  const dependantForms = [formMethodsProfile, formMethodsAttachment];

  const dependantBenefitsValidation = (validation: boolean) => {
    setValidBenefits(validation);
  };

  const calculateSelected = (): number => {
    const dynamicValue = Object.values(dependantBenefits).reduce(
      (acc: number, s) => {
        if (s.selected) {
          const { valueDependant } = s;
          return acc + (valueDependant || 0);
        }

        return acc;
      },
      0
    );

    return dynamicValue;
  };

  const amount: BenefitAmounts = {
    total: parseFloat(totalAmount.total.toFixed(2)),
    used: parseFloat((totalAmount.used + calculateSelected()).toFixed(2)),
  };

  const renderStep = () => {
    const formMap: Partial<Record<string, JSX.Element>> = {
      profile: (
        <DependantForm
          loading={loading}
          errorMessage={errorMessage}
          validCPF={debounceisValidCPF}
          formMethods={formMethodsProfile}
        />
      ),
      attachment: (
        <DependantAttachments
          setAttachmentsResponse={setAttachmentsResponse}
          attachmentsResponse={attachmentsResponse}
          isRemoving={removingFile}
          setFilesToRemove={setRemovingFile}
          formMethods={formMethodsAttachment}
          profileInfos={formMethodsProfile.getValues()}
        />
      ),
      benefits: (
        <DependantBenefits
          totalAmount={amount}
          benefits={dependantBenefits}
          setBenefits={setDependantBenefits}
          dependant={formMethodsProfile.getValues()}
          dependantBenefitsValidation={dependantBenefitsValidation}
        />
      ),
    };
    return formMap[step.name];
  };

  const handleErrorMessage = (message: string) => {
    const messageMap: Record<string, string> = {
      'document - The document is invalid': t('error.message.CPF.invalid'),
      'document - The Employee has the same document': t(
        'error.message.CPF.employee'
      ),
      'document - There is dependant with the same document': t(
        'error.message.CPF.dependant'
      ),
    };
    return messageMap[message];
  };

  const validCPF = async (cpf: string): Promise<boolean> => {
    if (cpf.length !== 14) {
      return false;
    }
    try {
      setLoading(true);
      await validationService.getValidCPF(cpf).then(() => setErrorMessage(''));
      return true;
    } catch (error: any) {
      setErrorMessage(handleErrorMessage(error.response.data.details[0]));
      return false;
    } finally {
      setLoading(false);
    }
  };

  const debounceisValidCPF = debounce(validCPF, 300);

  const handleBack = async () => {
    if (step.name === 'benefits') {
      setStepIndex(stepIndex - 1);
    }

    if (step.name === 'profile' || backStep === false) {
      navigate('/dependant');
    }

    if (step.name === 'attachment' && !removingFile) {
      setLoadingExcludeAttachment(true);
      setOpenExcludeAttachment(true);
      setDataExcludeAttachment({
        icon: '',
        title: t('removingData.title'),
        description: t('modal.loading.description'),
        rightButtonText: '',
      });
      attachmentsValidation();
    }

    setBackStep(false);
  };

  const attachmentsValidation = () => {
    const attachments = ['ID', 'CERTIFICATE'];
    attachments.forEach((attachment) => {
      if (
        formMethodsAttachment.getValues(attachment as DependantAttachmentsType)
      ) {
        const config = {
          params: {
            name: formMethodsAttachment.getValues(
              attachment as DependantAttachmentsType
            ),
          },
        };

        DependantService.deleteAttachment({
          config,
          handleThen: () => {
            setLoadingExcludeAttachment(false);
            setOpenExcludeAttachment(false);
            setAttachmentsResponse([]);
            formMethodsAttachment.reset();
            formMethodsAttachment.clearErrors(
              attachment as DependantAttachmentsType
            );
            formMethodsAttachment.trigger(
              attachment as DependantAttachmentsType
            );
            setStepIndex(stepIndex - 1);
          },
          handleCatch: () => {
            setStepIndex(stepIndex);
            setLoadingExcludeAttachment(false);
            setDataExcludeAttachment({
              icon: 'errorIcon',
              title: t('modal.error.step.back'),
              description: t('try.deleting.files'),
              rightButtonText: t('modal.back.button'),
            });
          },
        });
      }
    });
  };

  const handleNext = () => {
    if (!step.nextStep) {
      navigate('/');
    } else if (dependantForms[stepIndex]) {
      dependantForms[stepIndex].handleSubmit((_) => {
        setStepIndex(stepIndex + 1);
      })();
    }
  };

  const getNextButtonLabel = () => {
    if (!step.nextStep) {
      return t('button.label.send.solicitation');
    }
    return t('button.label.next');
  };

  const isFormValid = () => {
    const kinship = formMethodsProfile.getValues('kinship');
    const idFileName = formMethodsAttachment.getValues('ID');
    const certificateFileName = formMethodsAttachment.getValues('CERTIFICATE');

    if (loading || removingFile) return false;

    if (
      dependantForms[stepIndex] &&
      isConjugeFilesValid(
        idFileName,
        kinship,
        stepIndex,
        certificateFileName
      ) === true
    ) {
      return dependantForms[stepIndex].formState.isValid;
    }

    return false;
  };

  function disabled() {
    if (step.name === 'benefits') {
      return !validBenefits;
    }

    return !isFormValid() || errorMessage !== '';
  }

  const checkConfirmationModalOpen = () => {
    if (dependantBenefits.filter((b) => b.selected === true).length === 0) {
      confirmRedistributeModal([]);
    } else {
      setOpenModal(true);
    }
  };

  useEffect(() => {
    benefitService.getDynamicBenefits().then((response) => {
      const formattedBenefits = response.data.map((b) => ({
        ...b,
        value: 0,
      }));

      setBasketRedistribution(formattedBenefits);
    });

    benefitService.getAmounts(isTotalWithoutAlelo).then((response) => {
      setTotalAmount(response.data);
    });

    benefitService.getEmployeeBenefits().then((response) => {
      setDependantBenefits(response.data);
    });
  }, []);

  async function confirmRedistributeModal(
    confirmedBenefits: Array<BasketBenefit>
  ) {
    setLoading(true);
    setOpenConfirmationModal(true);
    setOpenRedistributeModal(false);
    setDataConfirmationModal({
      ...dataConfirmationModal,
      title: t('modal.loading.title'),
      description: t('modal.loading.description'),
    });

    const birthdate = formMethodsProfile.getValues('birthdate');

    const benefitSelected = dependantBenefits.filter(
      (b) => b.selected === true
    );

    const getformValues = Object.keys(formMethodsAttachment.getValues());

    const attachments: Record<string, any> = {};

    if (attachmentsResponse[0]?.field) {
      attachments[attachmentsResponse[0]?.field] = {
        type: attachmentsResponse[0]?.field,
        storageName: attachmentsResponse[0]?.document || '',
        originalName:
          attachmentsResponse[0]?.field.toLowerCase() ===
          getformValues[0].toLowerCase()
            ? formMethodsAttachment.getValues('ID') || ''
            : formMethodsAttachment.getValues('CERTIFICATE') || '',
      };
    }

    if (attachmentsResponse[1]?.field) {
      attachments[attachmentsResponse[1]?.field] = {
        type: attachmentsResponse[1]?.field || '',
        storageName: attachmentsResponse[1]?.document || '',
        originalName:
          attachmentsResponse[1]?.field.toLowerCase() ===
          getformValues[1].toLowerCase()
            ? formMethodsAttachment.getValues('CERTIFICATE') || ''
            : formMethodsAttachment.getValues('ID') || '',
      };
    }

    const createRequest: CreateDependantRequest = {
      dependant: {
        name: formMethodsProfile.getValues('name'),
        attachments,
        birthdate,
        document: formMethodsProfile.getValues('cpf'),
        includeIR: formMethodsProfile.getValues('includeIR'),
        kinship: formMethodsProfile.getValues('kinship'),
        benefits: benefitSelected.map((b) => b.id),
      },
      benefitsRedistribution: [],
    };

    confirmedBenefits.forEach((benefit) => {
      createRequest.benefitsRedistribution.push({
        idBenefit: benefit.id,
        value: benefit.value,
      });
    });

    await DependantService.createDependant(createRequest)
      .then(() => {
        setLoading(false);
        setDataConfirmationModal({
          type: 'success',
          icon: 'sentSuccess',
          title: t('modal.success.title'),
          description: t('modal.success.description'),
          rightButtonText: t('modal.success.confirm.button'),
        });
      })
      .catch(() => {
        setLoading(false);
        setDataConfirmationModal({
          type: 'error',
          icon: 'errorIcon',
          title: t('modal.error.title'),
          description: t('modal.error.description'),
          rightButtonText: t('modal.info.back.button'),
        });
      });
  }

  return (
    <div className="add-dependant-container main-padding">
      <Row>
        <Col>
          <AddDependantHeader
            step={step}
            isRemoving={removingFile}
            setOpenBackModal={setOpenBackModal}
          />
        </Col>
      </Row>
      <form className="add-dependant-form">
        <Row>
          <Col>
            <h2>{t(`add.form.${step.name}.title`)}</h2>
            <div className="desc">
              <h4>{t(`add.form.${step.name}.description`)}</h4>
              {step.required && <span>{t('add.form.required')}</span>}
            </div>
          </Col>
        </Row>
        {renderStep()}
        <Row className="add-dependant-buttons">
          <Col>
            {step.name !== 'profile' ? (
              <AppButton
                className={`button ${removingFile ? 'disabled' : ''}`}
                onClick={() => {
                  if (!removingFile) {
                    setBackStep(true);
                    setOpenBackModal(true);
                  }

                  if (
                    step.name === 'attachment' &&
                    !removingFile &&
                    step.name === 'attachment' &&
                    formMethodsAttachment.getValues().ID === '' &&
                    formMethodsAttachment.getValues().CERTIFICATE === ''
                  ) {
                    setStepIndex(stepIndex - 1);
                    setOpenBackModal(false);
                    setBackStep(true);
                  }
                }}
              >
                {t('back.app.button.text')}
              </AppButton>
            ) : (
              ''
            )}
            <AppButton
              full
              onClick={() => {
                step.name === 'benefits'
                  ? checkConfirmationModalOpen()
                  : handleNext();
              }}
              data-testid="nextButton"
              disabled={disabled()}
            >
              {getNextButtonLabel()}
            </AppButton>
          </Col>
        </Row>
      </form>
      <ConfirmationBackModal
        handleConfirm={() => {
          setOpenBackModal(false);
        }}
        handleClose={() => {
          handleBack();
          setOpenBackModal(false);
        }}
        open={openBackModal}
      />
      <BenefitGracePeriodModal
        open={openModal}
        data={modalData}
        showLeftButton={openModal}
        handleConfirm={() => {
          setOpenModal(false);
          setOpenRedistributeModal(true);
        }}
        close={() => setOpenModal(false)}
      />
      <RedistributeModal
        disableConfirm={amount.total > amount.used}
        totalAmount={amount}
        benefits={basketRedistribution}
        open={openRedistributeModal}
        setBenefits={setBasketRedistribution}
        close={() => {
          const formattedBenefits = basketRedistribution.map((b) => ({
            ...b,
            value: 0,
          }));

          setOpenRedistributeModal(false);
          setBasketRedistribution(formattedBenefits);
        }}
        confirm={(confirmedBenefits) =>
          confirmRedistributeModal(confirmedBenefits)
        }
      />
      <ModalWithLoadingTrans
        loading={loading}
        showFooter={!loading}
        data={dataConfirmationModal}
        open={openConfirmationModal}
        contextTranslation="dependant"
        close={() => setOpenConfirmationModal(false)}
        handleConfirm={() => {
          if (dataConfirmationModal.type === 'success') {
            navigate('/dependant');
            setOpenConfirmationModal(false);
          } else if (
            dependantBenefits.filter((b) => b.selected === true).length === 0
          ) {
            setOpenRedistributeModal(false);
            setOpenConfirmationModal(false);
          } else {
            setOpenRedistributeModal(true);
            setOpenConfirmationModal(false);
          }
        }}
      />

      <ModalWithLoading
        showFooter={!loadingExcludeAttachment}
        loading={loadingExcludeAttachment}
        close={() => {}}
        handleConfirm={() => {
          setOpenExcludeAttachment(false);
        }}
        open={openExcludeAttachment}
        data={dataExcludeAttachment}
      />
    </div>
  );
}

export default DependantAdd;
