import api from 'configs/api';
import { ProfileResponse, ProfileResume } from 'types/models/Profile';

async function get() {
  return api.get<ProfileResponse>('/profile');
}

async function getProfileResume() {
  return api.get<ProfileResume>('/profile/resume');
}

const ProfileService = { get, getProfileResume };

export default ProfileService;
