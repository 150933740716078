const masks = {
  mobileEightNumbers(value: string) {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{4})(\d+?)$/, '$1');
  },

  mobileNineNumbers(value: string) {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{4})(\d+?)$/, '$1');
  },

  phone(value: string) {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{4})(\d+?)$/, '$1');
  },

  date(value: string) {
    return value

      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\/\d{4})(\d+?)$/, '$1');
  },

  cep(value: string) {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{3})(\d+?)$/, '$1');
  },

  number(value: string) {
    return value.replace(/\D/g, '');
  },

  houseNumber(value: string) {
    return value.replace(/[^\d-]+$/g, '');
  },

  docNumber(value: string) {
    return value.replace(/[^0-9.-]+/g, '');
  },

  RGDocNumber(value: string) {
    return value.replace(/[^\d-\.x]+/g, '');
  },

  RNENumber(value: string) {
    return value.replace(/[^\d\.]+/g, '');
  },

  numberAndLetters(value: string) {
    return value.replace(/[^a-zA-Z0-9]*$/g, '');
  },

  numberAndLettersAndHyphen(value: string) {
    return value.replace(/[^a-zA-Z0-9-]*$/g, '');
  },

  cpf(value: string) {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1-$2')
      .replace(/(-\d{2})(\d+?)$/, '$1');
  },

  cnpj(value: string) {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})(\d+?)$/, '$1');
  },

  currency(value: number) {
    if (value == null) return '';
    return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumIntegerDigits: 1,
    });
  },

  brazilianCurrencyFormat(value: number) {
    return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumIntegerDigits: 1,
    });
  },

  onlyLetters(value: string) {
    return value.replace(/[\d]/g, '');
  },

  currencyWithoutStyle(value: number) {
    return (value / 100).toLocaleString('pt-BR', {
      minimumIntegerDigits: 1,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  },
};

export default masks;
