import React from 'react';
import { useContractType } from 'hooks/useContractType';
import { Navigate, Outlet } from 'react-router-dom';

function DependantPrivateRoutes() {
  const contractType = useContractType();

  return contractType === 'CLT' || contractType === 'INTERNSHIP' ? (
    <Outlet />
  ) : (
    <Navigate to="/profile" />
  );
}

export default DependantPrivateRoutes;
