import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import AppSkeleton from 'components/AppSkeleton';
import './styles.scss';

const commomMarginLeft = '10px';

function DependantDetailSkeleton() {
  return (
    <Container fluid className="dependant-detail-skeleton">
      <Row className="header-skeleton">
        <AppSkeleton xs={2} size="lg" />
      </Row>

      <Row className="profile-fields">
        <Col>
          <Row>
            <AppSkeleton xs={8} />
            <AppSkeleton xs={6} style={{ marginLeft: commomMarginLeft }} />
          </Row>
        </Col>
        <Col className="ms-auto text-end">
          <AppSkeleton xs={2} />
        </Col>

        <Col className="col-separator">
          <hr />
        </Col>
      </Row>

      <Row className="mt-4">
        <AppSkeleton xs={3} />
        <AppSkeleton xs={3} style={{ marginLeft: commomMarginLeft }} />

        <AppSkeleton xs={7} />
        <AppSkeleton xs={2} style={{ marginLeft: commomMarginLeft }} />

        <Col className="col-separator">
          <hr />
        </Col>
      </Row>

      <Row className="mt-4">
        <AppSkeleton xs={8} />
        <AppSkeleton xs={3} style={{ marginLeft: commomMarginLeft }} />

        <AppSkeleton xs={7} />

        <AppSkeleton xs={3} style={{ marginLeft: commomMarginLeft }} />

        <Col className="col-separator">
          <hr />
        </Col>
      </Row>

      <Row className="mt-4">
        <Col sm={3}>
          <AppSkeleton xs={11} />
        </Col>
        <AppSkeleton xs={12} style={{ marginLeft: commomMarginLeft }} />

        <AppSkeleton xs={12} />
        <Col className="col-separator">
          <hr />
        </Col>
      </Row>
    </Container>
  );
}

export default DependantDetailSkeleton;
