/* eslint-disable import/prefer-default-export */
export const BenefitTexts = {
  'pt-BR': {
    'edit.back': 'Voltar para benefícios',
    'dental.assist.edit.header.title': 'Assistência Odontológica',
    'dental.assist.edit.header.description':
      'Tenha o melhor plano dental para você e seus dependentes',
    'medical.assist.edit.header.title': 'Assistência Médica',
    'medical.assist.edit.header.description':
      'Você pode fazer o gerenciamento dos seus dependentes no plano Amil',
    'choose.back': 'voltar para benefícios',
    'choose.header.title': 'Solicitar Plano de Assistência Odontológica',
    'choose.header.description':
      'Siga as etapas e, ao finalizar, você deverá redistribuir a sua cesta de benefícios',
    'choose.form.required': '* Obrigatório',
    'choose.form.plans.title': 'Escolher o plano',
    'choose.form.plans.description':
      'Selecione qual a modalidade deseja e depois avance.',
    'choose.form.dependants.title': 'Adicionar dependentes',
    'choose.form.dependants.description':
      'Você pode adicionar seus dependentes no benefício, se não desejar, envie a solicitação.',
    'cancel.back': 'voltar para plano odontológico',
    'cancel.header.title': 'Cancelar Assistência Odontológica',
    'edit.basket.header.title': 'Benefícios Alelo',
    'edit.basket.header.description':
      'Gerencie os valores dos seus benefícios Alelo.',
    'edit.basket.manage.title': 'Gerenciar benefício',
    'edit.basket.change.values': 'Alterar os valores',
    'edit.basket.change.values.description':
      'Revise os novos valores antes do dia 15 do mês para receber no mesmo mês',
    'redistribute.values.button': 'Redistribuir cesta',
    'tooltip.title.delay':
      'Você alterou os valores da Alelo há menos de 3 meses',
    'tooltip.message.delay':
      'Será necessário aguardar o período para redistribuir a sua cesta novamente.',
    'tooltip.title.exceded':
      'Os planos da Amil somam o valor igual ou maior que a sua cesta!',
    'tooltip.message.exceded':
      'Os seus benefícios Alelo são zerados automaticamente e, se houver valor excedente, ele é descontado na sua folha de pagamento.',
    discount: 'Desconto em folha',

    'modal.intern.warning.close.btn': 'Fechar',
    'modal.intern.warning.title': 'Você não pode mais adicionar dependentes!',
    'modal.intern.warning.description':
      'Devido o valor do novo dependente ser superior ao valor disponível na sua cesta de benefícios. Colaboradores estagiários não podem ultrapassar sua cesta',
    'modal.success.title': 'Sua solicitação de plano odontológico foi enviada!',
    'modal.success.description':
      'A confirmação do novo plano será enviada para o seu e-mail em até 5 dias. Em caso de dúvidas, contate-nos em <bold>equipe.dp@iteris.com.br</bold>',
    'modal.success.confirm.button': 'Retornar para benefícios',
    'modal.error.title': 'Falha no envio da solicitação!',
    'modal.error.description': 'Ocorreu algum erro. Retorne e tente novamente!',
    'modal.info.back.button': 'Voltar',
    'modal.loading.title': 'Enviando solicitação...',
    'modal.loading.description': 'Aguarde!',
    'modal.intern.warning.choice.title':
      'O valor do plano ultrapassou a sua cesta!',
    'modal.intern.warning.choice.description':
      'O plano que você escolheu tem um valor maior que o valor disponível para sua cesta de benefícios. Colaboradores estagiários não podem ultrapassar sua cesta.',
    'send.solicitation.btn': 'Enviar solicitação',
    'next.button': 'Avançar',
    'back.button': 'Voltar',
    'stay.in.page.button': 'Permanecer na tela',

    'solicitate.plan.button': 'Solicitar plano',
    'add.basket.button': 'Adicionar à cesta',
    'using.all.benefits.title': 'Não há mais benefícios',
    'using.all.benefits.description':
      'Você está utilizando todos os benefícios eletivos',

    mandatory: 'Obrigatório',
    'show.actions': 'Exibir Ações',
    'actual.plan': 'Plano atual',
    'cost.benefit': 'Custo ao colaborador',
    'total.cost': 'Custo total de dependentes ',
    total: 'total',
    'total.basket': 'total da cesta',
    'amil.total.value': 'Valor utilizado pela Amil',
    'alelo.total.value': 'Valor disponível para Alelo',
    'dental.description': 'O que oferece?',
    'dental.title': 'Custo ao colaborador',
    'dental.dependant.cost': 'Custo por dependente',
    'dental.choose.plan': 'Selecionar plano',
    'back.to.benefits': 'Fechar',
    'benefit.operation.cancel.approved.title':
      'Sua solicitação de cancelar o plano odontológico foi <bold>aprovada!<bold>',
    'benefit.operation.cancel.approved.description':
      'Lembrando que para contratar novamente será necessário aguardar o término do período de 180 dias. Em caso de dúvidas, contate-nos em <bold>equipe.dp@iteris.com.br<bold>',

    'benefit.operation.cancel.denied.title':
      'Sua solicitação de cancelar o plano odontológico foi <bold>reprovada!</bold>',
    'benefit.operation.cancel.denied.description':
      'O seu plano odontológico continuará ativo. Em caso de dúvidas, contate-nos em <bold>equipe.dp@iteris.com.br<bold>',

    'benefit.operation.create.approved.title':
      'Agora você possui o <bold>plano odontológico!</bold>',
    'benefit.operation.create.approved.description':
      'Lembrando que é necessário aguardar o período de carência para utilizá-lo. Em caso de dúvidas, contate-nos em <bold>equipe.dp@iteris.com.br<bold>',
    'benefit.operation.right.button.approved': 'Ir para o benefício',
    'benefit.operation.close.button': 'Fechar',
    'benefit.manage': 'Gerenciar',
    'my.benefits.title': 'Benefícios',
    'my.benefits.description':
      'Visualize e gerencie os valores distribuídos da sua cesta de benefícios',
    'my.benefits.active.benefits': 'Benefícios ativos',
    'my.benefits.benefits.to.add': 'Benefícios para adicionar',

    'benefit.operation.create.denied.title':
      'A solicitação do plano odontológico foi <bold>reprovada!</bold>',
    'benefit.operation.create.denied.description':
      'Os detalhes foram enviados para o seu e-mail. Em caso de dúvidas, contate-nos em <bold>equipe.dp@iteris.com.br<bold>',
    'tooltip.pendency.title': 'Você já solicitou o plano!',
    'tooltip.pendency.message':
      'O processo está em andamento. Aguarde a finalização, você será avisado.',
    'more.benefits': 'Mais vantagens para você',
    'understand.benefits':
      'Entenda quais benefícios você tem ativos automaticamente sem nenhum custo adicional',
    'know.more': 'Saiba mais',
    'life.insurance': 'Seguro de vida',
    'employees.are.entitled':
      'Todos os colaboradores Iteris tem direito sem custo ao titular',
    'what.is.this.benefit': 'O que é este benefício?',
    'for.more.information': 'Para mais informações sobre este benefício',
    'send.an.email': 'Envie um e-mail para',
    'support.iteris': ' suporte@iteris.com.br',
    'answer.your.questions': 'e nós responderemos todas as suas dúvidas!',
    'view.values.food':
      'Visualize os valores do seu vale alimentação e refeição',
    'obs.label.title': 'Não encontrou o dependente desejado na lista?',
    'obs.label.description':
      'Se enviar a solicitação do plano odontológico, você deverá aguardar aprovação do plano para cadastrar o novo dependente e adicioná-lo no plano',
    'benefit.cancel.terms': 'Antes de confirmar, leia os termos:',
    'benefit.cancel.terms.first':
      'Você não poderá contratar o plano novamente por um período de 180 dias;',
    'benefit.cancel.terms.second':
      'Entraremos em contato com você por e-mail para prosseguir com o cancelamento;',
    'benefit.cancel.terms.third': 'O cancelamento é irreversível;.',
    'benefit.cancel.terms.fourth':
      'Será necessário fazer a redistribuição da sua cesta, que será automaticamente aplicada após a aprovação.',
    'benefit.cancel.checbock.text': 'estou ciente e desejo continuar',
    'benefit.cancel.confirm.button': 'Solicitar cancelamento',
    'benefit.manage.dental.assist.solicitation':
      'Você já possui o plano odontológico',
    'benefit.manage.dental.assist.solicitation.info':
      'Caso não queira mais o benefício, solicite o cancelamento abaixo',
    'benefit.manage.medical.assist.solicitation':
      'Você possui o plano médico Amil automaticamente',
    'benefit.manage.medical.assist.solicitation.info':
      'Oferecemos uma opção sem custo ao colaborador',
    'benefit.manage.info': 'Informações do benefício',
    'benefit.manage.card': 'Gerenciar benefício ',
    'benefit.manage.plan': 'Plano atual',
    'benefit.manage.plan.cancel': 'Cancelar plano',
    'benefit.manage.plan.mandatory': 'Benefício obrigatório',
    'benefit.manage.dental.assist.dependant':
      'Dependentes do Assistência Odontológica',
    'benefit.manage.medical.assist.dependant':
      'Dependentes do Assistência Médica',
    'benefit.manage.dependant.add':
      'Inclua ou exclua os seus dependentes cadastrados no benefício',
    'benefit.dependant.manage': 'Gerenciar dependentes',
    'benefit.manage.offer': 'O que ele oferece?',
    'benefit.manage.cost.details': 'Detalhes de custo',
    'benefit.manage.upgrade': 'Solicitar upgrade ou downgrade',
    'benefit.manage.dental.assist.upgrade.info':
      'Você poderá solicitar a mudança do seu plano odontológico',
    'benefit.manage.medical.assist.upgrade.info':
      'Você poderá solicitar a mudança do seu plano médico',
    'benefit.manage.plan.solicitation': 'Aguarde liberação',
    'benefit.manage.plan.solicitation.allowed': 'Alterar plano',
    'benefit.manage.dependant.cost': 'Custo total de dependentes',
    'benefit.manage.dependant.back.button.dental':
      'voltar para plano odontológico',
    'benefit.manage.dependant.header.title.dental':
      'Dependentes da Assistência Odontológica',
    'benefit.manage.dependant.back.button.medical': 'voltar para plano médico',
    'benefit.manage.dependant.header.title.medical':
      'Dependentes do Assistência Médica',
    'benefit.manage.dependant.header.description':
      'Adicione e/ou remova seus dependentes do benefício',
    'benefit.manage.dependant.modal.terms.title':
      'Antes de continuar, leia os termos!',
    'benefit.manage.dependant.modal.terms.1':
      'Prosseguiremos o processo com você por e-mail;',
    'benefit.manage.dependant.modal.terms.2':
      'Em casos de <bold>adição</bold> de dependentes, temos:',
    'benefit.manage.dependant.modal.terms.2.1':
      'O novo dependente poderá ter uma carência de 180 dias;',
    'benefit.manage.dependant.modal.terms.2.2':
      'A carência só não implicará caso a data do casamento ou nascimento não ultrapasse 30 dias antes da solicitação;',
    'benefit.manage.dependant.modal.terms.3':
      'Em casos de <bold>remoção</bold> de dependentes, temos:',
    'benefit.manage.dependant.modal.terms.3.1':
      'O dependente não poderá ser adicionado novamente por um período de 180 dias;',
    'benefit.manage.dependant.modal.terms.4':
      'Será necessário fazer a redistribuição da sua cesta, que será automaticamente aplicada após a aprovação;',
    'benefit.manage.dependant.modal.terms.5':
      'Ao confirmar, você concorda com os termos citados acima.',
    'benefit.manage.dependant.modal.confirm': 'Confirmar',
    'benefit.manage.dependant.modal.success.title':
      'Sua alteração nos dependentes foi enviada!',
    'benefit.manage.dependant.modal.success.description':
      'A confirmação da sua alteração nos dependentes será enviada para o seu e-mail em até 5 dias',
    'benefit.dental.manage.dependant.modal.success.confirm.btn':
      'Retornar para plano odontológico',
    'benefit.medical.manage.dependant.modal.success.confirm.btn':
      'Retornar para plano médico',
    'benefit.manage.dependant.intern.warning.modal.title':
      'Você ultrapassou o valor total da sua cesta!',
    'benefit.manage.dependant.intern.warning.modal.description':
      'Colaboradores estagiários não podem ultrapassar sua cesta de benefícios, retorne e <bold>retire algum dependente</bold> até que o valor seja menor ou igual ao total disponível da cesta.',
    'benefit.manage.no.dependants.warning.modal.title':
      'Você não tem dependentes cadastrados!',
    'benefit.manage.no.dependants.warning.modal.description':
      'Para adicionar no benefício, é necessário cadastrar seus dependentes antes. Você pode cadastrar agora ou retornar',
    'modal.add.dependant.button': 'Cadastrar dependente',
    'undo.changes.button': 'Desfazer alterações',
    'benefit.employee.cost': 'Custo ao colaborador',

    'benefit.manage.upgrade.medical.header.back': 'Voltar para plano médico',
    'benefit.manage.upgrade.medical.title':
      'Alterar plano de Assistência Médica',
    'benefit.manage.upgrade.dental.title':
      'Alterar plano de Assistência Odontológica',
    'benefit.manage.upgrade.medical.description':
      'Siga as etapas e, ao finalizar, você deverá redistribuir a sua cesta de benefícios',
    'benefit.manage.upgrade.medical.modal.success.title':
      'Sua solicitação de plano médico foi enviada!',
    'benefit.manage.upgrade.dental.modal.success.title':
      'Sua solicitação de plano odontológico foi enviada!',
    'benefit.manage.upgrade.medical.modal.success.description':
      'A confirmação do novo plano será enviada para o seu e-mail em até 5 dias. Em caso de dúvidas, contate-nos em <bold>equipe.dp@iteris.com.br</bold>',
    'benefit.manage.upgrade.medical.modal.success.confirm.btn':
      'Retornar para plano médico',
    'benefit.manage.upgrade.dental.modal.success.confirm.btn':
      'Retornar para plano odontológico',
    'benefit.manage.upgrade.obs.label.title':
      'Não possui dependente cadastrado?',
    'benefit.manage.upgrade.obs.label.description':
      'Se quiser adicionar algum dependente ao plano, você deverá primeiro cadastrar o dependente, aguardar a aprovação e depois fazer alteração do plano para a inclusão dele.',
    'benefit.manage.upgrade.pending.obs.label.title':
      'Aprovação do seu novo plano está em andamento',
    'benefit.manage.upgrade.pending.obs.label.description':
      'Você solicitou recentemente a alteração do seu plano. Aguarde...',

    'modal.success.cancel.title':
      'Sua solicitação de cancelamento do plano odontológico foi enviada!',
    'modal.success.cancel.description':
      'A confirmação do cancelamento será enviada para o seu e-mail em até 5 dias. Em caso de dúvidas, contate-nos em <bold>equipe.dp@iteris.com.br</bold>',

    'pending.cancel.dental.label.title':
      'Você solicitou o cancelamento do plano',
    'pending.cancel.dental.label.description':
      'O processo está em andamento. Aguarde a finalização, você será avisado por e-mail.',

    'dental.completed.operation.tooltip.title':
      'Você cancelou seu plano há menos de 180 dias!',
    'dental.completed.operation.tooltip.description':
      'Será necessário aguardar o término do período de bloqueio para contratar o benefício novamente',

    'medical.dependant.update.success.title':
      'Sua alteração nos dependentes do plano de Assistência Médica foi <bold>aprovada!</bold>',
    'medical.dependant.update.success.right.button': 'Ir para plano médico',
    'medical.dependant.update.denied.title':
      'Sua alteração nos dependentes do plano de Assistência Médica foi <bold>reprovada!</bold>',

    'dental.dependant.update.success.title':
      'Sua alteração nos dependentes do plano de Assistência Odontológica foi <bold>aprovada!</bold>',
    'dependant.update.success.description': 'Vá no benefício para visualizar',
    'dental.dependant.update.success.right.button':
      'Ir para plano odontológico',
    'dental.dependant.update.denied.title':
      'Sua alteração nos dependentes do plano de Assistência Odontológica foi <bold>reprovada!</bold>',
    'dependant.update.denied.description':
      'Infelizmente sua solicitação foi negada. Os motivos foram enviados para o seu e-mail, em caso de dúvidas, contate-nos em <bold>equipe.dp@iteris.com.br</bold>',

    'tooltip.update.plan.title':
      'Será necessário aguardar o período anual de alterações da Amil',
    'tooltip.update.plan.description':
      'Não se preocupe! Avisaremos todos os colaboradores sobre o início do período.',
    'benefit.manage.dependant.banner.message':
      'Você alterou seus dependentes recentemente. Sua solicitação está em andamento, aguarde para editar novamente',
    'redistribute.basket.header.title': 'Redistribuição de cesta',
    'redistribute.basket.header.description':
      'Utilize o valor disponível da sua cesta nos seus benefícios Alelo.',
    'redistribute.button': 'Confirmar redistribuição',
    'redistribute.obs.label.title':
      'Você está alterando os valores antes do dia <bold>15 do mês</bold>?',
    'redistribute.obs.label.description':
      'Se sim, os novos valores serão refletidos no dia 30 do mesmo mês. Se não, os novos valores só serão refletidos no dia 30 do mês seguinte.',
    'redistribute.modal.back.button': 'Cancelar',
    'redistribute.modal.confirm.button': 'Confirmar',
    'redistribute.modal.title': 'Deseja redistribuir sua cesta?',
    'redistribute.modal.description':
      'Confirme para enviar sua nova distribuição de valores',
    'redistribute.modal.success.title': 'Sua nova redistribuição foi salva!',
    'redistribute.modal.success.description':
      'Acompanhe os novos valores em Informações dos benefícios. Para alterar novamente você deverá aguardar <bold>90 dias</bold>.',
    'redistribute.modal.success.confirm.button':
      'Retornar para cesta de benefícios',
    'redistribute.modal.error.title': 'Falha no salvamento',
    'redistribute.modal.error.description':
      'Houve algum erro na hora de salvar os novos valores da sua cesta. Retorne e tente novamente!',
    'redistribute.modal.loading.title': 'Salvando redistribuição...',
    'redistribute.edit.back': 'Voltar para Alelo',
    'benefit.update.success.modal.dental.title':
      'Agora você possui o plano odontológico',
    'benefit.update.success.modal.medical.title':
      'Agora você possui o plano médico',
    'benefit.update.success.modal.description':
      'Sua alteração de plano foi aprovada! Acompanhe os novos valores no benefício',
    'benefit.update.error.modal.dental.title':
      'Sua solicitação de alterar o plano odontológico foi <bold>reprovada!</bold>',
    'benefit.update.error.modal.medical.title':
      'Sua solicitação de alterar o plano médico foi <bold>reprovada!</bold>',
    'benefit.update.error.modal.description':
      'Não foi possível concluir a alteração do seu plano. Os detalhes foram enviados para o seu e-mail. Em caso de dúvidas, contate-nos em <bold>equipe.dp@iteris.com.br</bold>',
    'benefit.update.info.modal.title':
      'Está disponível o upgrade ou downgrade da Amil!',
    'benefit.update.info.modal.description': 'Você tem até ',
    'benefit.update.info.modal.description.2':
      ' para solicitar. Aproveite e escolha o melhor para você e sua família',
    'benefit.update.info.modal.button': 'Ir para benefícios',
    'benefit.adjust.warning.modal.title': 'Atenção!',
    'benefit.adjust.warning.modal.subtitle':
      'Novos valores serão cobrados na Assistência Médica e Odontológica. Revise sua cesta até ',
    'benefit.adjust.warning.modal.description':
      'Todos os detalhes foram enviados para o seu e-mail.',
    'benefit.adjust.warning.modal.resume.title': 'Como vai ficar sua cesta?',
    'benefit.adjust.warning.modal.resume.total': 'Total da sua cesta',
    'benefit.adjust.warning.modal.resume.medical':
      'Custo total da sua Assistência Médica',
    'benefit.adjust.warning.modal.resume.dental':
      'Custo total da sua Assistência Odontológica',
    'benefit.adjust.warning.modal.resume.alelo': 'Valor disponível para Alelo',
    'benefit.adjust.warning.modal.resume.discount':
      'Desconto na sua folha de pagamento',
    'benefit.adjust.warning.modal.info.title': 'Quais as suas possibilidades?',
    'benefit.adjust.warning.modal.info.item.1':
      'Se você concorda com a distribuição de valores da sua cesta descrita na seção <bold>acima</bold>, escolha a opção <bold>“Eu aceito a minha nova cesta”</bold>:',
    'benefit.adjust.warning.modal.info.item.1.sub.1':
      'Caso haja valor disponível para Alelo, ao aceitar você seguirá para a redistribuição da cesta;',
    'benefit.adjust.warning.modal.info.item.1.sub.2':
      'Caso o valor total da Assistência Médica e Odontológica ultrapasse o total da sua cesta, o valor excedente será <bold>descontado na sua folha de pagamento.</bold>',
    'benefit.adjust.warning.modal.info.item.2':
      'Se você <bold>não</bold> concorda, escolha a opção <bold>“Quero gerenciar meus benefícios”</bold> e, assim, você será redirecionado para <bold>Benefícios</bold> e lá poderá escolher como modificar a sua cesta, por exemplo:',
    'benefit.adjust.warning.modal.info.item.2.sub.1':
      'Gerenciando seus dependentes nos benefícios de assistência médica e/ou odontológica;',
    'benefit.adjust.warning.modal.info.item.2.sub.2':
      'Ou solicitando o upgrade ou downgrade dos planos da sua assistência médica e/ou odontológica;',
    'benefit.adjust.warning.modal.info.item.2.sub.3':
      'Ou cancelando sua assistência odontológica.',
    'benefit.adjust.warning.modal.info.item.3.1':
      'Caso você escolha <bold>“Quero gerenciar meus benefícios”</bold> e, uma vez na seção Benefícios, <bold>não fizer nada para alterar</bold> a sua cesta até o dia ',
    'benefit.adjust.warning.modal.info.item.3.2':
      ', sua cesta de benefícios será alterada automaticamente como foi descrita na seção "Como vai ficar a sua cesta?”.',
    'benefit.adjust.warning.modal.checkbox':
      'estou ciente dos termos descritos em ”Quais as suas possibilidades?” e desejo continuar',
    'benefit.adjust.warning.modal.left.button':
      'Quero gerenciar meus benefícios',
    'benefit.adjust.warning.modal.right.button': 'Eu aceito a minha nova cesta',
  },
};
