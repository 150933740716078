import React from 'react';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg';
import { ReactComponent as ArrowDownDisabled } from 'assets/icons/arrow-down-disabled.svg';
import { ReactComponent as HistoryToggle } from 'assets/icons/history_toggle_off.svg';
import { ReactComponent as CheckboxDefault } from 'assets/icons/checkbox-default.svg';
import { ReactComponent as ImageUploadError } from 'assets/icons/image-upload-error.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as ModalIconClose } from 'assets/icons/modal-icon-close.svg';
import { ReactComponent as ReloadIcon } from 'assets/icons/reload-icon.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/upload-icon.svg';
import { ReactComponent as SearchWhiteIcon } from 'assets/icons/search-white-icon.svg';
import { ReactComponent as LogoIteris } from 'assets/icons/logo-iteris.svg';
import { ReactComponent as CheckboxReset } from 'assets/icons/checkbox-reset.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search-icon.svg';
import { ReactComponent as PersonIcon } from 'assets/icons/person-icon.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';
import { ReactComponent as PngFileIcon } from 'assets/icons/doc-image-error.svg';
import { ReactComponent as PdfFileIcon } from 'assets/icons/pdf-file-icon.svg';
import { ReactComponent as ReportProblem } from 'assets/icons/report-problem-icon.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check-icon.svg';
import { ReactComponent as CheckBoxRed } from 'assets/icons/red-checkbox.svg';
import { ReactComponent as BirthCake } from 'assets/icons/cake.svg';
import { ReactComponent as EditPencil } from 'assets/icons/edit-pencil.svg';
import { ReactComponent as IterisLogoColor } from 'assets/icons/iteris-globant-logo-default.svg';
import { ReactComponent as AccountCircle } from 'assets/icons/account_circle.svg';
import { ReactComponent as AddCircle } from 'assets/icons/add_circle.svg';
import { ReactComponent as HelpIcon } from 'assets/icons/help.svg';
import { ReactComponent as ChevronRightBlue } from 'assets/icons/chevron_right_blue.svg';
import { ReactComponent as PaidIcon } from 'assets/icons/paid.svg';
import { ReactComponent as PaidIconOrange } from 'assets/icons/paid-orange.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/error.svg';
import { ReactComponent as PaidGrayIcon } from 'assets/icons/paid-gray.svg';
import { ReactComponent as LogoAmilIcon } from 'assets/icons/logo-amil-mock.svg';
import { ReactComponent as GroupAdd } from 'assets/icons/group-add.svg';
import { ReactComponent as GroupAddOutline } from 'assets/icons/group-add-outline.svg';
import { ReactComponent as HelpCircle } from 'assets/icons/help-circle.svg';
import { ReactComponent as AttachFileAdd } from 'assets/icons/attach-file-add.svg';
import { ReactComponent as AttachFileAddDisabled } from 'assets/icons/attach-file-add-disabled.svg';
import { ReactComponent as CloseUploaderValid } from 'assets/icons/close-uploader-valid.svg';
import { ReactComponent as CloseUploaderInvalid } from 'assets/icons/close-uploader-invalid.svg';
import { ReactComponent as LockerOpen } from 'assets/icons/locker-open.svg';
import { ReactComponent as LockerDisabled } from 'assets/icons/locker-disabled.svg';
import { ReactComponent as InfoCircle } from 'assets/icons/info-circle.svg';
import { ReactComponent as ToothIcon } from 'assets/icons/tooth-icon.svg';
import { ReactComponent as GroupActive } from 'assets/icons/group-active.svg';
import { ReactComponent as TimePlanChange } from 'assets/icons/time-plan-change.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/cross-icon.svg';
import { ReactComponent as PaidBlue } from 'assets/icons/paid-blue.svg';
import { ReactComponent as PersonIconBlue } from 'assets/icons/person-icon-blue.svg';
import { ReactComponent as CheckFlag } from 'assets/icons/check-flag.svg';
import { ReactComponent as CheckFlagSuccess } from 'assets/icons/check-flag-success.svg';
import { ReactComponent as CheckFlagSuccessOrange } from 'assets/icons/check-flag-success-orange.svg';
import { ReactComponent as MinusCircle } from 'assets/icons/minus-circle.svg';
import { ReactComponent as MinusCircleOrange } from 'assets/icons/minus-circle-orange.svg';
import { ReactComponent as MinusCircleBgwhite } from 'assets/icons/minus-circle-bgwhite.svg';
import { ReactComponent as PlusCircleBgwhite } from 'assets/icons/plus-circle-bgwhite.svg';
import { ReactComponent as PlusCircle } from 'assets/icons/plus-circle.svg';
import { ReactComponent as PlusCircleBlue } from 'assets/icons/plus-circle-blue.svg';
import { ReactComponent as WaitingApproval } from 'assets/icons/waiting-approval.svg';
import { ReactComponent as Warning } from 'assets/icons/warning.svg';
import { ReactComponent as SentSuccess } from 'assets/icons/sent-success.svg';
import { ReactComponent as ValueChange } from 'assets/icons/value-change.svg';
import { ReactComponent as LogoAlelo } from 'assets/icons/logo-alelo.svg';
import { ReactComponent as EditSuccess } from 'assets/icons/edit-success.svg';
import { ReactComponent as EditError } from 'assets/icons/edit-error.svg';
import { ReactComponent as TrashCan } from 'assets/icons/trash-can.svg';
import { ReactComponent as LockerClosed } from 'assets/icons/locker-closed.svg';
import { ReactComponent as Calendar } from 'assets/icons/calendar.svg';
import { ReactComponent as Remove } from 'assets/icons/remove.svg';
import { ReactComponent as RemoveDenied } from 'assets/icons/remove-denied.svg';
import { ReactComponent as WaitingApprovalRed } from 'assets/icons/waiting-approval-red.svg';
import { ReactComponent as RightIndicator } from 'assets/icons/right-indicator.svg';
import { ReactComponent as LeftIndicator } from 'assets/icons/left-indicator.svg';
import { ReactComponent as Attention } from 'assets/icons/attention.svg';
import { ReactComponent as CreateApproved } from 'assets/icons/create-approved.svg';
import { ReactComponent as CreateDenied } from 'assets/icons/create-denied.svg';
import { ReactComponent as DeniedOperation } from 'assets/icons/deniedOperation.svg';
import { ReactComponent as CheckboxOrange } from 'assets/icons/checkbox-checked-orange.svg';
import { ReactComponent as ChevronLeftDisabled } from 'assets/icons/chevron-left-disabled.svg';
import { ReactComponent as ChevronLeftGray } from 'assets/icons/chevron-left-gray.svg';
import { ReactComponent as RadioButtonChecked } from 'assets/icons/radio_button_checked.svg';
import { ReactComponent as RadioButtonUnchecked } from 'assets/icons/radio_button_unchecked.svg';
import { ReactComponent as VolunteerActivism } from 'assets/icons/volunteer_activism.svg';
import { ReactComponent as SettingsOutlined } from 'assets/icons/settings-outlined.svg';
import { ReactComponent as CheckCircleFull } from 'assets/icons/check-circle-full.svg';
import { ReactComponent as Emergency } from 'assets/icons/emergency.svg';
import { ReactComponent as CheckboxBlack } from 'assets/icons/black-checkbox.svg';

export type IconTypes =
  | 'rightIndicator'
  | 'leftIndicator'
  | 'warning'
  | 'checkBoxRed'
  | 'lockerDisabled'
  | 'checkFlagSuccessOrange'
  | 'editError'
  | 'editSuccess'
  | 'errorIcon'
  | 'trashCan'
  | 'arrowDown'
  | 'arrowUp'
  | 'LogoAmilIcon'
  | 'checkboxDefault'
  | 'uploadIcon'
  | 'addCircle'
  | 'helpIcon'
  | 'reloadIcon'
  | 'imageUploadError'
  | 'modalIconClose'
  | 'infoIcon'
  | 'searchWhiteIcon'
  | 'logoIteris'
  | 'checkboxReset'
  | 'searchIcon'
  | 'personIcon'
  | 'chevronRight'
  | 'chevronRightBlue'
  | 'paid'
  | 'paidOrange'
  | 'chevronLeft'
  | 'chevronLeftGray'
  | 'chevronLeftDisabled'
  | 'paidGray'
  | 'pdfFileIcon'
  | 'pngFileIcon'
  | 'checkIcon'
  | 'birthCake'
  | 'accountCircle'
  | 'editPencil'
  | 'iterisLogoColor'
  | 'reportProblem'
  | 'groupAdd'
  | 'groupAddOutline'
  | 'helpCircle'
  | 'attachFileAdd'
  | 'attachFileAddDisabled'
  | 'closeUploaderValid'
  | 'closeUploaderInvalid'
  | 'lockerOpen'
  | 'infoCircle'
  | 'toothIcon'
  | 'groupActive'
  | 'timePlanChange'
  | 'crossIcon'
  | 'paidBlue'
  | 'personIconBlue'
  | 'checkFlag'
  | 'checkFlagSuccess'
  | 'minusCircle'
  | 'plusCircle'
  | 'plusCircleBlue'
  | 'minusCircleOrange'
  | 'minusCircleBgwhite'
  | 'plusCircleBgwhite'
  | 'waitingApproval'
  | 'sentSuccess'
  | 'checkFlag'
  | 'checkFlag'
  | 'valueChange'
  | 'lockerClosed'
  | 'logoAlelo'
  | 'calendar'
  | 'arrowDownDisabled'
  | 'historyToggle'
  | 'waitingApprovalRed'
  | 'historyToggle'
  | 'remove'
  | 'removeDenied'
  | 'deniedOperation'
  | 'createDenied'
  | 'createApproved'
  | 'attention'
  | 'checkboxOrange'
  | 'radioButtonChecked'
  | 'radioButtonUnchecked'
  | 'volunteerActivism'
  | 'checkCircleFull'
  | 'settingsOutlined'
  | 'emergency'
  | 'checkboxBlack';

type IconProps = {
  name: IconTypes | '';
};

const icons: Record<IconTypes, React.ReactElement> = {
  checkboxOrange: <CheckboxOrange />,
  checkBoxRed: <CheckBoxRed />,
  checkboxBlack: <CheckboxBlack />,
  lockerClosed: <LockerClosed />,
  rightIndicator: <RightIndicator />,
  leftIndicator: <LeftIndicator />,
  lockerDisabled: <LockerDisabled />,
  checkFlagSuccessOrange: <CheckFlagSuccessOrange />,
  accountCircle: <AccountCircle />,
  editError: <EditError />,
  editSuccess: <EditSuccess />,
  trashCan: <TrashCan />,
  errorIcon: <ErrorIcon />,
  arrowDown: <ArrowDown />,
  arrowUp: <ArrowUp />,
  helpIcon: <HelpIcon />,
  addCircle: <AddCircle />,
  LogoAmilIcon: <LogoAmilIcon />,
  paid: <PaidIcon />,
  paidOrange: <PaidIconOrange />,
  chevronRightBlue: <ChevronRightBlue />,
  iterisLogoColor: <IterisLogoColor />,
  paidGray: <PaidGrayIcon />,
  checkboxDefault: <CheckboxDefault />,
  uploadIcon: <UploadIcon />,
  reloadIcon: <ReloadIcon />,
  imageUploadError: <ImageUploadError />,
  modalIconClose: <ModalIconClose />,
  infoIcon: <InfoIcon />,
  searchWhiteIcon: <SearchWhiteIcon />,
  logoIteris: <LogoIteris />,
  checkboxReset: <CheckboxReset />,
  searchIcon: <SearchIcon />,
  personIcon: <PersonIcon />,
  chevronRight: <ChevronRight />,
  chevronLeft: <ChevronLeft />,
  chevronLeftDisabled: <ChevronLeftDisabled />,
  chevronLeftGray: <ChevronLeftGray />,
  pdfFileIcon: <PdfFileIcon />,
  pngFileIcon: <PngFileIcon />,
  reportProblem: <ReportProblem />,
  checkIcon: <CheckIcon />,
  birthCake: <BirthCake />,
  editPencil: <EditPencil />,
  groupAdd: <GroupAdd />,
  groupAddOutline: <GroupAddOutline />,
  helpCircle: <HelpCircle />,
  attachFileAdd: <AttachFileAdd />,
  attachFileAddDisabled: <AttachFileAddDisabled />,
  closeUploaderValid: <CloseUploaderValid />,
  closeUploaderInvalid: <CloseUploaderInvalid />,
  lockerOpen: <LockerOpen />,
  infoCircle: <InfoCircle />,
  toothIcon: <ToothIcon />,
  groupActive: <GroupActive />,
  timePlanChange: <TimePlanChange />,
  crossIcon: <CrossIcon />,
  paidBlue: <PaidBlue />,
  personIconBlue: <PersonIconBlue />,
  checkFlag: <CheckFlag />,
  checkFlagSuccess: <CheckFlagSuccess />,
  minusCircle: <MinusCircle />,
  minusCircleOrange: <MinusCircleOrange />,
  plusCircle: <PlusCircle />,
  plusCircleBlue: <PlusCircleBlue />,
  minusCircleBgwhite: <MinusCircleBgwhite />,
  plusCircleBgwhite: <PlusCircleBgwhite />,
  waitingApproval: <WaitingApproval />,
  warning: <Warning />,
  sentSuccess: <SentSuccess />,
  valueChange: <ValueChange />,
  logoAlelo: <LogoAlelo />,
  calendar: <Calendar />,
  historyToggle: <HistoryToggle />,
  arrowDownDisabled: <ArrowDownDisabled />,
  waitingApprovalRed: <WaitingApprovalRed />,
  remove: <Remove />,
  removeDenied: <RemoveDenied />,
  attention: <Attention />,
  createApproved: <CreateApproved />,
  createDenied: <CreateDenied />,
  deniedOperation: <DeniedOperation />,
  radioButtonChecked: <RadioButtonChecked />,
  radioButtonUnchecked: <RadioButtonUnchecked />,
  volunteerActivism: <VolunteerActivism />,
  settingsOutlined: <SettingsOutlined />,
  checkCircleFull: <CheckCircleFull />,
  emergency: <Emergency />,
};

function Icon({ name }: IconProps): React.ReactElement | null {
  if (name) {
    const Component = icons[name];
    return Component;
  }

  return null;
}

export default Icon;
