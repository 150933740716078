import { UserStore } from './user/types';

const store: UserStore = {
  resume: {
    contractType: '',
    birthdate: '',
    email: '',
    name: '',
    position: '',
    acronym: '',
    active: true,
    benefitOptionValueChanged: false,
    login: '',
    picture: '',
  },
  loggedUser: {
    nameUser: '',
    imgUser: null,
  },
  editDependant: {
    dependantId: 0,
    name: '',
    includeIR: false,
  },
  cancelDependant: {
    id: 0,
    name: '',
    kinship: '',
    document: '',
    includeIR: false,
    birthdate: '',
    benefits: [],
    dependantUpdateOperation: {
      id: 0,
      status: '',
      flagViewed: true,
    },
  },
  benefitToManage: {
    benefitsToRedistribute: [
      {
        id: 0,
        name: '',
        urlImage: '',
        value: 0,
        locked: false,
      },
    ],
    benefit: {
      benefitId: 0,
      description: '',
      denominationBenefit: '',
      mandatory: false,
      name: '',
      idBenefitOption: 0,
      option: '',
      subtitle: '',
      total: 0,
      urlImage: '',
      amountDependants: 0,
      numberDependants: 0,
      valueByDependant: 0,
      valueEmployee: 0,
    },
    totalAmount: {
      total: 0,
      used: 0,
    },
  },
  idBenefitPlan: 0,
  pendingOperation: false,
  reloadBenefit: false,
};

export default store;
