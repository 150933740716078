import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import Conditional from './Conditional';
import './style.scss';

type ContainerProps = {
  children: React.ReactNode;
  className?: string;
  close: () => void;
  confirm?: () => void;
  open?: boolean;
  disableConfirm?: boolean;
  showFooter?: boolean;
  showLeftButton?: boolean;
  leftButtonText?: string;
  rightButtonText?: string;
};

function AppModal({
  leftButtonText,
  rightButtonText,
  children,
  open,
  className,
  close,
  confirm,
  disableConfirm = false,
  showFooter = true,
  showLeftButton = false,
}: ContainerProps) {
  return (
    <Conditional if={open}>
      <Modal show centered dialogClassName={className}>
        <Modal.Body>{children}</Modal.Body>
        {showFooter && (
          <Modal.Footer className="footer">
            {showLeftButton && (
              <Button onClick={close} className="left-button">
                <span className="">{leftButtonText}</span>
              </Button>
            )}

            <Button
              onClick={confirm}
              disabled={disableConfirm}
              className="right-button"
            >
              {rightButtonText}
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </Conditional>
  );
}

export default AppModal;
