import React from 'react';
import Icon, { IconTypes } from 'components/Icon';
import './styles.scss';

export type CheckboxProps = {
  checkedValue: boolean;
  classNameButton?: string;
  classNameChecked?: string;
  checkedIcon?: IconTypes | '';
  checkboxColor?: string;
  setCheckedValue: (check: boolean) => void;
};

function Checkbox({
  setCheckedValue,
  classNameChecked,
  checkedValue = false,
  checkboxColor = '',
  checkedIcon = 'checkBoxRed',
  classNameButton = 'checkbox-container',
}: CheckboxProps) {
  const handleCheckboxChange = () => {
    setCheckedValue(!checkedValue);
  };

  return (
    <button
      type="button"
      className={`${classNameButton} ${checkboxColor}`}
      onClick={handleCheckboxChange}
    >
      <input
        type="checkbox"
        aria-checked={checkedValue}
        className="hidden-checkbox"
      />
      <div
        title="checked-icon"
        aria-checked={checkedValue}
        className={classNameChecked}
        style={{
          display: checkedValue ? 'flex' : 'none',
        }}
      >
        <Icon name={checkedIcon} />
      </div>
    </button>
  );
}

export default Checkbox;
