import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';
import Icon from 'components/Icon';
import AppButton from 'components/AppButton';
import BenefitBasketInformation from 'components/Benefits/BenefitBasketEdit/BenefitBasketInformation';
import BenefitService from 'services/BenefitService';
import BenefitBasketEditLoader from 'components/Benefits/BenefitBasketEdit/BenefitBasketEditLoader';
import { BenefitAmounts, BenefitResumeWithId } from 'types/models/Benefit';
import GenericTooltipMessage from 'components/Shared/GenericTooltipMessage';
import AuthContext from 'configs/context';
import 'styles/base/page-header.scss';
import './styles.scss';
import PendingOperationBanner from 'components/Shared/PendingOperationBanner';

function BenefitBasketEdit() {
  const navigate = useNavigate();
  const { t } = useTranslation('benefit');
  const {
    state: { pendingOperation },
  } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [benefits, setBenefits] = useState<Array<BenefitResumeWithId>>([]);
  const [totalAmount, setTotalAmount] = useState<BenefitAmounts>({
    total: 0,
    used: 0,
  });
  const [disabled, setDisabled] = useState(false);

  const exceded = totalAmount.used - totalAmount.total;
  const benefitsToSort = [...benefits];
  const benefitsSorted = benefitsToSort.sort(
    (a, b) => b.benefitId - a.benefitId
  );

  useEffect(() => {
    Promise.all([
      BenefitService.getAmounts(true),
      BenefitService.getBenefitsResume(),
      BenefitService.redistribuitonDelay(),
    ])
      .then((results) => {
        const [amountsRes, benefitsResumeRes, redistributionDelayRes] = results;
        setTotalAmount(amountsRes.data);
        setDisabled(redistributionDelayRes.data);
        setBenefits(benefitsResumeRes.data.benefitsResume);
      })
      .catch(() => navigate('/benefit'))
      .finally(() => setLoading(false));
  }, []);

  const tooltipData = () => {
    if (disabled) {
      return {
        title: t('tooltip.title.delay'),
        message: t('tooltip.message.delay'),
        type: 'delay',
      };
    }

    return {
      title: t('tooltip.title.exceded'),
      message: t('tooltip.message.exceded'),
      type: 'exceded',
    };
  };

  return (
    <div className="benefit-basket-container main-padding">
      {loading ? (
        <BenefitBasketEditLoader />
      ) : (
        <>
          <div className="page-header">
            <Row className="navigation">
              <Col
                className="navigation-link"
                onClick={() => navigate('/benefit')}
              >
                <Icon name="chevronLeftGray" />
                <span>{t('edit.back')}</span>
              </Col>
            </Row>
            <hr />
            <div className="title">
              <Icon name="logoAlelo" />
              <div className="text">
                <h1>{t('edit.basket.header.title')}</h1>
                <p>{t('edit.basket.header.description')}</p>
              </div>
            </div>
          </div>

          <div className="manage">
            <h1 className="title">{t('edit.basket.manage.title')}</h1>
            <Row>
              <Col xl={5} xxl={4}>
                <Card
                  className={
                    disabled || exceded >= 0 || pendingOperation
                      ? 'disabled'
                      : 'active'
                  }
                >
                  <Card.Body>
                    <Icon name="valueChange" />
                    <h3 className="title">{t('edit.basket.change.values')}</h3>
                    <p className="description">
                      {t('edit.basket.change.values.description')}
                    </p>
                  </Card.Body>
                  <Card.Footer>
                    <AppButton
                      disabled={disabled || exceded >= 0 || pendingOperation}
                      className="redistribute-button"
                      onClick={() => navigate('/benefit/basket/redistribute')}
                    >
                      {t('redistribute.values.button')}
                    </AppButton>
                    {(disabled || exceded >= 0) && (
                      <GenericTooltipMessage
                        icon="attention"
                        placement="right-end"
                        {...tooltipData()}
                      />
                    )}
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
            {pendingOperation && <PendingOperationBanner />}
          </div>
          <BenefitBasketInformation
            totalAmount={totalAmount}
            benefits={benefitsSorted.filter((benefit) => !benefit.option)}
          />
        </>
      )}
    </div>
  );
}

export default BenefitBasketEdit;
