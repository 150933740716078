import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import Icon from 'components/Icon';
import './styles.scss';

function PendingOperationBanner() {
  const { t } = useTranslation('shared');
  return (
    <Row className="pendency-row">
      <Col className="pendency-info">
        <Icon name="historyToggle" />
        <h2>{t('pending.operation.message')}</h2>
      </Col>
    </Row>
  );
}

export default PendingOperationBanner;
