import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import UserPhoto from 'components/UserPhoto';
import MyProfileLoader from 'pages/MyProfile/MyProfileLoader';
import AuthContext from '../../configs/context';
import './style.scss';
import ProfileHeaderLoader from './ProfileHeaderLoader';

function ProfileHeader() {
  const { state } = useContext(AuthContext);

  return (
    <Row className="profile-container main-padding">
      {state.resume.position ? (
        <div className="container">
          <Col xs={2} className="image">
            <UserPhoto className="img" alt="profile" />
          </Col>
          <Col className="titles">
            <h1 className="name">{state.loggedUser.nameUser}</h1>
            <h4 className="team">{state.resume.position}</h4>
          </Col>
        </div>
      ) : (
        <ProfileHeaderLoader />
      )}
    </Row>
  );
}

export default ProfileHeader;
