import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ProgressBarBenefits from 'components/MyBenefits/ProgressBar';
import Checkbox from 'components/Shared/Checkbox';
import { BenefitAmounts, BasketBenefit } from 'types/models/Benefit';
import masks from 'utils/MasksUtils';
import RedistributeSlider from './RedistributeSlider';
import './styles.scss';

export type Props = {
  checkboxValue: boolean;
  totalAmount: BenefitAmounts;
  benefits: BasketBenefit[];
  setBenefits: (benefits: BasketBenefit[]) => void;
  setCheckboxValue: (checkboxValue: boolean) => void;
};

function Redistribute({
  benefits,
  setBenefits,
  totalAmount,
  checkboxValue,
  setCheckboxValue,
}: Props) {
  const { t } = useTranslation('redistribute');
  const [redistributeValue, setRedistributeValue] = useState(0);
  const [values, setValues] = useState<Record<number, BasketBenefit>>({});
  const available = totalAmount.total - totalAmount.used;
  const getTotalAvailable = () => parseFloat(available.toFixed(2));
  const roundedTotal = parseFloat(totalAmount.total.toFixed(2));
  const roundedUsed = parseFloat(totalAmount.used.toFixed(2));
  const payrollDiscount = roundedUsed > roundedTotal;

  const handleChange = (benefit: BasketBenefit, value: number) => {
    if (!values) return;

    const realValue = value / 100;
    const updatedBenefits = { ...values };

    if (Object.values(updatedBenefits).filter((v) => !v.locked).length <= 1)
      return;

    updatedBenefits[benefit.id] = {
      ...benefit,
      value: realValue,
    };

    setBenefits(Object.values(updatedBenefits));
    setValues(updatedBenefits);
  };

  const getTotalDistribuited = () => {
    if (!Object.values(values) || Object.values(values).length === 0) return 0;
    return parseFloat(
      Object.values(values)
        .map((x) => x.value)
        .reduce((a, b) => a + b)
        .toFixed(2)
    );
  };

  const remainingBalance =
    parseFloat(available.toFixed(2)) - getTotalDistribuited();
  const invalidDistribution = parseFloat(remainingBalance.toFixed(2)) < 0;

  useEffect(() => {
    const record: Record<number, BasketBenefit> = {};

    for (const benefit of benefits) {
      record[benefit.id] = {
        ...benefit,
      };
    }

    setValues(record);
    setBenefits(Object.values(record));
    setRedistributeValue(getTotalAvailable());
  }, []);

  return (
    <div className="redistribute-container">
      <div className="body">
        <ProgressBarBenefits amounts={totalAmount} />
        <div className="sliders">
          {values &&
            Object.values(values)
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((benefit) => (
                <RedistributeSlider
                  key={benefit.id}
                  benefit={values[benefit.id]}
                  redistributeValue={redistributeValue}
                  sliderDisabled
                  total={available}
                  benefitValues={values[benefit.id].value}
                  handleChange={handleChange}
                />
              ))}
        </div>
      </div>

      {!payrollDiscount && roundedTotal !== roundedUsed && (
        <div
          className={invalidDistribution ? 'remaining invalid' : 'remaining'}
        >
          <span className="text">
            {invalidDistribution
              ? t('remaining.text.invalid')
              : t('remaining.text')}
            <span className="bold value">
              {invalidDistribution
                ? masks.currency(available)
                : masks.currency(remainingBalance)}
            </span>
          </span>
        </div>
      )}

      {payrollDiscount && (
        <div className="warning">
          <div className="text">
            <h1 className="title">{t('warning.text')}</h1>
            <p className="description">
              <Trans
                t={t}
                i18nKey="warning.description"
                components={{ bold: <span className="bold" /> }}
              />
            </p>
            <div className="checkbox">
              <Checkbox
                checkboxColor="orange"
                checkedValue={checkboxValue}
                setCheckedValue={setCheckboxValue}
                checkedIcon="checkboxOrange"
              />
              <p className="text">{t('warning.button')}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Redistribute;
