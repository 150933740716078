import React, { useEffect, useState, useContext } from 'react';
import OtherRoutes from 'routes/OtherRoutes';
import Header from 'components/Header';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useAccount,
} from '@azure/msal-react';
import SignRoutes from 'routes/SignRoutes';
import { Container } from 'react-bootstrap';

import { InteractionStatus } from '@azure/msal-browser';
import CallMsGraph from 'services/GraphService';

import Storage from 'services/storage';
import AuthContext from '../../configs/context';
import { loggedUser } from '../../store/user/actions';

function Base() {
  const { state, dispatch } = useContext(AuthContext);
  const { instance, inProgress } = useMsal();
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState('');
  const [initials, setInitials] = useState('');
  const [graphData, setGraphData] = useState<null | any>(null);

  useEffect(() => {
    if (account && account.name) {
      setName(account.name.split('-')[0]);
      setInitials(account.name.split('-')[1]);
    } else {
      setName('');
      setInitials('');
    }
    if (account && !graphData && inProgress === InteractionStatus.None) {
      CallMsGraph(account);
      setGraphData(Storage.getAvatar());
    }
  }, [inProgress, graphData, instance, account]);

  useEffect(() => {
    const image = state.loggedUser.imgUser;

    if (!image) {
      const fromStorage = Storage.getAvatar();

      if (fromStorage) {
        dispatch(
          loggedUser({
            ...state.loggedUser,
            imgUser: fromStorage,
          })
        );
      }
    }
  });

  useEffect(() => {
    if (name !== '') {
      dispatch(
        loggedUser({
          nameUser: `${name}-${initials}`,
          imgUser: graphData,
        })
      );
    }
  }, [name, initials, graphData, dispatch]);

  return (
    <>
      <AuthenticatedTemplate>
        <Container fluid className="container-base">
          <Header />
          <OtherRoutes />
        </Container>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <SignRoutes />
      </UnauthenticatedTemplate>
    </>
  );
}

export default Base;
