import React from 'react';
import { Row } from 'react-bootstrap';
import AppModal from 'components/AppModal';
import Icon, { IconTypes } from 'components/Icon';
import './styles.scss';

type modalProps = {
  open: boolean;
  loading?: boolean;
  showFooter?: boolean;
  showLeftButton?: boolean;
  data?: {
    type?: string;
    icon?: string;
    title?: string;
    description?: any;
    leftButtonText?: string;
    rightButtonText?: string;
  };
  close: () => void;
  handleConfirm: () => void;
};

function ModalWithLoading({
  open,
  data,
  close,
  loading,
  showFooter,
  handleConfirm,
  showLeftButton,
}: modalProps) {
  return (
    <AppModal
      open={open}
      leftButtonText={data?.leftButtonText}
      rightButtonText={data?.rightButtonText}
      showFooter={showFooter}
      showLeftButton={showLeftButton}
      confirm={() => {
        handleConfirm();
      }}
      close={() => {
        close();
      }}
    >
      <Row className="modal-with-loading">
        {loading ? (
          <div data-testid="loading-container" className="loading-container">
            <div data-testid="loading-image" className="loading-image" />
          </div>
        ) : (
          <Row data-testid="icon-container" className="icon">
            <Icon name={data?.icon as IconTypes} />
          </Row>
        )}

        <Row className="text pe-0">
          <h2 className="title pe-0">{data?.title}</h2>
          <h4
            className={
              data?.title === '' ? 'description pe-0' : 'description pe-0 mt-0'
            }
          >
            {data?.description}
          </h4>
        </Row>
      </Row>
    </AppModal>
  );
}

export default ModalWithLoading;
