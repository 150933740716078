import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import AppModal from 'components/AppModal';
import Redistribute from 'components/Shared/Redistribute';
import { BenefitAmounts, BasketBenefit } from 'types/models/Benefit';
import './styles.scss';

export type Props = {
  open: boolean;
  close: () => void;
  confirm: (confirmedBenefits: Array<BasketBenefit>) => void;
  disableConfirm?: boolean;
  benefits: Array<BasketBenefit>;
  totalAmount: BenefitAmounts;
  setBenefits: (benefits: Array<BasketBenefit>) => void;
};

function RedistributeModal({
  open,
  close,
  confirm,
  benefits,
  setBenefits,
  totalAmount,
  disableConfirm,
}: Props) {
  const { t } = useTranslation('redistribute');
  const { total, used } = totalAmount;
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [basketRedistribution, setBasketRedistribution] =
    useState<Array<BasketBenefit>>(benefits);
  const roundedTotal = parseFloat(total.toFixed(2));
  const roundedUsed = parseFloat(used.toFixed(2));

  const disableConfirmButton = (): boolean => {
    const availableAmount = roundedTotal - roundedUsed;
    const isAmountZero = availableAmount === 0;
    const roundedAvailableAmount = Math.max(
      parseFloat(availableAmount.toFixed(2)),
      0
    );
    let totalBasketDistributed = 0;
    basketRedistribution.forEach((benefit) => {
      totalBasketDistributed += benefit.value;
    });
    const roundedTotalBasket = parseFloat(totalBasketDistributed.toFixed(2));
    if (isAmountZero) return false;
    if (roundedAvailableAmount !== roundedTotalBasket) return true;
    return disableConfirm ? false : !checkboxValue;
  };

  return (
    <AppModal
      open={open}
      showLeftButton
      close={() => close()}
      disableConfirm={disableConfirmButton()}
      leftButtonText="Cancelar"
      confirm={() => {
        setBenefits(basketRedistribution);
        confirm(basketRedistribution);
      }}
      className="redistribute-modal"
      rightButtonText="Confirmar redistribuição"
    >
      <div className="modal-with-confirmation">
        <Row className="text">
          <Col>
            <h2 className="title">{t('add.header.title')}</h2>
            <h4 className="description">
              <Trans
                t={t}
                i18nKey="add.header.description"
                components={{ bold: <span className="bold" /> }}
              />
            </h4>
          </Col>
        </Row>
        <Redistribute
          benefits={benefits}
          setBenefits={setBasketRedistribution}
          totalAmount={totalAmount}
          checkboxValue={checkboxValue}
          setCheckboxValue={setCheckboxValue}
        />
        {roundedTotal === roundedUsed && (
          <div className="zero-value-benefit-basket-banner">
            <span className="banner-title">
              {t('redistribute.modal.zero.value.available.title')}
            </span>
            <p className="banner-description">
              {t('redistribute.modal.zero.value.available.description')}
            </p>
          </div>
        )}
      </div>
    </AppModal>
  );
}

export default RedistributeModal;
