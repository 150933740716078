import api from 'configs/api';
import {
  DependantsList,
  DependantEditProfile,
  CreateDependantRequest,
  DeleteDependantRequest,
} from 'types/models/Dependant';

type DeleteAttachmentProps = {
  config: any;
  handleThen: () => void;
  handleCatch: () => void;
};

async function list() {
  return api.get<DependantsList>('/dependant');
}

async function updateDependantInfo(data: DependantEditProfile) {
  return api.put('/operations/dependant-update', data);
}

async function dependantOptions(idBenefitOption: number) {
  return api.get('/dependant/benefit-option', {
    params: { idBenefitOption },
  });
}

async function createDependant(request: CreateDependantRequest) {
  return api.post('/operations/dependant-create', request);
}

async function deleteDependant(request: DeleteDependantRequest) {
  return api.post('/operations/dependant-delete', request);
}

async function deleteAttachment({
  config,
  handleCatch,
  handleThen,
}: DeleteAttachmentProps) {
  api
    .delete(`${process.env.REACT_APP_URL_API}${'/attachment'}`, config)
    .then((_) => handleThen())
    .catch(() => handleCatch());
}

const DependantService = {
  list,
  createDependant,
  dependantOptions,
  updateDependantInfo,
  deleteDependant,
  deleteAttachment,
};

export default DependantService;
