import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';

function SignRoutes() {
  const { instance } = useMsal();
  useEffect(() => {
    instance.loginRedirect(loginRequest).catch((e: any) => {
      console.error(e);
    });
  }, [instance]);
  return null;
}

export default SignRoutes;
