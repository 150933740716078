import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Icon from 'components/Icon';
import './styles.scss';

export type BenefitHeaderProps = {
  denominationBenefit: string;
};

function BenefitEditHeader({ denominationBenefit }: BenefitHeaderProps) {
  const { t } = useTranslation('benefit');
  const navigate = useNavigate();

  const dentalAssistHeaderData = {
    title: t('dental.assist.edit.header.title'),
    description: t('dental.assist.edit.header.description'),
  };

  const medicalAssistHeaderData = {
    title: t('medical.assist.edit.header.title'),
    description: t('medical.assist.edit.header.description'),
  };

  function setBenefitText() {
    let benefitText = {
      title: '',
      description: '',
    };
    if (denominationBenefit === 'ASSISTENCIA_ODONTOLOGICA') {
      benefitText = dentalAssistHeaderData;
    }
    if (denominationBenefit === 'ASSISTENCIA_MEDICA') {
      benefitText = medicalAssistHeaderData;
    }
    return benefitText;
  }

  return (
    <div className="benefit-edit-container" data-testid="benefit-edit-header">
      <Row className="navigation">
        <Col
          data-testid="logo-icon"
          className="navigation-link"
          onClick={() => navigate('/benefit')}
        >
          <Icon name="chevronLeft" />
          <span>{t('edit.back')}</span>
        </Col>
      </Row>
      <hr />
      <div className="title">
        <Icon name="LogoAmilIcon" />
        <div className="text">
          <h1>{setBenefitText().title}</h1>
          <p>{setBenefitText().description}</p>
        </div>
      </div>
    </div>
  );
}

export default BenefitEditHeader;
