import React from 'react';
import { FieldError, UseFormReturn } from 'react-hook-form';

const CUSTOM_ERROR_MESSAGE = 'Campo precisa de revisão.';

function errorMaxLengthValidator(
  errors: any,
  errorValidation: FieldError | undefined,
  maxLength: number
) {
  return errors && errorValidation?.type === 'max' ? (
    <span className="error-text">Precisa ter até {maxLength} caracteres.</span>
  ) : null;
}

function errorLabelClass(errors: any, errorValidation: FieldError | undefined) {
  return errors && errorValidation
    ? 'label label-required-error'
    : 'label label-required';
}

function errorClass(errors: any, errorValidation: FieldError | undefined) {
  return errors && errorValidation ? 'field -input-error' : 'field -input';
}

function errorRadioClass(errors: any, errorValidation: FieldError | undefined) {
  return errors && errorValidation
    ? 'field-radio -input-error'
    : 'field-radio -input';
}

function errorCustomValidator(hasCustomError: boolean) {
  return (
    hasCustomError && <span className="error-text">{CUSTOM_ERROR_MESSAGE}</span>
  );
}

function errorViewValidator(
  errors: any,
  errorValidation: FieldError | undefined
) {
  return errors && errorValidation?.type === 'required' ? (
    <span className="error-text">Esse campo é obrigatório.</span>
  ) : null;
}

export function isErrorStatus(status: string): boolean {
  return status === 'error';
}

export function isPendingStatus(status: string): boolean {
  return status === 'pending';
}

export function isValidStatus(status: string): boolean {
  return status === 'valid';
}

export function getFormValidationProps(
  fieldName: string,
  formStore: any,
  formMethods: UseFormReturn<any>
) {
  return {
    shouldDisable: false,
    hasCustomError: false,
    hasAnyError: false,
    hasModified: false,
  };
}

const ValidationUtils = {
  CUSTOM_ERROR_MESSAGE,
  errorClass,
  errorCustomValidator,
  errorViewValidator,
  errorMaxLengthValidator,
  errorRadioClass,
  errorLabelClass,
};

export default ValidationUtils;
