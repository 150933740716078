import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import boomeImg from 'assets/img/benefit-update-boome.png';
import Icon from 'components/Icon';
import Conditional from 'components/AppModal/Conditional';
import DateUtils from 'utils/DateUtils';
import './styles.scss';

type modalProps = {
  contextTranslation?: 'benefit' | 'dependant' | 'redistribute' | 'shared';
  open: boolean;
  data: {
    title: string;
    description: string;
    secondDescription: string;
    buttonText: string;
    date?: string;
  };
  close: () => void;
  handleConfirm: () => void;
};

function ModalInformative({
  contextTranslation,
  open,
  data,
  close,
  handleConfirm,
}: modalProps) {
  const { t } = useTranslation(contextTranslation);

  return (
    <Conditional if={open}>
      <Modal show centered dialogClassName="informative-modal-container">
        <div className="informative-modal-body">
          <div className="top-content">
            <div className="close-btn-container">
              <button
                title="Fechar"
                data-testid="close-button"
                type="button"
                className="close-button"
                onClick={close}
              >
                <Icon name="modalIconClose" />
              </button>
            </div>
          </div>
          <img alt="boome" src={boomeImg} />
        </div>
        <div className="text-content">
          <span className="title">{t(data?.title)}</span>
          <span className="description">
            {t(data.description)}
            {data.date && (
              <span className="bold">
                {DateUtils.format(data.date, 'dd')} de{' '}
                {DateUtils.format(data.date, 'MMMM')}
              </span>
            )}
            {t(data.secondDescription)}
          </span>
        </div>
        <Modal.Footer className="footer">
          <Button onClick={handleConfirm} className="action-button">
            {t(data?.buttonText)}
          </Button>
        </Modal.Footer>
      </Modal>
    </Conditional>
  );
}

export default ModalInformative;
