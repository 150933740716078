/* eslint-disable import/prefer-default-export */
export const DependantTexts = {
  'pt-BR': {
    'add.dependant.header': 'Cadastrar dependente',
    'add.back.dependants': 'Voltar para dependentes',
    'add.header.description':
      'O novo cadastro é necessário ser aprovado. Após o cadastro, você deverá redistribuir a sua cesta de benefícios',
    'add.form.profile.title': 'Informações do dependente',
    'add.form.profile.description':
      'Preencha o formulário com as informações do seu novo dependente.',
    'add.form.attachment.title': 'Anexar documentos',
    'add.form.attachment.description':
      'Forneça os documentos do novo dependente nos formatos JPEG, JPG, PDF ou PNG até 5Mb.',
    'add.form.benefits.title': 'Adicionar nos benefícios',
    'add.form.benefits.description':
      'Informe se quer ou não adicionar o novo dependente ao benefícios Amil.',
    'add.form.required': '*Obrigatório',
    'form.field.name': 'Nome completo',
    'form.field.kinship': 'Grau de parentesco',
    'form.field.birthDate': 'Data de nascimento',
    'form.field.cpf': 'CPF do dependente',
    'form.field.includeIR':
      'Este dependente está declarado no Imposto de Renda?',
    'upload.field.example': 'Exibir exemplo',
    'upload.field.ID': 'RG',
    'upload.field.IDChild': 'RG',
    'upload.field.CERTIFICATE': 'Certidão de Casamento',
    'upload.field.CERTIFICATEChild': 'Certidão de Nascimento',
    'upload.description.ID':
      'Adicione a frente e o verso do documento nos formatos JPEG, JPG, PDF ou PNG de até 5Mb.',
    'upload.description.IDChild':
      'Adicione a frente e o verso do documento nos formatos JPEG, JPG, PDF ou PNG de até 5Mb. Não obrigatório para bebês.',
    'upload.description.CERTIFICATE':
      'Adicione o documento nos formatos JPEG, JPG, PDF ou PNG de até 5Mb.',
    'upload.description.CERTIFICATEChild':
      'Adicione o documento nos formatos JPEG, JPG, PDF ou PNG de até 5Mb.',
    'edit.back': 'Voltar para dependentes',
    'edit.header.title': 'Editar informações do dependente',
    'edit.header.description':
      'As informações disponíveis para edição são o nome completo e se o dependente está incluso do seu IR',

    'delete.back.dependants': 'Voltar para dependentes',
    'delete.header.title': 'Excluir dependente',
    'delete.header.description':
      'É necessário a exclusão ser aprovada. Você deverá redistribuir a sua cesta de benefícios.',
    'delete.dependant.header.warning.text':
      'Você está solicitando a exclusão do dependente:',
    'delete.dependant.warning.title': 'Atenção!',
    'delete.dependant.warning.description.part1':
      'Ao solicitar a exclusão deste dependente, ele também será removido dos planos Amil e não poderá ser reincluído por um período de',
    'delete.dependant.warning.description.part2': ' 180 dias ',
    'delete.dependant.warning.description.part3':
      'contando a partir da aprovação da exclusão pelo DP.',
    'delete.dependant.warning.description.part4': ' Esta ação é irreversível!',
    'delete.dependant.warning.checkbox.text': 'estou ciente e desejo continuar',
    'delete.dependant.solicitation.button': 'Solicitar exclusão',

    'error.message.CPF.dependant':
      'Você já tem um dependente cadastrado com esse CPF.',
    'error.message.CPF.employee':
      'O CPF informado deve ser diferente do CPF do colaborador.',
    'error.message.CPF.invalid': 'CPF inválido.',

    'button.label.send.solicitation': 'Enviar solicitação',
    'button.label.next': 'Avançar',

    'field.profile.benefits.included': 'ADICIONADO NO BENEFÍCIO',
    'field.profile.not.included': 'Não está em nenhum benefício',

    'dependant.empty.title': 'Você não possui dependentes!',
    'dependant.empty.description':
      'Cadastre seus dependentes para poder adicioná-los aos seus benefícios',
    'dependant.first.analysis.title':
      'Você solicitou o cadastro de um dependente',
    'dependant.first.analysis.description.first.line':
      'Estamos aprovando o seu dependente, logo ele aparecerá aqui.',
    'dependant.first.analysis.description.second.line':
      'Aguarde a finalização para cadastrar outro!',

    'modal.back.button': 'Fechar',
    'modal.error.title.upload': 'Falha no upload',
    'modal.custom.error.description':
      'Ocorreu algum erro no upload do seu arquivo. Tente enviar novamente!',
    'modal.error.exclude.title': 'Falha na exclusão',
    'modal.error.exclude.description':
      'Ocorreu algum erro em finalizar a exclusão do seu arquivo. Tente excluir novamente!',
    'modal.error.step.back':
      'Houve uma falha na tentativa de voltar uma etapa.',
    'try.deleting.files':
      'Tente excluir os arquivos que você adicionou antes de sair desta página.',
    'dependant.loading.validation': 'Validando o CPF. Aguarde',
    'click.to.add': 'Clique aqui para adicionar',
    'modal.cancel.button': 'Cancelar',
    'modal.confirm.button': 'Confirmar',
    'modal.confirm.exclude.title': 'Deseja excluir o documento?',
    'modal.confirm.exclude.description':
      'Só é possível avançar se tiver feito o upload deste documento',
    'modal.something.went.wrong.title': 'Algo deu errado',
    'modal.something.went.wrong.description':
      'Por favor, tente novamente em alguns instantes.',
    'modal.edit.title': 'Tem certeza que deseja enviar a solicitação?',
    'modal.edit.description':
      'O processo de edição de dependente será continuado por e-mail. Aguarde o nosso contato para confirmar e finalizar a edição.',
    'yes.input': 'Sim',
    'no.input': 'Não',
    'actual.plan': 'Plano atual',
    'new.dependant.cost': 'Custo do novo dependente',
    'actual.benefit.cost': 'Custo atual do benefício',
    total: 'TOTAL',

    'add.dependant': 'Deseja adicionar',
    'add.plan': 'ao plano de',

    'full.name': 'NOME COMPLETO',
    document: 'CPF',
    kinship: 'GRAU DE PARENTESCO',
    birthdate: 'DATA DE NASCIMENTO',
    'included.IR': 'DECLARADO NO IR',

    'modal.close.button': 'Fechar',
    'modal.close.title': 'Você não pode mais adicionar dependentes!',
    'modal.close.description':
      'Devido o valor do novo dependente ser superior ao valor disponível na sua cesta de benefícios. Colaboradores estagiários não podem ultrapassar sua cesta',
    'modal.info.title':
      'O dependente poderá ter <bold>carência de 180 dias</bold> nos planos Amil!',
    'modal.info.description':
      'A carência só não implicará caso a <bold>data do casamento ou nascimento </bold> não ultrapasse a data de até <bold>30 dias antes </bold> da adição do dependente',
    'modal.info.close.button': 'Cancelar',
    'modal.info.back.button': 'Voltar',
    'modal.info.confirm.button': 'Continuar',

    'modal.loading.title': 'Enviando solicitação...',
    'modal.loading.description': 'Aguarde!',

    'modal.success.title': 'Novo dependente foi solicitado!',
    'modal.success.description':
      'A confirmação do novo dependente será enviada para o seu e-mail em até 5 dias. Em caso de dúvidas, contate-nos em <bold>equipe.dp@iteris.com.br</bold>',
    'modal.success.confirm.button': 'Retornar aos dependentes',

    'modal.error.title': 'Falha no envio da solicitação!',
    'modal.error.description': 'Ocorreu algum erro. Retorne e tente novamente!',

    'placeholder.full.name': 'Digite o nome completo',
    'dependant.edited.wainting.approval':
      'Você solicitou edição deste dependente. O processo está em andamento, aguarde... ',
    'dependant.deleted.wainting.approval':
      'O processo de exclusão deste dependente está em andamento. Aguarde para realizar novas alterações nos seus dependentes. ',

    'dependant.operation.update.approved.title':
      'Sua solicitação de editar as informações do(a) {{name}} foi <bold>aprovada!</bold>',
    'dependant.operation.update.approved.description':
      'As novas informações do dependente já aparecem no seu cadastro.',
    'dependant.operation.update.denied.title':
      'Sua solicitação de editar as informações do(a) {{name}} foi <bold>reprovada!</bold>',
    'dependant.operation.update.denied.description':
      'Consideramos que a sua solicitação não pode ser concluída devido às regras da empresa. Os detalhes foram enviados para o seu e-mail.',

    'uploadFileModal.description': 'Aguarde enquanto o documento é carregado!',
    'uploadFileModal.title': 'Realizando o upload...',

    'removingData.title': 'Retornando para a etapa anterior',

    'dependant.file.max.size':
      'Você deve fazer o upload de um arquivo nos formatos PNG, PDF, JPEG ou JPG com até 5MB.',

    'modal.title': 'Deseja realmente sair?',
    'modal.right.button': 'Permanecer na tela',
    'modal.left.button': 'Sair mesmo assim',
    'modal.description': 'Suas informações serão descartadas',
    'income.tax.modal.title':
      ' Ao incluir o dependente no seu IR, você confirma que:',
    'income.tax.modal.first.dot':
      'Para todos os fins e efeitos, especialmente para o abatimento do',
    'income.tax.modal.first.dot.strong.text':
      'IMPOSTO DE RENDA RETIDO NA FONTE',
    'income.tax.modal.first.dot.continuation':
      ', sob as penas da Lei e responsabilidade criminal, que as pessoas indicadas são meus dependentes;',
    'income.tax.second.dot': 'E que ',
    'income.tax.modal.second.dot.strong.text': 'não ',
    'income.tax.modal.second.dot.continuation':
      'fazem parte da Declaração de Dependentes na Fonte Pagadora do meu cônjuge.',
    'income.tax.modal.button': 'Confirmar',
    'back.app.button.text': 'Voltar',
    'dependant.title': 'Dependentes',
    'dependant.view.info':
      'Visualize e gerencie as suas informações de seus dependentes',
    'dependant.register': 'Cadastrar dependente',
    'dependant.edit.benefits':
      'Quer adicionar um dependente nos seus benefícios?',
    'manage.dependants.benefits':
      'Vá até a aba de benefícios e edite quais dependentes farão parte do seu plano de saúde e odontológico',
    'go.to.benefits': 'Ir para benefícios',
    'dependant.pendency.info':
      'Estamos aprovando o seu novo dependente. Aguarde a finalização para cadastrar outro!',
    'modal.dependant.cancel.title': `Deseja excluir '{{name}}' dos seus dependentes?`,
    'modal.confirm.cancel.dependant': 'Continuar mesmo assim',
    'modal.dependant.cancel.description':
      'Se quiser retirar somente de um benefício, você deve voltar e ir até <bold>Benefícios</bold> para alterar',
    'modal.confirmation.edit.sent.description':
      'A confirmação da edição será enviada para o seu e-mail em até 5 dias. Em caso de dúvidas, contate-nos em equipe.dp@iteris.com.br',
    'modal.edit.request.sent.title':
      'Solicitação de edição do dependente enviada!',
    'dependant.actions.open.button': 'Exibir ações',
    'dependant.actions.close.button': 'Fechar ações',
    'dependant.actions.edit': 'Editar informações',
    'dependant.actions.cancel': 'Solicitar exclusão',
    'editing.dependent.information':
      'Você está editando as informações do dependente:',
    'fill.dependant.information':
      'Preencha as informações atuais do dependente',

    'manage.included.dependats': 'Dependentes incluídos',
    'manage.remove.dependats': 'Remover todos',
    'manage.dependants.to.add': 'Dependentes para adicionar',
    'manage.add.all.dependants': 'Adicionar todos',
    'manage.no.dependants.into.benefit':
      ' Você não tem dependentes neste benefício',
    'manage.no.dependants.to.add': ' Você não tem dependentes para adicionar',
    'manage.total.value': 'Valor Total',
    'manage.cost.per.dependant': 'Custo por dependente',
    'dependant.operation.delete.approved.title': `Exclusão solicitada do(a) '{{name}}' foi <bold>aprovada!</bold>`,
    'dependant.operation.delete.approved.description':
      'Agora ele não aparecerá mais na sua lista. A confirmação foi enviada para o seu e-mail. Em caso de dúvidas, contate-nos em',
    'dependant.operation.delete.denied.title': `Exclusão solicitada do(a) '{{name}}' foi <bold>reprovada!</bold>`,
    'dependant.operation.delete.denied.description':
      'Os detalhes foram enviados para o seu e-mail. Em caso de dúvidas, contate-nos em ',

    'dependant.operation.delete.send.success.title':
      'Exclusão do dependente foi solicitada!',

    'dependant.operation.delete.send.success.description':
      'A confirmação da exclusão será enviada para o seu e-mail em até 5 dias. Em caso de dúvidas, contate-nos em ',
    'dependant.operation.description.email': ' equipe.dp@iteris.com.br',
    'dependant.operation.edit.approved.description':
      'A confirmação da edição será enviada para o seu e-mail em até 5 dias. Em caso de dúvidas, contate-nos em',

    'dependant.operation.create.approved.success.title.part1': `O cadastro do(a) '{{name}}' como dependente foi `,
    'dependant.operation.create.approved.success.title.part2': 'aprovado!',
    'dependant.operation.create.approved.success.description':
      'Se você o adicionou nos benefícios, a redistribuição da cesta feita já está implantada. Em caso de dúvidas, contate-nos em ',

    'dependant.operation.create.denied.success.title.part1':
      'O cadastro do novo dependente foi ',
    'dependant.operation.create.denied.success.title.part2': 'reprovado!',
    'dependant.operation.create.denied.success.description':
      'Consideramos que sua solicitação não pode fazer parte dos seus dependentes. Os detalhes foram enviados para o seu e-mail. Em caso de dúvidas, contate-nos em ',
    'dependant.new': 'Novo dependente ',
  },
};
