import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import amilBoome from 'assets/img/amil-boome-vector.svg';
import AppButton from 'components/AppButton';
import { BenefitBasketResume } from 'types/models/Profile';
import Conditional from 'components/AppModal/Conditional';
import DateUtils from 'utils/DateUtils';
import Checkbox from '../Checkbox';
import BenefitsResume from '../BenefitsResume';
import BenefitAdjustWarningInfo from '../BenefitAdjustWarningInfo';
import './styles.scss';

type modalProps = {
  totalBasketValue: number;
  benefitBasketResume: BenefitBasketResume;
  benefitFinalDate?: string;
  open: boolean;
  close: () => void;
  handleConfirm: () => void;
};

function BenefitAdjustWarningModal({
  totalBasketValue,
  benefitBasketResume,
  benefitFinalDate = '',
  open,
  close,
  handleConfirm,
}: modalProps) {
  const [checkboxValue, setCheckboxValue] = useState(false);
  const { t } = useTranslation('benefit');

  return (
    <Conditional if={open}>
      <Modal
        show
        centered
        dialogClassName="benefit-adjust-modal-container modal-xl"
      >
        <div className="benefit-adjust-modal-header">
          <div className="header-text-content">
            <span className="title">
              {t('benefit.adjust.warning.modal.title')}
            </span>
            <span className="subtitle">
              {t('benefit.adjust.warning.modal.subtitle')}
              <span className="deadline">
                {benefitFinalDate &&
                  DateUtils.format(benefitFinalDate, 'dd/MM/yyyy')}
                !
              </span>
            </span>
            <span className="description">
              {t('benefit.adjust.warning.modal.description')}
            </span>
          </div>
          <img alt="boome" src={amilBoome} />
        </div>
        <div className="content">
          <BenefitsResume
            totalBasketValue={totalBasketValue}
            benefitBasketResume={benefitBasketResume}
          />
          <div className="divider" />
          <BenefitAdjustWarningInfo benefitFinalDate={benefitFinalDate} />
          <div className="divider" />
          <div className="checkbox-content">
            <Checkbox
              checkboxColor="black"
              checkedValue={checkboxValue}
              setCheckedValue={setCheckboxValue}
              checkedIcon="checkboxBlack"
            />
            <span className="checkbox-text">
              {t('benefit.adjust.warning.modal.checkbox')}
            </span>
          </div>
        </div>
        <Modal.Footer className="footer">
          <AppButton
            disabled={!checkboxValue}
            outline
            onClick={close}
            className="action-button-left"
          >
            {t('benefit.adjust.warning.modal.left.button')}
          </AppButton>
          <AppButton
            disabled={!checkboxValue}
            full
            onClick={handleConfirm}
            className="action-button"
          >
            {t('benefit.adjust.warning.modal.right.button')}
          </AppButton>
        </Modal.Footer>
      </Modal>
    </Conditional>
  );
}

export default BenefitAdjustWarningModal;
