import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon';
import masks from 'utils/MasksUtils';
import Conditional from 'components/AppModal/Conditional';
import { BenefitResumeWithId } from 'types/models/Benefit';
import './styles.scss';

type Props = {
  benefits: Array<BenefitResumeWithId>;
};

function BasketBenefits({ benefits }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation('benefit');

  const hasBenefits = benefits.filter((b) => !b.option).length > 0;
  const benefitsToSort = [...benefits];
  const benefitsSorted = benefitsToSort.sort(
    (a, b) => b.benefitId - a.benefitId
  );

  return (
    <Conditional if={hasBenefits}>
      <div className="basket-benefits-container">
        <Row data-testid="benefits-container" className="header">
          <Col xs={10} className="title">
            <div className="texts">
              <h1 className="name">{t('edit.basket.header.title')}</h1>
              <p className="description">{t('view.values.food')} </p>
            </div>
            <button
              data-testid="alelo-manage-button"
              className="manage-button"
              type="button"
              onClick={() => navigate('/benefit/basket/edit')}
            >
              <span className="button-title">{t('benefit.manage')}</span>
              <Icon name="settingsOutlined" />
            </button>
          </Col>
          <Col className="actions d-none">
            <span>{t('show.actions')}</span>
            <Icon data-testid="benefits-icon" name="chevronRightBlue" />
          </Col>
        </Row>
        {benefitsSorted.map((benefit) => {
          if (benefit.option) return true;
          return (
            <Row className="benefit" key={benefit.name}>
              <Col className="d-flex align-items-center">
                <img src={benefit.urlImage} alt="benefit icon" />
                <h1>{benefit.name}</h1>
              </Col>
              <Col className="value">
                <span>{masks.currency(benefit.valueEmployee || 0)}</span>
              </Col>
            </Row>
          );
        })}
      </div>
    </Conditional>
  );
}

export default BasketBenefits;
