import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon';
import DependantAddButton from '../AddDependant/AddDependantButton/AddDependantButton';
import './styles.scss';

type DependantEmptyProps = {
  disabled: boolean;
};

function DependantsEmpty({ disabled }: DependantEmptyProps) {
  const { t } = useTranslation('dependant');

  return (
    <div data-testid="dependants-empty" className="empty-container">
      <Icon name="groupAdd" />
      <p className="title">{t('dependant.empty.title')}</p>
      <p className="desc">{t('dependant.empty.description')}</p>
      <DependantAddButton full disabled={disabled} />
    </div>
  );
}

export default DependantsEmpty;
