import React from 'react';
import { Row } from 'react-bootstrap';
import Icon, { IconTypes } from 'components/Icon';
import './styles.scss';

export type Props = {
  data: {
    classNameIcon?: string;
    icon?: string;
    title?: string;
    titleColor?: string;
    description?: string;
    descriptionColor?: string;
    backgroundColor?: string;
  };
};

function ObsLabel({ data }: Props) {
  return (
    <Row
      data-testid="obs-label"
      className="obs-container"
      style={{ backgroundColor: data.backgroundColor }}
    >
      <div className={`icon ${data.classNameIcon}`}>
        <Icon name={data?.icon as IconTypes} />
      </div>
      <div className="text">
        <p className="title" style={{ color: data.titleColor }}>
          {data.title}
        </p>
        <p className="description" style={{ color: data.descriptionColor }}>
          {data.description}
        </p>
      </div>
    </Row>
  );
}

export default ObsLabel;
